import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { TextField, Button, TextareaAutosize, Divider } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import * as ETVConstants from '../ETVConstants';

export class LocationMap extends Component {

  state = {
    initial_lat:undefined,
    initial_lgt:undefined,
  };

  componentDidMount() {
    var lat = this.props.lat;
    if(lat==='0' || lat==0) {
      lat = 1.283963993518128;
    }
    var lgt = this.props.lgt;
    if(lgt==='0' || lgt==0) {
      lgt = 103.85129636907892;
    }
    //console.log("iniital GPS="+lat+" this.props.lat="+this.props.lat);
    this.setState({initial_lat:lat, initial_lgt:lgt});
  }

  componentDidUpdate( prevProps, prevState) {
    if(this.props.lat != prevProps.lat || this.props.lgt != prevProps.lgt) {
      var lat = this.props.lat;
      if(lat==='0' || lat==0) {
        lat = 1.283963993518128;
      }
      var lgt = this.props.lgt;
      if(lgt==='0' || lgt==0) {
        lgt = 103.85129636907892;
      }
      //console.log("iniital GPS="+lat+" this.props.lat="+this.props.lat);
      this.setState({initial_lat:lat, initial_lgt:lgt});
    }
  }

  onMapClicked = (ref,map,ev) => {
      const location = ev.latLng;
      //console.log("new lat="+location.lat()+" lgt="+location.lng());
      this.props.setLocationGPS(location.lat(),location.lng());
  };


  render() {
    return (
      <span>
          {this.state.initial_lat?
            <div>
              <Map
                className="map"
                google={this.props.google}
                onClick={this.onMapClicked}
                style={{ height: "90%", position: "relative", width: "90%" }}
                zoom={12}
                initialCenter={{ lat: this.state.initial_lat, lng: this.state.initial_lgt}}
                >
                <Marker
                   title={'markertitle'}
                   name={'markername'}
                   coordinate={{lat: this.state.initial_lat, lng: this.state.initial_lgt}}>
                </Marker>
              </Map>
            </div>
            :
            <span/>
          }
      </span>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyDY87mGfUn304KvYQdwSFPdXG010XiwXNM',
})(LocationMap);
