import React from 'react';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import SelectAvailableScreensNew from './SelectAvailableScreensNew';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Image2 from 'material-ui-image';
import * as ETVConstants from '../ETVConstants';

//https://blog.cloudboost.io/using-html5-canvas-with-react-ff7d93f5dc76
class EditText extends React.Component {

    constructor(props) {
      super(props);
      this.onMouseOut = this.onMouseOut.bind(this);
      this.onKeyDown = this.onKeyDown.bind(this);
      this.loadBG = this.loadBG.bind(this);
      this.readerLoaded = this.readerLoaded.bind(this);
      this.bgImageLoaded = this.bgImageLoaded.bind(this);
      this.closeScreenSelection = this.closeScreenSelection.bind(this);
      this.selectedScreens = this.selectedScreens.bind(this);
    }

    state = {
      items:[],
      canvas:undefined,
      ctx:undefined,
      cWidth:1024,
      cHeight:768,
      canvas_width: 1024,
      canvas_height: 768,
      canvasResolution:1.33333,
      font:"Tahoma",
      textSize:64,
      fontStyle:'normal',
      canvas_bg_color: '#e5decf',
      needRedraw: false,
      state_writing:false,
      textColor:'#0A0A0A',
      status:0,
      currentPos:{x:0,y:0},
      canvas_bg: undefined,
      canvas_img: undefined,
      final_image: undefined,
      show_overlay: false,
      window: undefined,
      channel: 0,
      selected_screens: [],
      template_items:[],
    }

    resetDrawing() {
      var defstate = {
        items:[],
        cWidth:1024,
        cHeight:768,
        canvas_width: 1024,
        canvas_height: 768,
        canvasResolution:1.33333,
        canvas_bg_color: '#e5decf',
        needRedraw: true,
        state_writing:false,
        textColor:'#0A0A0A',
        status:0,
        currentPos:{x:0,y:0},
        canvas_bg: undefined,
        canvas_img: undefined,
        final_image: undefined,
        show_overlay: false,
        window: undefined,
      }
      this.setState(defstate);
    }

    componentDidMount() {
      this.loadTemplates();
      const canvas = this.refs.canvas;
	    // canvas.addEventListener('mouseout',on_mouse_out, false);
	    //  window.addEventListener( 'keydown', doKeyDown, false);
      const ctx = canvas.getContext("2d")
      const img = this.refs.image
      this.setState({ctx:ctx, canvas:canvas});
      this.drawCanvasContent();
    }

    componentDidUpdate() {
      if(this.state.needRedraw) {
        const canvas = this.refs.canvas;
        const ctx = canvas.getContext("2d");
        const img = this.refs.image;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        this.setState({ctx:ctx, canvas:canvas, needRedraw:false});
        this.drawCanvasContent();
      }
    }

    readerLoaded = (e) => {
      const content = e.target.result;
      //console.log("readerLoaded="+content);
      var image = new Image();
      image.src = e.target.result;
      image.onload = this.bgImageLoaded;
      this.setState({canvas_img:image, canvas_bg:e.target.result,window:''});
    }

    loadBG(selectorFiles) {
      //console.log(selectorFiles[0]);
      var file = selectorFiles[0];
      var reader  = new FileReader();
      reader.onloadend = this.readerLoaded;
      if (file) {
        reader.readAsDataURL(file);
      }
    }

    bgImageLoaded(image) {
      this.setState({cWidth:this.state.canvas_img.width,cHeight:this.state.canvas_img.height, bgImage:image, canvas_bg_color:'#FFFFFF',items:[],needRedraw:true});
    }

    createTextObject( text, pos, textSize, font, fontStyle, textColor, stateDrawing) {
    	var o = new Object();
    	o.text = text;
    	o.pos = pos;
    	o.textSize = textSize;
    	o.font = font;
    	o.fontStyle = fontStyle;
    	o.textColor = textColor;
    	o.stateDrawing = stateDrawing;
    	return o;
    }

    addTextObject( obj) {
      var newItems = [];
      for(var i=0; i<this.state.items.length; i++) {
        var item = this.state.items[i];
        newItems.push(item);
      }
      newItems.push(obj);
      this.setState({items:newItems, needRedraw:true});
    }

    drawCanvasContent() {
      if(!this.state.ctx) return;
      //console.log("drawCanvasContent, fontSize="+this.state.textSize);
      this.state.ctx.clearRect(0, 0, this.state.canvas.width, this.state.canvas.height);
      var items = this.state.items;
      for(var i=0; i<items.length; i++) {
        this.drawText(items[i]);
      }
    }

    drawText( textObj) {
    	this.state.ctx.font = textObj.fontStyle+" "+textObj.textSize+"px "+textObj.font;
    	this.state.ctx.fillStyle = textObj.textColor;
    	if (textObj.stateDrawing) {
    		this.state.ctx.fillText(textObj.text+"_", textObj.pos.x, textObj.pos.y);
    	}else{
    		this.state.ctx.fillText(textObj.text, textObj.pos.x, textObj.pos.y);
    	}
    }

    handleCanvasClick = (evt) => {
      this.setAllNonDrawing();
      const coord = this.getMousePos(this.state.canvas,evt);
      //console.log("x="+coord.x+" y="+coord.y);
      var tObj = this.createTextObject( "", coord, this.state.textSize, this.state.font, this.state.fontStyle, this.state.textColor, true);
      this.addTextObject(tObj);
      this.setState({currentPos:coord, status:1, needRedraw:true});
    }

    setAllNonDrawing() {
      var newItems = [];
      for(var i=0; i<this.state.items.length; i++) {
        var item = this.state.items[i];
        item.stateDrawing = false;
        newItems.push(item);
      }
      this.setState({items:newItems,needRedraw:true});
    }

    getMousePos(canvas, evt) {
        var rect = this.state.canvas.getBoundingClientRect();
        return {
          x: evt.clientX - rect.left,
          y: evt.clientY - rect.top
        };
    }

    appendChar(ch) {
      var newItems = [];
      for(var i=0; i<this.state.items.length; i++) {
        var curItem = this.state.items[i];
        var additionalItem = undefined;
        if(curItem.stateDrawing) {
          if(ch==="Tab") {
            curItem.text = curItem.text+"    ";

          }else if(ch==="Enter") {
            curItem.stateDrawing = false;
            var newPos = {x:curItem.pos.x, y:curItem.pos.y + 1.2* curItem.textSize};
            additionalItem = this.createTextObject("", newPos, curItem.textSize, curItem.font, curItem.fontStyle, curItem.textColor, true);

          }else if(ch==="Backspace" || ch==="Delete") {
            //console.log("curItem.text="+curItem.text+" state.Drawing="+curItem.stateDrawing);
            if(curItem.text.length>0) {
              curItem.text = curItem.text.substring(0,curItem.text.length-1);
              if(curItem.text.length===0) {
                curItem = undefined;
                if(newItems.length>0) {
                  newItems[newItems.length-1].stateDrawing=true;
                }
              }
            }else{
              curItem = undefined;
              if(newItems.length>0) {
                newItems[newItems.length-1].stateDrawing=true;
              }
            }

          }else if(!this.isSpecialCharacter(ch)){
            curItem.text = curItem.text + ch;
          }
        }
        if(curItem) {
          newItems.push(curItem);
        }
        if(additionalItem) {
          newItems.push(additionalItem);
        }
      }
      this.setState({items:newItems, needRedraw:true});
    }

    isSpecialCharacter( ch) {
      if(ch==="Shift") return true;
      if(ch==="Control") return true;
      if(ch==="Alt") return true;
      if(ch==="Meta") return true;
      if(ch==="Insert") return true;
      if(ch==="PageUp") return true;
      if(ch==="PageDown") return true;
      if(ch==="End") return true;
      if(ch==="Home") return true;
      if(ch==="ArrowUp") return true;
      if(ch==="ArrowDown") return true;
      if(ch==="ArrowLeft") return true;
      if(ch==="ArrowRight") return true;
      if(ch==="CapsLock") return true;
      if(ch==="Esc") return true;
      return false;
    }

    handleCanvasResolutionChange(ratio, adaptUserField) {

      var y = 768;
      var x = y*ratio;
      if(ratio>=1) {
        x = 1024;
        y = x/ratio;
      }
      if(ratio===1) {
        x = 800;
        y = 800;
      }
      x = Math.round(x);
      y = Math.round(y);

      if(adaptUserField) {
        this.setState({canvas_width:x,canvas_height:y});
      }
      this.setState({canvasResolution:ratio, cWidth:x, cHeight:y});
      this.setAllNonDrawing();
    }

    handleFontChange = (event) => {
      var font = event.target.value;
      this.setState({font:font, needRedraw:true});
    }

    handleTextSizeChange = (event) => {
      var txtsize = event.target.value;
      this.setState({textSize:txtsize, needRedraw:true});
    }

    handleFontStyleChange = (event) => {
      var fontStyle = event.target.value;
      this.setState({fontStyle:fontStyle, needRedraw:true});
    }

    handleChannelChange = (event) => {
      var ch = event.target.value;
      this.setState({channel:ch, needRedraw:true});
    }

    onMouseOut() {
      this.setAllNonDrawing();
    }

    onKeyDown(event) {
      //console.log("KeyDown keyValue="+event.keyCode+" event.key="+event.key);
      var ch = event.key;
      if(this.state.status===1) {
        this.appendChar(ch);
        //console.log("append char="+ch);
      }else{
        //console.log("ouf of focus, not append char ("+ch+")");
      }
    }

    prepareUploadImage() {
      //console.log("--------> upload image -----------");
      var canvas = this.state.canvas;
      var ctx = canvas.getContext("2d");

      if(this.state.canvas_bg_color) {
        ctx.fillStyle = this.state.canvas_bg_color;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }

      if(this.state.canvas_bg) {
        var image = new Image();
        image.src = this.state.canvas_bg;
        ctx.drawImage(image,0,0);
      }
      for(var i=0; i<this.state.items.length; i++) {
          this.state.items[i].stateDrawing = false;
          this.drawText(this.state.items[i]);
      }
      if(this.state.canvas) {
        var data = this.state.canvas.toDataURL("image/png");
      }
      this.uploadImage( this.props.user_id, data, this.state.selected_screens, this.state.channel, this.state.canvasResolution);
      this.setState({canvas_bg:undefined,items:[],needRedraw:true,canvas_bg_color:'#FFFFFF',textColor:'#0A0A0A',channel:0});
    }

    showWindow(divID, show) {
      this.setState({show_overlay:show});
      { show?this.setState({window:divID}):this.setState({window:''}) };
    }

    showInfoMessage(msg) {
      this.setState({window:'message_div',info_message:msg});
    }

    closeInfoMessage() {
      this.setState({info_message:undefined});
      this.showWindow('message_div',false);
    }

    closeScreenSelection() {
      this.showWindow('select_screens_div',false);
    }

    selectedScreens(screenIDs) {
      //console.log("selected screens="+screenIDs);
      this.showWindow('select_screens_div',false);
      this.setState({selected_screens:screenIDs});
    }

    uploadImage( userID, imageData, screenIDs, channel, ratio) {
      this.showInfoMessage(ETVConstants.trans("uploading..."));
      var d = new Date();
      var desc = "text created "+d.toLocaleString();
      //console.log("---------------------uploading data");
      var userID = this.props.user_id;
      var targetDir = "/"+userID+"/content_library";
      var myRand = Math.floor((Math.random() * 100000) + 1);
      var filename = myRand+"_text.png";

      var formData = new FormData();
      formData.append("user_id",userID);
      screenIDs.map( (item,i) => formData.append("device_id",item));
      //console.log('---------------> IMAGE_DATA.length='+imageData.length);
      formData.append("image_data",imageData);
      formData.append("channel",channel);
      formData.append("filename",filename);
      formData.append("target_dir",targetDir);
      formData.append("desc",desc);
      formData.append("content_ratio", ratio)
      //console.log("sending desc="+JSON.stringify(desc));

      const config = {
        headers: {	'content-type': 'multipart/form-data'}
      }
      var url = ETVConstants.getServerBase()+"/image_upload";
      axios.post(url, formData, config)
            .then((response) => {
                  //console.log("==============================> resp="+JSON.stringify(response, null, 2));
                  this.showInfoMessage(ETVConstants.trans("successfully_uploaded"));
                })
                .catch((error) => {
                });
    }

    changeCustomCanvasSize(canvasWidth, canvasHeight) {
      this.setState({canvas_width:canvasWidth,canvas_height:canvasHeight});
      var ratio = canvasWidth/Math.max(1,canvasHeight);
      this.handleCanvasResolutionChange(ratio,false);
    }


    filterTemplateItems( marketPlaceRx) {
      //console.log(marketPlaceRx);
      var templates = [];
      for(var i=0; i<marketPlaceRx.length; i++) {
        //console.log("checking file="+marketPlaceRx[i].filename+" content_category="+marketPlaceRx[i].content_category);
        if(marketPlaceRx[i].content_category==="template" && (marketPlaceRx[i].filename.toLowerCase().endsWith(".jpg") || marketPlaceRx[i].filename.toLowerCase().endsWith(".jpeg") || marketPlaceRx[i].filename.toLowerCase().endsWith(".png"))) {
          //console.log("yes, push");
          templates.push(marketPlaceRx[i]);
        }
      }
      this.setState({
        template_items: templates,
      });
    }

    loadTemplates() {
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      var url = ETVConstants.getServerBase()+"/content_library?action=list_market_place";
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            var rx = result.list;
            this.filterTemplateItems(rx);
          },
          (error) => {
            this.setState({
              error
            });
          }
        )
    }

    loadTemplate( item) {
      var reader = new FileReader();
      reader.onload = this.readerLoaded;
      var request = new XMLHttpRequest();
      request.open('GET', item.url, true);
      request.responseType = 'blob';
      request.onload = function() {
          reader.readAsDataURL(request.response);
      };
      request.send();
    }

    render() {

      var dropDownWidth = 200;

      return (
        <Card>
          <CardContent>
            <table cellSpacing="10"><tbody>
              <tr valign='top'>
                <td>
                  <fieldset>
                    <div>
                        <FormControl>
                           <InputLabel>{ETVConstants.trans("resolution")}</InputLabel>
                           <Select
                             labelId="sizeLabel"
                             id="sizeID"
                             style={{ width: dropDownWidth}}
                             value={this.state.canvasResolution}
                             onChange={(e) => {this.handleCanvasResolutionChange(e.target.value,true)}}>
                             <MenuItem value={1.33333}>4:3</MenuItem>
                             <MenuItem value={0.75}>3:4</MenuItem>
                             <MenuItem value={1.77777778}>16:9</MenuItem>
                             <MenuItem value={1}>Square</MenuItem>
                             <MenuItem value={7.8}>Banner 468x60</MenuItem>
                             <MenuItem value={8.0888889}>Leaderboard 728x90</MenuItem>
                             <MenuItem value={1.2}>Inline Rectangle 300x250</MenuItem>
                             <MenuItem value={0.2}>Skyscraper 120x600</MenuItem>
                             <MenuItem value={0.5}>Half-Page Ad 300x600</MenuItem>
                             <MenuItem value={10.77777778}>Large Leaderboard 970x90</MenuItem>
                           </Select>
                           <div id='custom_resolution' style={{marginTop:'20px'}}>
                                <TextField key='canvas_width'
                                          label={ETVConstants.trans("width_pixels")}
                                          type="number"
                                          InputProps={{ inputProps: { min: 0, max: 2048 } }}
                                          value={this.state.canvas_width}
                                          style = {{width: 90}}
                                          onChange={(event)=>{this.changeCustomCanvasSize(event.target.value,this.state.canvas_height)}} />
                                <TextField key='canvas_height'
                                          label={ETVConstants.trans("height_pixels")}
                                          type="number"
                                          InputProps={{ inputProps: { min: 0, max: 2048 } }}
                                          style = {{width: 95, marginLeft:10,}}
                                          value={this.state.canvas_height}
                                          onChange={(event)=>{this.changeCustomCanvasSize(this.state.canvas_width,event.target.value)}} />
                           </div>
                         </FormControl>
                    </div>
                    <br/>
                    <div>
                       <FormControl>
                          <InputLabel>{ETVConstants.trans("font")}</InputLabel>
                          <Select
                            labelId="fontlabelID"
                            id="fontID"
                            style={{ width: dropDownWidth}}
                            value={this.state.font}
                            onChange={this.handleFontChange}>
                            <MenuItem value='Arial'>Arial</MenuItem>
                  					<MenuItem value='Arial Black'>Arial Black</MenuItem>
                  					<MenuItem value='Bookman'>Bookman</MenuItem>
                  					<MenuItem value='Calibri'>Calibri</MenuItem>
                  					<MenuItem value='Comic Sans MS'>Comic Sans MS</MenuItem>
                  					<MenuItem value='Courier New'>Courier New</MenuItem>
                  					<MenuItem value='Courier'>Courier</MenuItem>
                  					<MenuItem value='Garamond'>Garamond</MenuItem>
                  					<MenuItem value='Georgia'>Georgia</MenuItem>
                  					<MenuItem value='Helvetica'>Helvetica</MenuItem>
                  					<MenuItem value='Impact'>Impact</MenuItem>
                  					<MenuItem value='Lucida Sans Unicode'>Lucida Sans Unicode</MenuItem>
                  					<MenuItem value='MS PGothic'>MS PGothic</MenuItem>
                  					<MenuItem value='MS Gothic'>MS Gothic</MenuItem>
                  					<MenuItem value='MS PMing'>MS PMing</MenuItem>
                  					<MenuItem value='Palatino'>Palatino</MenuItem>
                  					<MenuItem value='Sans-Serif'>Sans-Serif</MenuItem>
                  					<MenuItem value='Serif'>Serif</MenuItem>
                  					<MenuItem value='Tahoma'>Tahoma</MenuItem>
                  					<MenuItem value='Times New Roman'>Times New Roman</MenuItem>
                  					<MenuItem value='Times'>Times</MenuItem>
                  					<MenuItem value='Trebuchet MS'>Trebuchet MS</MenuItem>
                  					<MenuItem value='Verdana'>Verdana</MenuItem>
                          </Select>
                        </FormControl>
                    </div>
                    <br/>
                    <div>
                        <FormControl>
                           <InputLabel>{ETVConstants.trans("text_size")}</InputLabel>
                           <Select
                             labelId="textSizeLabelID"
                             id="textSize"
                             style={{ width: dropDownWidth}}
                             value={this.state.textSize}
                             onChange={this.handleTextSizeChange}>
                             <MenuItem value='10'>10pt</MenuItem>
                             <MenuItem value='12'>12pt</MenuItem>
                             <MenuItem value='16'>16pt</MenuItem>
                             <MenuItem value='18'>18pt</MenuItem>
                             <MenuItem value='20'>20pt</MenuItem>
                             <MenuItem value='24'>24pt</MenuItem>
                             <MenuItem value='30'>30pt</MenuItem>
                             <MenuItem value='36'>36pt</MenuItem>
                             <MenuItem value='40'>40pt</MenuItem>
                             <MenuItem value='44'>44pt</MenuItem>
                             <MenuItem value='48'>48pt</MenuItem>
                             <MenuItem value='56'>56pt</MenuItem>
                             <MenuItem value='64'>64pt</MenuItem>
                             <MenuItem value='72'>72pt</MenuItem>
                             <MenuItem value='80'>80pt</MenuItem>
                             <MenuItem value='88'>88pt</MenuItem>
                             <MenuItem value='96'>96pt</MenuItem>
                             <MenuItem value='102'>102pt</MenuItem>
                             <MenuItem value='116'>116pt</MenuItem>
                             <MenuItem value='128'>128pt</MenuItem>
                             <MenuItem value='150'>150pt</MenuItem>
                             <MenuItem value='175'>175pt</MenuItem>
                             <MenuItem value='200'>200pt</MenuItem>
                             <MenuItem value='256'>256pt</MenuItem>
                           </Select>
                         </FormControl>
                    </div>
                    <br/>
                    <div>
                         <FormControl>
                            <InputLabel>{ETVConstants.trans("font_style")}</InputLabel>
                            <Select
                              labelId="FontStyleLabelID"
                              id="fontStyleID"
                              style={{ width: dropDownWidth}}
                              value={this.state.fontStyle}
                              onChange={this.handleFontStyleChange}>
                              <MenuItem value='normal'>normal</MenuItem>
                              <MenuItem value='italic'>italic</MenuItem>
                              <MenuItem value='bold'>bold</MenuItem>
                            </Select>
                          </FormControl>
                    </div>
                    <br/>
                      <div>
                          <Typography variant="body2">{ETVConstants.trans("font_color")}</Typography>
                          <input type="color" style={{marginTop:5}} value={this.state.textColor} onChange={e => this.setState({textColor:e.target.value, needRedraw:true} )} />
                      </div>
                      <br/>
                      <div>
                          <Typography variant="body2">{ETVConstants.trans("background_color")}</Typography>
                          <input type="color" style={{marginTop:5}} value={this.state.canvas_bg_color} onChange={e => this.setState({canvas_bg_color:e.target.value, needRedraw:true} )} />
                      </div>
                      <br/>
                      <div>
                          <Typography variant="body2">{ETVConstants.trans("choose_template")}</Typography>
                          <input type="file" style={{marginTop:5}} onChange={ (e) => this.loadBG(e.target.files) } style={{ width: dropDownWidth}} />
                      </div>
                      <br/>
                      <div>
                          {ETVConstants.ON_PREMISE_VERSION?<span/>:
                            <Button color="primary" variant='contained' onClick={() => this.showWindow('select_from_templates',true)}>{ETVConstants.trans("select_from_templates")}</Button>
                          }
                      </div>
                    </fieldset>
                    <br/>
                    <fieldset>
                        <div><Button color="primary" variant='contained' onClick={() => this.showWindow('select_screens_div',true)}>{ETVConstants.trans("select_screens")} ({this.state.selected_screens.length})</Button></div>
                        <FormControl style={{marginTop:10}}>
                           <InputLabel>{ETVConstants.trans("channel")}</InputLabel>
                           <Select
                             labelId="ChannelIDLabel"
                             id="channelID"
                             style={{ width: dropDownWidth}}
                             value={this.state.channel}
                             onChange={this.handleChannelChange}>
                              <MenuItem value='0'>{ETVConstants.trans("channel")} 0</MenuItem>
                              <MenuItem value='1'>{ETVConstants.trans("channel")} 1</MenuItem>
                              <MenuItem value='2'>{ETVConstants.trans("channel")} 2</MenuItem>
                              <MenuItem value='3'>{ETVConstants.trans("channel")} 3</MenuItem>
                              <MenuItem value='4'>{ETVConstants.trans("channel")} 4</MenuItem>
                           </Select>
                         </FormControl>
                    </fieldset>
                </td>
                <td>
                     <div>
                          {this.state.canvas_bg?
                            <canvas ref="canvas" width={this.state.cWidth} height={this.state.cHeight} style={{border:"1px solid lightgrey", background:'url('+this.state.canvas_bg+')'}}
                                onClick={this.handleCanvasClick}
                                tabIndex="0"
                                onKeyDown={this.onKeyDown}
                                onMouseOut={this.onMouseOut} />
                            :
                            <canvas ref="canvas" width={this.state.cWidth} height={this.state.cHeight} style={{border:"1px solid lightgrey", backgroundColor:this.state.canvas_bg_color}}
                                onClick={this.handleCanvasClick}
                                tabIndex="0"
                                onKeyDown={this.onKeyDown}
                                onMouseOut={this.onMouseOut} />
                          }

                     </div>
                </td>
              </tr>
             </tbody></table>

            <div id='select_screens_div' style={{display:this.state.window==='select_screens_div'?'block':'none'}}>
   								<SelectAvailableScreensNew isOpen={this.state.window==='select_screens_div'} user_id={this.props.user_id} closeHook={this.closeScreenSelection} selected={this.state.selected_screens?this.state.selected_screens:[]} selectedScreens={this.selectedScreens}/>
   					</div>

             <Dialog
               open={this.state.window==='message_div'}
               maxWidth='xs'
               fullWidth={true}
               onClose={()=>{this.setState({window:''})}}
               aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description">
               <DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
               <DialogContent>
                   <Typography variant='body1'>{this.state.info_message}</Typography>
               </DialogContent>
               <DialogActions>
                 <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
               </DialogActions>
             </Dialog>

             <Dialog
               open={this.state.window==='select_from_templates'}
               maxWidth='lg'
               fullWidth={true}
               onClose={()=>{}}
               aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description">
               <DialogTitle id="alert-dialog-title">{ETVConstants.trans("select_from_templates")}</DialogTitle>
               <DialogContent>
                  {this.state.template_items.length>0?
                        <div>
                             { this.state.template_items.map( item => {
                                  return ( <div key={item.content_id} style={{display: 'inline-block', width:300, height:225, margin: 5}}>
                                              <Image2 src={item.thumbnail} onClick={()=>{this.loadTemplate(item)}} imageStyle={{width:300,height:'inherit'}}/>
                                           </div>
                                         )
                              })}
                        </div>
                     :
                        <span/>
                   }
               </DialogContent>
               <DialogActions>
                 <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
               </DialogActions>
             </Dialog>

          </CardContent>
          <CardActions>
              <Button color="primary" onClick={() => this.resetDrawing()}>{ETVConstants.trans("reset")}</Button>
              {this.state.items.length>0?<Button color="secondary" variant='contained' onClick={() => this.prepareUploadImage()}>Upload</Button>:<span/>}
          </CardActions>
        </Card>
      )
    }
}

export default EditText;
