import React from 'react';
import CheckBoxList2 from '../tools/CheckBoxList2';
import * as ETVConstants from '../ETVConstants';

class RolesList extends React.Component {

    constructor(props) {
      super(props);
      this.roleCallback = this.roleCallback.bind(this);
      this.translateRole = this.translateRole.bind(this);
    }

    state = {
      checkBoxList: [],
      current_user: undefined,
    }

    componentDidUpdate() {
      if(this.props.user_id===undefined) {
        return;
      }
      if(this.state.current_user!==this.props.user_id) {
        //bug but it works --> investigate!!
        this.state.current_user = this.props.user_id;
        //console.log("xxx---> load skills of user="+this.props.user_id);
        this.loadUserRoles();
      }
    }

    componentDidMount() {
      this.loadUserRoles();
    }

    setContains( setItems, item) {
      for(var i=0; setItems && i<setItems.length; i++) {
        if(setItems[i]===item) {
          return true;
        }
      }
      return false;
    }

    createCheckBoxList( allRoles, userRoles) {
      var checkBoxList = [];
      for( var i=0; allRoles && i<allRoles.length; i++) {
        checkBoxList.push(
          {name:allRoles[i],isChecked:this.setContains(userRoles,allRoles[i])}
        );
      }
      return checkBoxList;
    }

    debugCheckBoxes( boxes) {
      for(var i=0; i<boxes.length; i++) {
        //console.log(boxes[i].name+"/"+boxes[i].isChecked);
      }
    }

    loadUserRoles() {
      if(this.props.user_id==='undefined') {
        //console.log("xxxxxxxxx undefined user, do not load data");
      }
      //console.log("=========>loading user roles, userID="+this.props.user_id+" allRoles="+this.props.roles);
      if(this.props.user_id==='undefined') {
        //console.log('undefined user, do not try to load');
        return;
      }
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      var url = ETVConstants.getServerBase()+"/useradmin?user_id="+this.state.current_user+"&action=get_roles";
      //console.log(url);
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            var chxBxList = this.createCheckBoxList(this.props.roles,result["roles"]);
            this.debugCheckBoxes(chxBxList);
            this.setState({checkBoxList:chxBxList})
          }
        )
    }

    createSet( list) {
      var skillStr = '[';
      for(var i=0; i<list.length; i++) {
        skillStr += list[i];
        if(i!==list.length-1) {
          skillStr+=',';
        }
      }
      skillStr += ']';
      return skillStr;
    }

    roleCallback( list) {
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      if(this.props.user_id===undefined) {
        return;
      }
      var urlStr = ETVConstants.getServerBase()+'/useradmin?action=set_roles&user_id='+this.props.user_id+'&roles='+encodeURIComponent(this.createSet(list));
      //console.log(urlStr);
      fetch(urlStr,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then((response) => response.json())
			.then((responseJson) => {
				//console.log("updatedRoles")
			})
			.catch((error) => {
				 console.error(error);
			});
    }

    translateRole( roleName) {
      var name =  roleName.substring(roleName.lastIndexOf(".")+1);
      if (name==='library') return ETVConstants.trans("content_library");
      if (name==='access_marketplace') return ETVConstants.trans("access_to_market_place");
      if (name==='contribute_marketplace') return ETVConstants.trans("contribute_to_market_place");
      if (name==='playlist') return ETVConstants.trans("playlist");
      if (name==='create_text') return ETVConstants.trans("create_texts");
      if (name==='weather_info') return ETVConstants.trans("weather_information");
      if (name==='surveys') return ETVConstants.trans("create_surveys");
      if (name==='coupons') return ETVConstants.trans("create_coupons");
      if (name==='reservations') return ETVConstants.trans("create_reservations");
      if (name==='device_configuration') return ETVConstants.trans("devices_configuration");
      if (name==='remote_management') return ETVConstants.trans("remote_management_devices");
      if (name==='layout_designer') return ETVConstants.trans("layout_designer");
      if (name==='user_management') return ETVConstants.trans("user_management");
      if (name==='elevator_config') return ETVConstants.trans("elevator_interface_configuration");
      if (name==='elevator_statistics') return ETVConstants.trans("elevator_statistics");
      if (name==='about') return ETVConstants.trans("about");
      if (name==='cam') return ETVConstants.trans("camera");
      return "** "+name+" ***";
    }

    setBasicUser() {
      var cbl = ['library','playlist','device_configuration'];
      this.setState({checkBoxList:cbl});
      this.roleCallback(cbl);
    }

    setPremiumUser() {

    }

    render() {
      return (
        <div>
          {this.state.checkBoxList?<CheckBoxList2 callback={this.roleCallback} title='Access Rights' list={this.state.checkBoxList} translateF={this.translateRole} />:<span/>}
        </div>
      )
    }
}

export default RolesList;
