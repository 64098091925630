import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import ChipInput from "material-ui-chip-input";
import * as ETVConstants from '../ETVConstants';

class SkillsDefinition extends React.Component {

    state = {
      isLoaded: false,
			chip_values: [],
			chips_changed:false,
    }

    componentDidMount() {
			this.loadAvailableSkills();
    }

		componentDidUpdate() {
			if(!this.state.isLoaded) {
				this.loadAvailableSkills();
			}
		}

		loadAvailableSkills() {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var urlStr = ETVConstants.getServerBase()+"/useradmin?action=get_skills&user_id="+this.props.user_id;
      fetch(urlStr,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						//console.log("userID="+this.props.user_id+" skills available="+JSON.stringify(result));
            this.setState({
              chip_values: result.skills,
							isLoaded:true,
            });
          },
          (error) => {
            this.setState({
              chip_values: [],
              error
            });
          }
        )
    }

		// Add Chips
		handleAddChip = (chip) => {
			var curChips = this.state.chip_values;
			curChips.push(chip);
			this.setState({chip_values:curChips,chips_changed:true});
  	}
		// Delete Chips
		handleDeleteChip = (chip) => {
				var curChips = this.state.chip_values;
				var index = curChips.indexOf(chip);
				if(index>-1) {
					curChips.splice(index,1);
				}
				this.setState({chip_values:curChips,chips_changed:true});
  	}

		sendCMDToServer(url) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then((response) => response.json())
				.then((responseJson) => {
					 this.setState({
							isLoaded: false
					 })
				})
				.catch((error) => {
					 console.error(error);
				});
    }

		saveAvailableSkillSet() {
			var url = ETVConstants.getServerBase()+"/useradmin?action=set_skills&user_id="+this.props.user_id;
			for(var i=0; i<this.state.chip_values.length; i++) {
				url += "&skill="+encodeURIComponent(this.state.chip_values[i]);
			}
			//console.log("saveAvailableSkillSet url="+url);
			this.sendCMDToServer(url);
			this.setState({chips_changed:false});
			//this.showWindow("skills_div",false);
		}

    render() {
      return(
				<Card>
					<CardContent>
								<ChipInput
										label={ETVConstants.trans("skills")}
										color='primary'
										value={this.state.chip_values}
										onAdd={(chip) => this.handleAddChip(chip)}
										onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
								/>
					</CardContent>
					<CardActions>
						<Button disabled={!this.state.chips_changed} size="small" variant="contained" color="secondary" onClick={() => this.saveAvailableSkillSet()}>{ETVConstants.trans("save")}</Button>
					</CardActions>
				</Card>
			)
	}
}

export default SkillsDefinition
