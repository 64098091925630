import React from 'react';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import BasicTable2 from '../tools/BasicTable2';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import * as ETVConstants from '../ETVConstants';

class OnPremisesServers extends React.Component {

    constructor(props) {
      super(props);
			this.resultListener = this.resultListener.bind(this);
    }

    state = {
      list:[],
      isLoaded:false,
    }

    componentDidUpdate() {
      if(!this.state.isLoaded) {
        this.loadServers();
      }
		}

    componentDidMount() {
      this.loadServers();
    }

    loadServers() {
			var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/on_premises_admin_service?user_id="+this.props.user_id+"&action=list";
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            console.log(JSON.stringify(result));
            this.setState({
              list: result.list,
              isLoaded:true
            });
          },
          (error) => {
            this.setState({
              list: [],
              isLoaded:true,
              error
            });
          }
        )
    }

    showWindow(divID, show) {
      show?this.setState({window:divID}):this.setState({window:''});
    }

    showInfoMessage(msg) {
      this.setState({window:'message_div',info_message:msg});
    }

    closeInfoMessage() {
      this.setState({info_message:undefined});
      this.showWindow('message_div',false);
    }

    restartTeamViewer(serverID) {
      	var url = ETVConstants.getServerBase()+"/todo";
        ETVConstants.sendCMDToServerListener(url,this.resultListener);
    }

    resultListener(val) {
			this.setState({isLoaded:false});
		}

    resultListener( res) {
      console.log("res="+JSON.stringify(res));
      this.showInfoMessage("success");
    }

    startTeamViewer( serverID) {
      	var url = ETVConstants.getServerBase()+"/on_premises_admin_service?server_id="+serverID+"&action=command&command="+encodeURIComponent("systemctl start teamviewerd");
        ETVConstants.sendCMDToServerListener(url,this.resultListener);
    }

    render() {

      const tableTitle = "";

      const serversData= [
          { title: ETVConstants.trans("last_connect"), field: 'last_connect', render: rowData => <Typography variant='body2'>{ETVConstants.getLastConnectInfo(rowData.last_connect)}</Typography> },
          { title: ETVConstants.trans("server_name"), field: 'server_name' },
          { title: ETVConstants.trans("public_ip"), field: 'public_ip' },
          { title: ETVConstants.trans("restart_teamviewer"), field: 'select_layout', render: rowData => <Button variant='contained' size='small' color='secondary' onClick={()=>this.startTeamViewer(rowData.server_id)}>TeamViewer</Button>},
      ];

      return (
        <div style={{width:800}}>
          <Card>
            <CardContent>
                <Typography variant="h5">{ETVConstants.trans("on_premise_servers")}</Typography>
                <Typography variant="body1" style={{marginTop:10}}>
                    {ETVConstants.trans("on_premise_servers_desc")}
                </Typography>
                <BasicTable2 style={{marginTop:20}} data={this.state.list} columns={serversData} title={tableTitle} pageSize={5} allow_search={true} />
            </CardContent>
          </Card>

          <Dialog
            open={this.state.window==='message_div'}
            maxWidth='xs'
            fullWidth={true}
            onClose={()=>{this.setState({window:''})}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
            <DialogContent>
                <Typography variant='body1'>{this.state.info_message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
            </DialogActions>
          </Dialog>

        </div>
      )
    }
}

export default OnPremisesServers;
