import React from 'react';
import axios from 'axios';
import Image from 'material-ui-image';
import Dropzone from "react-dropzone";
import {Button,Divider,TextField} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {CircularProgress} from '@material-ui/core';
import * as ETVConstants from '../ETVConstants';

class FloorImages extends React.Component {

    state = {
			imageWidth: 150,
			ioProps:undefined,
      ioPropsKeys:[], //for sorting
			info_message:"",
			info_title:"",
			signal: '',
      isUploading:false,
    }

		componentDidUpdate() {
			if(!this.state.isLoaded) {
				this.loadItems();
			}
		}

    componentDidMount() {
      this.loadItems();
    }

    sortIoPropsKeys( props) {
      var keys = Object.keys(props);
      keys.sort( (a,b) => {return this.compareKey(props, a, b)})
      return keys;
    }

    compareKey(props, key1, key2) {
      //console.log("xxxxx");
      if(key1==="image_default" || key1==="image_door_opening" || key2==="image_default" || key2==="image_door_opening") {
        //console.log("comparing image default="+key1);
        if(key1==="image_default") return -1;
        if(key2==="image_default") return 1;
        if(key1==="image_door_opening") return -1;
        if(key2==="image_door_opening") return 1;
      }
      var val1 = props[key1].trim();
      var val2 = props[key2].trim();
      if(!isNaN(val1) && !isNaN(val2)) {
        var intVal1 = parseInt(val1);
        var intVal2 = parseInt(val2);
        return intVal1-intVal2;
      }
      if(!isNaN(val1)) return -1;
      if(!isNaN(val2)) return 1;
      return val1.localeCompare(val2);
    }


    loadItems() {
      //console.log("---------------------loading floor sounds 4b37-b85b-bb56-76ed");
			var url = ETVConstants.getServerBase()+"/elevator_config?device_id="+this.props.device_id;
      //console.log("xxx loading url="+url);
      fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("result="+JSON.stringify(result));
            this.setState({
							ioProps:result,
              ioPropsKeys:this.sortIoPropsKeys(result),
              isLoaded: true,
            });
          },
          (error) => {
            this.setState({
              ioProps: undefined,
              isLoaded: false,
              error
            });
          }
        )
    }

		onDrop = (files) => {
        this.setState({isUploading:true});
				var targetDir = "/"+this.props.device_id+"/config";
				//console.log('ondrop, files='+files.length);
				this.setState({isLoaded:false});
				for( var i=0; i<files.length; i++) {
					//console.log("drop files="+files[i]);
					if(ETVConstants.ON_PREMISE_VERSION) {
						this.uploadFileToOnPremise( files[i], targetDir);
					}else{
						this.uploadFileToGCS( files[i], targetDir);
					}
				}
		}

		givePublicAccess(target_dir,filename) {
			//console.log("---------------------giving public access");
			var url = ETVConstants.getServerBase()+"/gcs_upload?action=public_access&target_dir="+target_dir+"&filename="+filename;
			//console.log("url="+url);
			fetch(url)
				.then( res => res.json() )
				.then(
						(result) => {
						},
						(error) => {
						}
					)
		}

		getEnding( filename) {
			var ending = filename.substring(filename.lastIndexOf('.'),filename.length);
			return ending;
		}

		updateProgress( size, loaded) {
			//console.log("progress, size="+size+" loaded="+loaded);
		}

		uploadFileToOnPremise( file, targetDir) {
			if(!this.state.signal || this.state.signal.length===0) {
				return;
			}
			var filename = this.state.signal+this.getEnding(file.name);
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var formData = new FormData();
			formData.append('file', file);
			formData.append('filename', filename);
			formData.append('target_dir',targetDir);
			//console.log("filename="+filename);

			const config = {
				headers: {	'content-type': 'multipart/form-data'},
				onUploadProgress: progressEvent => this.updateProgress(file.size, progressEvent.loaded)
			}

			var url = ETVConstants.getServerBase()+"/file_premise_upload";
			axios.post(url, formData, config)
					.then((response) => {
						this.setState({isLoaded:false,isUploading:false});
					})
					.catch((error) => {
						 console.error(error);
					});
		}


		uploadFileToGCS( file, targetDir) {
			//console.log("---------------------uploading file to GCS");
			if(!this.state.signal || this.state.signal.length===0) {
				//console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz");
				return;
			}
			var filename = this.state.signal+this.getEnding(file.name);
			//console.log("filename="+filename);
			var url = ETVConstants.getServerBase()+"/gcs_upload?action=request_upload&target_dir="+targetDir+"&filename="+filename;
      //console.log("xxxxxxxxxxxxxx request GCS Upload, url="+url);
      fetch(url)
				.then(res => res.json())
				.then(
					(result) => {
						var formData = new FormData();
						Object.entries(result.fields).map(([key,value])=>{
								formData.append(key,value);
								//console.log("1=======>"+key+"="+value);
						})
						formData.append('file', file);
						//formData.append('success_action_redirect',)
						const config = {
	    				headers: {	'content-type': 'multipart/form-data'}
						}
						axios.post(result.url, formData, config)
								.then((response) => {
									this.givePublicAccess(targetDir,filename);
									this.setState({isLoaded:false,isUploading:false});
								})
								.catch((error) => {
									 console.error(error);
								});
					},
					// Note: it's important to handle errors here
					// instead of a catch() block so that we don't swallow
					// exceptions from actual bugs in components.
					(error) => {
						//console.log("errrrrror="+error);
					}
				)
		}

		isBinary( val) {
			var regEx = /^[0-1]{1,}$/;
			return val.match(regEx)!==null;
		}

		createFloorItem( itemKey) {
			var key = itemKey; // 001101
			//console.log("key="+key);
			//var val = this.state.ioProps[key];
			if(key==='image_default') {
				//console.log("default image!!");
				var imageURL = this.state.ioProps[key];
				var imagePath = this.state.ioProps["image_path_default"];
				return (<TableRow>
									<TableCell>
										<TextField size='small' key={key} label="" disabled={true} value={"default image"}/>
									</TableCell>
									<TableCell>
										{this.isImage(imageURL)?<Image src={imageURL} aspectRatio={4.0/3.0} style={{width:200,height:'inherit'}} />:
										this.isVideo(imageURL)?<video src={imageURL} width={200} height='auto' controls >no video available</video>:null}
									</TableCell>
									<TableCell>
										<Button style={{marginTop:10}} size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(imagePath)}>{ETVConstants.trans("delete")}</Button>
									</TableCell>
								</TableRow>)

      }
      if(key==='image_door_opening') {
          				//console.log("default image!!");
          				var imageURL1 = this.state.ioProps[key];
          				var imagePath1 = this.state.ioProps["image_path_door_opening"];
          				return (<TableRow>
          									<TableCell>
          										<TextField size='small' key={key} label="" disabled={true} value={"door opening"}/>
          									</TableCell>
          									<TableCell>
          										{this.isImage(imageURL1)?<Image src={imageURL1} aspectRatio={4.0/3.0} style={{width:200,height:'inherit'}} />:
          										this.isVideo(imageURL1)?<video src={imageURL1} width={200} height='auto' controls >no video available</video>:null}
          									</TableCell>
          									<TableCell>
          										<Button style={{marginTop:10}} size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(imagePath1)}>{ETVConstants.trans("delete")}</Button>
          									</TableCell>
          								</TableRow>)

			}
      if(this.isBinary(key)) {
				var floor = this.state.ioProps[key];
				var imagePath2 = this.state.ioProps["image_path_"+floor];
				var imageURL2 = this.state.ioProps["image_"+floor];
				if(imageURL2) {
					//console.log("xxximageUrl="+imageURL+" imageWidth="+this.state.imageWidth+" isImage="+this.isImage(imageURL));
					return (<TableRow>
										<TableCell>
											<TextField size='small' key={key} label="" disabled={true} value={"Floor "+floor}/>
										</TableCell>
										<TableCell>
											{this.isImage(imageURL2)?<Image src={imageURL2} aspectRatio={1.333} style={{width:200}} />:
											this.isVideo(imageURL2)?<video src={imageURL2} width={200} height='auto' controls >no video available</video>:null}
										</TableCell>
										<TableCell>
											<Button style={{marginTop:10}} size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(imagePath2)}>{ETVConstants.trans("delete")}</Button>
										</TableCell>
									</TableRow>)
				}
				return null;
			}
			return null;
		}

		showWindow(divID, show) {
			this.setState({show_overlay:show});
			show?this.setState({window:divID}):this.setState({window:''});
		}

		showInfoMessage(title,msg) {
			this.showWindow('message_div',true);
			this.setState({info_title:title, info_message:msg});
		}

		closeInfoMessage() {
			this.setState({info_message:undefined});
			this.showWindow('message_div',false);
		}

		loadImage( imgURL) {
			//console.log("load image URL="+imgURL);
			if(imgURL) {
				return <Image src={imgURL} style={{width:this.state.imageWidth}} />
			}else{
				return <span/>
			}
		}

		deleteFile( imgPath) {
			var url = ETVConstants.getServerBase()+"/gcs_upload?action=delete&file_uri="+imgPath;
			//console.log("xxxxxxxxxxxxxx delete file_uri="+imgPath);
			fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("result="+JSON.stringify(result));
            this.setState({
              isLoaded: false,
            });
          },
          (error) => {
            this.setState({
              isLoaded: false,
              error
            });
          }
        )
		}

		hasFile( val) {
			return this.state.ioProps && this.state.ioProps[val]!==null
		}

		isImage( val) {
			if(!val) return false;
			if (val.toLowerCase().indexOf(".jpg")>=0) return true;
			if (val.toLowerCase().indexOf(".jpeg")>=0) return true;
			if (val.toLowerCase().indexOf(".bmp")>=0) return true;
			if (val.toLowerCase().indexOf(".png")>=0) return true;
			if (val.toLowerCase().indexOf(".gif")>=0) return true;
			if (val.toLowerCase().indexOf(".webp")>=0) return true;
			return false;
		}

		isVideo( val) {
			if(!val) return false;
			if (val.toLowerCase().indexOf(".mp4")>=0) return true;
      if (val.toLowerCase().indexOf(".mpg")>=0) return true;
      if (val.toLowerCase().indexOf(".mpeg")>=0) return true;
      return false;
		}

		createMenuItem( key) {
			var isBinary = this.isBinary(key);
			var floorName = this.state.ioProps[key];
			//console.log(key+" isBinary="+isBinary);
			if (isBinary && floorName!=='-') {
				return <MenuItem key={key} value={floorName}>Floor {floorName}</MenuItem>
			}else{
				return <span key={key}/>
			}
		}

    render() {
      var ioPropsKeys = this.state.ioPropsKeys;
      //console.log("ioMapKeys="+JSON.stringify(ioPropsKeys));
      var hasPropsLoaded = ioPropsKeys && ioPropsKeys.length>0;
      return(
				<div style={{width:'700px'}}>
						<Typography variant="body2">DeviceID: {this.props.device_id}</Typography>
						<Divider/>
						<Table>
							<TableBody>
							<TableRow>
									<TableCell>
									<FormControl>
										 <InputLabel>Signal</InputLabel>
										 <Select
											 labelId="signal"
											 id="signal"
											 style={{ width: 150}}
											 value={this.state.signal}
											 onChange={(e)=>{this.setState({signal:e.target.value})}}>
											 <MenuItem value='default'>Default Image</MenuItem>
											 <MenuItem value='door_opening'>Door Opening</MenuItem>
											 {hasPropsLoaded?ioPropsKeys.map( (key, i) => { return this.createMenuItem(key) }):<span/>}
										 </Select>
									 </FormControl>
									</TableCell>
									<TableCell>
                    {this.state.isUploading?
                      <CircularProgress style={{marginTop:20}} size={100} />:
                      this.state.signal?
    										<div>
    												<Dropzone
    														onDrop={this.onDrop}
    														disabled={this.state.signal===undefined}
    														accept="image/*, video/mp4, video/x-m4v, video/mpg, video/mpeg"
    														multiple={false}
    														minSize={1024}
    														maxSize={64288000}	>

    												{({ getRootProps, getInputProps }) => (
    													<div {...getRootProps({ className: "dropzone" })}>
    															<input {...getInputProps()} />
    															<p>{this.state.signal?"click or drag and drop file here":"please select signal"}</p>
    													</div>
    												)}
    												</Dropzone>
    										</div>
                        :
                        <span/>
                    }
									</TableCell>
									<TableCell>
									</TableCell>
								 </TableRow>
							</TableBody>
						</Table>
						<Divider />
						<Divider style={{marginTop:20}} />
						<Table>
							<TableBody>
								{!hasPropsLoaded?<TableRow><TableCell></TableCell><TableCell></TableCell><TableCell></TableCell></TableRow>:ioPropsKeys.map( (key, i) => { return (this.createFloorItem(key)) })}
              </TableBody>
						</Table>

						<Dialog
							open={this.state.window==='message_div'}
							maxWidth='xs'
							fullWidth={true}
							onClose={()=>{this.setState({window:''})}}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description">
							<DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
							<DialogContent>
									<Typography variant="body1">{this.state.info_message}</Typography>
							</DialogContent>
							<DialogActions>
								<Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
							</DialogActions>
						</Dialog>
		   	</div>
			);
	}
}

export default FloorImages
