import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormattedMessage} from 'react-intl';
import * as ETVConstants from '../ETVConstants';

class EditUserAccount extends React.Component {

		state = {
			isLoaded: false,
			firstname:'',
			lastname:'',
			company:'',
			address1:'',
			address2:'',
			zip:'',
			city:'',
			country:'',
			phone:'',
			url:'',
			edit:false,
			otp_phone:'',
			otp_phone_helper:'',
			otp_enabled: false,
			user:'',
			info_title:'',
			info_message:'',
		}

    componentDidMount() {
			this.loadData();
    }

		loadData() {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
      //console.log("adding user id="+username+"/firstname="+firstname+" last="+lastname+" pw="+password+" parentID="+parentID);
			var url = ETVConstants.getServerBase()+"/useradmin?action=get_user&user_id="+this.props.user_id;
			//console.log("--------------->url="+url);
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
      .then((response) => response.json())
      .then((user) => {
         this.setState({
            parent_email: user.user_name,
						parent_firstname: user.firstname,
						parent_lastname: user.lastname,
						firstname:user.firstname,
						lastname:user.lastname,
						company:user.company,
						address1:user.address_1,
						address2:user.address_2,
						zip:user.zip,
						city:user.city,
						country:user.country,
						phone:user.phone,
						url: user.url,
						edit:false,
						otp_phone:user.phone,
						otp_phone_helper_ok:true,
						otp_enabled: user.use_2_factor_authentication,
						user:user,
						isLoaded:true,
         })
      })
      .catch((error) => {
         //console.log("Error="+error);
      });
    }

		componentDidUpdate() {
			if(!this.state.isLoaded) {
				this.loadData();
			}
		}

		showWindow(divID, show) {
			this.setState({show_overlay:show});
			{ show?this.setState({window:divID}):this.setState({window:''}) };
		}

		showInfoMessage(title,text) {
			this.setState({info_title:title,info_message:text});
			this.showWindow('message_div',true);
		}

		closeInfoMessage() {
			this.setState({info_title:'',info_message:''});
			this.showWindow('message_div',false);
		}

		sendCMDToServer(url) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then((response) => response.json())
				.then((responseJson) => {
					if(responseJson['result']) {
						this.showInfoMessage(ETVConstants.trans("success"),ETVConstants.trans("user_info_saved"));
						this.setState({edit:false, isLoaded:false});
					}else{
						this.showInfoMessage(ETVConstants.trans("error"),"Failed to save, reason: "+responseJson['reason']);
					}
				})
				.catch((error) => {
					 console.error(error);
				});
		}

		saveValue( val) {
			if(!val) return "";
			if(val==='undefined') return "";
			if(val===null) return "";
			if(val==="null") return "";
			return val;
		}

		updateInfo() {
			// validate info
			if(!this.isPhone(this.state.phone)) {
					this.setState({otp_phone_helper_ok:false});
					return;
			}
			var url = ETVConstants.getServerBase()+"/useradmin?action=update_user_info&user_id="+this.props.user_id
			url += "&firstname="+encodeURIComponent(this.saveValue(this.state.firstname));
			url += "&lastname="+encodeURIComponent(this.saveValue(this.state.lastname));
			url += "&company="+encodeURIComponent(this.saveValue(this.state.company));
			url += "&address_1="+encodeURIComponent(this.saveValue(this.state.address1));
			url += "&address_2="+encodeURIComponent(this.saveValue(this.state.address2));
			url += "&zip="+encodeURIComponent(this.saveValue(this.state.zip));
			url += "&city="+encodeURIComponent(this.saveValue(this.state.city));
			url += "&country="+encodeURIComponent(this.saveValue(this.state.country));
			url += "&phone="+encodeURIComponent(this.saveValue(this.state.phone));
			url += "&url="+encodeURIComponent(this.saveValue(this.state.url));
			this.sendCMDToServer(url);
		}

		saveOTP() {
				var phone = this.state.otp_phone.trim();
				if(this.state.otp_enabled) {
					if(!this.isPhone(phone)) {
						this.setState({otp_phone_helper_ok:false});
						return;
					}
				}else{
					// otp not enabled --> phone === 0 or then correct
					if(phone.trim().length>0 && !this.isPhone(phone)) {
						this.setState({otp_phone_helper_ok:false});
						return;
					}
				}
				phone = phone.replace(/\s/g, '');
				var url = ETVConstants.getServerBase()+"/useradmin?action=update_2_factor_authentication&phone="+encodeURIComponent(phone)+"&user_id="+this.state.selected_user+"&use_2_factor_authentication="+this.state.otp_enabled;
				var accessToken = localStorage.getItem("etv.access_token");
				var reqUserID = localStorage.getItem("etv.user_id");
				//console.log(url);
				fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then((response) => response.json())
				.then((result) => {
						if(result["result"]!==true) {
							this.showInfoMessage(ETVConstants.trans("error"),ETVConstants.trans("could_not_update_2_factor_authentication"));
							this.setState({isLoaded:false});
						}else{
							this.setState({isLoaded:false, otp_enabled:false, otp_phone_helper_ok:true, otp_phone:'', window:''});
						}
				})
				.catch((error) => {
						//console.log(error);
					  //this.showInfoMessage("Error",error);
				});
		}

		isPremiumUser() {
				return this.props.status==="premium" || this.props.status==="enterprise";
		}

		isPhone( phone) {
			// remarks: ^abc$ --> means exact match
			// "/" start and "/" end tags
			// \s* as many whitespace as wishes
			// {7,} at least 7 numbers
			// \+ must start with +
			const PHONE_REGEX =  /^\+\s*[0-9\s*]{7,}$/;
			//console.log("testing phone="+PHONE_REGEX.test(phone));
			return PHONE_REGEX.test(phone);
		}

		show2FactorAuth(user) {
			//console.log(JSON.stringify(user));
			this.setState({selected_user:user.user_id, otp_phone:user.phone, otp_enabled:user.use_2_factor_authentication});
			this.showWindow('2_fac_auth_div',true);
		}

    render() {

			var isCloudUser = this.isPremiumUser();
			//{isCloudUser?<Button style={{marginTop:10}} color="primary" size='small' variant='contained' color='primary' onClick={() => this.show2FactorAuth(this.state.user)}>2 Factor Authentication</Button>:<span/>}

      return(
				<div>
							<div style={{width:500, display:'flex', alignItems:'center', justifyContent:'center', flex:'1', margin:'0 auto' }}>
									<Card>
										<CardContent>
											<Typography variant='h6'>
												{ETVConstants.trans("my_details")}
											</Typography>
											<Divider style={{marginTop:20}}/>
											<TextField style={{marginTop:10}} key='fn' disabled={!this.state.edit} label={ETVConstants.trans("firstname")} fullWidth={true} value={this.state.firstname} onChange={(event)=>{this.setState({firstname:event.target.value})}} />
											<TextField style={{marginTop:10}} key='ln' disabled={!this.state.edit} label={ETVConstants.trans("lastname")} fullWidth={true} value={this.state.lastname} onChange={(event)=>{this.setState({lastname:event.target.value})}} />
											<TextField style={{marginTop:10}} key='cp' disabled={!this.state.edit} label={ETVConstants.trans("company")} fullWidth={true} value={this.state.company} onChange={(event)=>{this.setState({company:event.target.value})}} />
											<TextField style={{marginTop:10}} key='address1' disabled={!this.state.edit} label={ETVConstants.trans("address_line_1")} fullWidth={true} value={this.state.address1} onChange={(event)=>{this.setState({address1:event.target.value})}} />
											<TextField style={{marginTop:10}} key='address2' disabled={!this.state.edit} label={ETVConstants.trans("address_line_2")} fullWidth={true} value={this.state.address2} onChange={(event)=>{this.setState({address2:event.target.value})}} />
											<TextField style={{marginTop:10}} key='zip' disabled={!this.state.edit} label={ETVConstants.trans("zip")} fullWidth={true} value={this.state.zip} onChange={(event)=>{this.setState({zip:event.target.value})}} />
											<TextField style={{marginTop:10}} key='city' disabled={!this.state.edit} label={ETVConstants.trans("city")} fullWidth={true} value={this.state.city} onChange={(event)=>{this.setState({city:event.target.value})}} />
											<TextField style={{marginTop:10}} key='country' disabled={!this.state.edit} label={ETVConstants.trans("country")} fullWidth={true} value={this.state.country} onChange={(event)=>{this.setState({country:event.target.value})}} />
											<TextField style={{marginTop:10}} error={!this.state.otp_phone_helper_ok} helperText={this.state.otp_phone_helper_ok?"":ETVConstants.trans("invalid_phone_number")} key='phone' disabled={!this.state.edit} label={ETVConstants.trans("phone")} fullWidth={true} value={this.state.phone} onChange={(event)=>{this.setState({phone:event.target.value})}} />
											<TextField style={{marginTop:10}} key='url' disabled={!this.state.edit} label={ETVConstants.trans("url_optional")} fullWidth={true} value={this.state.url} onChange={(event)=>{this.setState({url:event.target.value})}} />
										</CardContent>
										<CardActions>
											<Button color="primary" size='small' disabled={this.state.edit} variant='contained' color='primary' onClick={() => this.setState({edit:true})}>{ETVConstants.trans("edit")}</Button>
											<Button color="secondary" size='small' disabled={!this.state.edit} variant='contained' color='secondary' onClick={() => this.updateInfo()}>{ETVConstants.trans("save")}</Button>
										</CardActions>
									</Card>
							</div>

							<Dialog
								open={this.state.window==='2_fac_auth_div'}
								maxWidth='xs'
								fullWidth={true}
								onClose={()=>{}}>
								<DialogTitle id="alert-dialog-title">{ETVConstants.trans("2_factor_authentication")}</DialogTitle>
								<DialogContent>
									<FormControlLabel control={<Checkbox checked={this.state.otp_enabled} onChange={()=>{this.setState({otp_enabled:!this.state.otp_enabled})}} />} label={ETVConstants.trans("2_factor_authentication_enabled")} />
									<TextField autoComplete='off' error={!this.state.otp_phone_helper_ok} helperText={this.state.otp_phone_helper_ok?"":ETVConstants.trans("invalid_phone_number")} style={{marginTop:10}} fullWidth={true} size="small" required id="otp_phone" label={ETVConstants.trans("phone")} value={this.state.otp_phone} onChange={(e)=>{this.setState({otp_phone:e.target.value})}} onInput={()=>{this.setState({otp_phone_helper_ok:true})}} />
								</DialogContent>
								<DialogActions>
									<Button onClick={()=>{this.showWindow('2_fac_auth_div',false)}} color="primary">{ETVConstants.trans("close")}</Button>
									<Button disabled={ (this.state.otp_phone.trim().length===0 && this.state.otp_enabled) || this.state.otp_phone_helper.length>0} onClick={()=>{this.saveOTP()}} color="secondary" variant='contained'>{ETVConstants.trans("save")}</Button>
								</DialogActions>
							</Dialog>

							<Dialog
								open={this.state.window==='message_div'}
								maxWidth='xs'
								fullWidth={true}
								onClose={()=>{this.closeInfoMessage()}}>
								<DialogTitle id="message_div_title">{this.state.info_title}</DialogTitle>
								<DialogContent>
										<Typography variant="body1">{this.state.info_message}</Typography>
								</DialogContent>
								<DialogActions>
									<Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
								</DialogActions>
							</Dialog>
	   		</div>);
	}
}

export default EditUserAccount
