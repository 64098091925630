import React from 'react';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import {  MuiPickersUtilsProvider,  KeyboardDatePicker,} from '@material-ui/pickers';
import Chart from "react-google-charts";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as ETVConstants from '../ETVConstants';

class TravelStatistics extends React.Component {

    state = {
			loading:false,
			isLoaded:false,
			data:[],
			from_date:"2021-01-01",
			to_date:new Date(),
			data_available:false,
    }

		componentDidUpdate(prevProps, prevState) {
			if(this.state.loading) {
				return;
			}
			if(prevProps.comm_nr !== this.props.comm_nr) {
				//console.log("----------------------------commmm nr changed");
				this.loadItems();
				return;
			}
			if(!this.state.isLoaded) {
				this.loadItems();
			}
		}

    componentDidMount() {
      this.loadItems();
    }

		formatDate( date) {
			var d = new Date(date);
			return d.getFullYear()+"-"+(1+d.getMonth())+"-"+d.getDate();
		}

    loadItems() {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			this.setState({loading:true, data_available:true});
      //console.log("---------------------loading arriving statistics");
			var url = ETVConstants.getServerBase()+"/elevator_bigquery_servlet?action=arriving_data&from_date="+this.formatDate(this.state.from_date)+"&to_date="+this.formatDate(this.state.to_date)+"&commission_nr="+this.props.comm_nr;
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
							if(result["result"]) {
		            //console.log("result="+JSON.stringify(result));
								var d = this.extractData(result);
								this.setState({loading:false, isLoaded:true, data:d, data_available:true});
							}else{
								this.setState({data_available:false,isLoaded:true,loading:false,data:[]});
							}
          },
          (error) => {
						this.setState({data_available:false,isLoaded:true,loading:false,data:[]});
          }
        )
    }

		extractData( jsonData) {
			var data = [];
			data.push( ["Floor","Count"]);
			var jsonArr = jsonData["arriving_data"];
			for(var i=0; i<jsonArr.length; i++) {
				var floor = jsonArr[i]["floor"];
				var arrivals = jsonArr[i]["count"];
				data.push( [floor,arrivals]);
			}
			return data;
		}

    render() {
      return(
				<div>
							<Typography variant="body1" >{ETVConstants.trans("commission_nr")}</Typography>
							<Typography variant="body1" style={{fontWeight:'bold'}} >{this.props.comm_nr}</Typography>
							{!this.state.data_available?
								<Typography variant="h6"><div style={{margin:'auto',marginTop:20}}>{ETVConstants.trans("no_data_available")}</div></Typography>
								:
								<div>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													margin="normal"
													id="date-picker-dialog"
													label="from date"
													value={this.state.from_date}
													format='yyyy-MM-dd'
													onChange={(date)=>{this.setState({from_date:date,isLoaded:false})}}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}} />
										<span style={{marginTop:20}}/>
												<KeyboardDatePicker
														margin="normal"
														id="date-picker-dialog"
														label="until date"
														format='yyyy-MM-dd'
														value={this.state.to_date}
														onChange={(date)=>{this.setState({to_date:date,isLoaded:false})}}
														KeyboardButtonProps={{
															'aria-label': 'change date',
														}} />

										 </MuiPickersUtilsProvider>
												 {this.state.loading?
													 <CircularProgress />
													 :
													 <div style={{ display: 'flex', maxWidth:'100%', overflowY:'scroll',overflowX:'hidden' }}>
														 <Chart
															 width={600}
															 height={400}
															 chartType="BarChart"
															 loader={<div>Loading Chart</div>}
															 data={this.state.data}
															 options={{
																 title: this.props.chart_title,
																 chartArea: { width: '50%' },
																 hAxis: {
																	 title: '',
																	 minValue: 0,
																 },
																 vAxis: {
																	 title: '',
																	 minValue: 0,
																 },
															 }}
															 legendToggle
														 />
													 </div>
											 }
								 </div>
							 }
				</div>
			);
	}
}

export default TravelStatistics
