import React from 'react';
import BasicTable2 from "../tools/BasicTable2";
import IconButton from '@material-ui/core/IconButton';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import * as ETVConstants from '../ETVConstants';

class ScreenSelection extends React.Component {

	constructor(props) {
      super(props);
      this.state = {
        items:[],
        selected: [],
        isLoaded: false,
      };
    }

    state = {
      items:[],
      selected: [],
      isLoaded: false,
    }

		componentDidUpdate(prevProps,prevState) {
      if(!this.state.isLoaded) {
        this.loadItems();
      }
      if(prevProps.content_id!==this.props.content_id) {
        this.loadItems();
      }
		}

    componentDidMount() {
      this.loadItems();
    }

    loadItems() {
      //console.log("xx---------------------loading screens");
			var url = ETVConstants.getServerBase()+"/playlist?action=selected_devices&user_id="+this.props.user_id+"&channel="+this.props.channel+"&content_id="+this.props.content_id;
      //console.log("loading url="+url);
      fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("items="+result.all_devices.length);
						var allDevices = result.all_devices;
						allDevices.sort( (a,b) => a.device_name.localeCompare(b.device_name));
            this.setState({
              items: allDevices,
              selected: result.selected_devices,
              isLoaded: true,
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              items:[],
              isLoaded: false,
              error
            });
          }
        )
    }

    addContentToDevice( deviceID) {
      var contentID = this.props.library_element.content_id;
      //console.log("add content to playlist");
      var url = ETVConstants.getServerBase()+"/playlist?action=add_content_to_device&content_id="+contentID+"&device_id="+deviceID+"&channel="+this.props.channel;
      this.updateScreenList(url);
      this.setState({isLoaded:false});
      if(this.props.updatedHook) {
        this.props.updatedHook();
      }
    }

    removeContentFromDevice( deviceID) {
      var contentID = this.props.library_element.content_id;
      //console.log("remove from playlist");
      var url = ETVConstants.getServerBase()+"/playlist?action=remove_content_from_device&content_id="+contentID+"&device_id="+deviceID+"&channel="+this.props.channel;
      this.updateScreenList(url);
      this.setState({isLoaded:false});
      if(this.props.updatedHook) {
        this.props.updatedHook();
      }
    }

    updateScreenList( url) {
			//console.log("xxx---->"+url);
			fetch(url)
				.then( res => res.json() )
				.then(
						(result) => {
							this.setState({isLoaded:false});
						},
						(error) => {
              this.setState({isLoaded:false});
							//console.log("yyy-------> "+error);
						}
					)
		}

    showWindow(divID, show) {
      this.setState({show_overlay:show});
      show?this.setState({window:divID}):this.setState({window:''});
    }

    isSelectedScreen( deviceID) {
      if(!this.state.selected) return false;
      for(var i=0; i<this.state.selected.length; i++) {
        if(this.state.selected[i]===deviceID) {
          return true;
        }
      }
      return false;
    }

    render() {

      const columnsData= [
					{ title: 'select', field: 'selected', render: rowData => this.isSelectedScreen(rowData.id)?
                <IconButton variant='contained' color='primary' onClick={()=>this.removeContentFromDevice(rowData.id)}><CheckBoxIcon/></IconButton>
                :
                <IconButton variant='contained' color='primary' onClick={()=>this.addContentToDevice(rowData.id)}><CheckBoxOutlineBlankIcon/></IconButton>},
					{ title: 'status', field: 'device_name', render: rowData => ETVConstants.getLastConnectSymbol(rowData.last_connected,rowData.startup_datetime,rowData.status) },
					{ title: 'Name', field: 'device_name' },
          { title: 'Location', field: 'location_name' },
          { title: 'Address', field: 'address' },
          { title: 'ZIP', field: 'zip' },
          { title: 'city', field: 'city' },

        ];

      const tabletitle = "Showing on Screens";

      return(
				<div>
	          <BasicTable2 data={this.state.items} columns={columnsData} title={tabletitle} pageSize={10} allow_search={true} />
		   	</div>
			);
	}
}

export default ScreenSelection
