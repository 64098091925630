import React from 'react';
import axios from 'axios';
import Dropzone from "react-dropzone";
import {Button,Divider,TextField} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as ETVConstants from '../ETVConstants';

class FloorSounds extends React.Component {

    state = {
			imageWidth: 150,
			ioProps:undefined,
      menuItems:[],
			info_message:"",
			info_title:"",
			signal: '',
      isLoading:true,
    }

		componentDidUpdate() {
			if(!this.state.isLoaded) {
				this.loadItems();
			}
		}

    componentDidMount() {
      this.loadItems();
    }

    loadItems() {
      //console.log("---------------------loading floor sounds 4b37-b85b-bb56-76ed");
			var url = ETVConstants.getServerBase()+"/elevator_config?device_id="+this.props.device_id;
      //console.log("xxx loading url="+url);
      fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("xxx result="+JSON.stringify(result));
            var allFloorItems = this.getAllFloorItems(result);
            this.setState({
							ioProps:result,
              menuItems:allFloorItems,
              usedFloorItems:this.filterFloorItems(result,allFloorItems,true),
              unusedFloorItems:this.filterFloorItems(result,allFloorItems,false),
              isLoaded: true,
              isLoading:false,
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              ioProps: undefined,
              isLoaded: false,
              isLoading:false,
              error
            });
          }
        )
    }

    filterFloorItems(ioProps,allFloorItems,used) {
      var res = [];
      allFloorItems.map( (item,index) => {
        var soundURL = ioProps["sound_"+item.value];
        if(used && soundURL) {
            res.push(item);
        }else if(!used && !soundURL) {
            res.push(item);
        }
      });
      return res;
    }

    //these are the menu items for the dropdown
    getAllFloorItems(props) {
      var res = [];
      Object.keys(props).map( (key, i) => {
        if(this.isBinary(key)) {
          //console.log("isBinary="+key+" val="+props[key]);
          var val = props[key].trim();
          if(val!=='dummy') {
              res.push( {key:key,value:val});
          }
          res.sort( (a,b) => this.compareSortVal(a,b));
        }
      });
      var res2 = this.removeDuplicates(res);
      return res2;
    }

    // there can be duplicates, because several gray codes may point to same floor number
    removeDuplicates(arr) {
      var res = [];
      var valSet = [];
      arr.map( (a,index) => {
        if(valSet.indexOf(a.value)<0) {
          valSet.push(a.value);
          res.push(a);
        }
      });
      return res;
    }

    compareSortVal( a, b) {
        var intA = parseInt(a.value);
        var intB = parseInt(b.value);
        if(isNaN(intA) && isNaN(intB)) {
          return a.value.localeCompare(b.value);
        }
        if(isNaN(intA)) return -1;
        if(isNaN(intB)) return 1;
        if(intA<=intB) return -1;
        return 1;
    }

		onDrop = (files) => {
        this.setState({isLoading:true});
				var targetDir = "/"+this.props.device_id+"/config";
				//console.log('ondrop, files='+files.length);
				//this.setState({isLoaded:false});
				for( var i=0; i<files.length; i++) {
					//console.log("drop files="+files[i]);
					if(ETVConstants.ON_PREMISE_VERSION) {
							this.uploadFileToOnPremise(files[i], targetDir);
					}else{
							this.uploadFileToGCS( files[i], targetDir);
					}
				}
		}

		givePublicAccess(target_dir,filename) {
			//console.log("---------------------giving public access");
			var url = ETVConstants.getServerBase()+"/gcs_upload?action=public_access&target_dir="+target_dir+"&filename="+filename;
			//console.log("url="+url);
			fetch(url)
				.then( res => res.json() )
				.then(
						(result) => {
							//console.log("result="+JSON.stringify(result, null, 2))
						},
						(error) => {
							//console.log("yyy-------> "+error);
						}
					)
		}

		getEnding( filename) {
			var ending = filename.substring(filename.lastIndexOf('.'),filename.length);
			return ending;
		}

		updateProgress( size, loaded) {
			console.log("size="+size+" loaded="+loaded);
		}

		uploadFileToOnPremise( file, targetDir) {
			if(!this.state.signal || this.state.signal.length===0) {
				return;
			}
			var filename = this.state.signal+this.getEnding(file.name);
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var formData = new FormData();
			formData.append('file', file);
			formData.append('filename', filename);
			formData.append('target_dir',targetDir);
			console.log("filename="+filename);
			const config = {
				headers: {	'content-type': 'multipart/form-data'},
				onUploadProgress: progressEvent => this.updateProgress(file.size, progressEvent.loaded)
			}

			var url = ETVConstants.getServerBase()+"/file_premise_upload";
			axios.post(url, formData, config)
					.then((response) => {
						this.setState({isLoaded:false,isLoading:false,signal:'---'});
					})
					.catch((error) => {
						 console.error(error);
					});
			}

		uploadFileToGCS( file, targetDir) {
			//console.log("---------------------uploading file to GCS");
			if(!this.state.signal || this.state.signal.length===0) {
				//console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz");
				return;
			}
			var filename = this.state.signal+this.getEnding(file.name);
			//console.log("filename="+filename);
			var url = ETVConstants.getServerBase()+"/gcs_upload?action=request_upload&target_dir="+targetDir+"&filename="+filename;
      //console.log("xxxxxxxxxxxxxx request GCS Upload, url="+url);
      fetch(url)
				.then(res => res.json())
				.then(
					(result) => {
						//console.log("///rx="+JSON.stringify(result));
						var formData = new FormData();
						Object.entries(result.fields).map(([key,value])=>{
								formData.append(key,value);
								//console.log("1=======>"+key+"="+value);
						})
						formData.append('file', file);
						//formData.append('success_action_redirect',)
						const config = {
	    				headers: {	'content-type': 'multipart/form-data'}
						}
						axios.post(result.url, formData, config)
								.then((response) => {
									this.givePublicAccess(targetDir,filename);
									this.setState({isLoaded:false,isLoading:false,signal:'---'});
								})
								.catch((error) => {
									 console.error(error);
								});
					},
					// Note: it's important to handle errors here
					// instead of a catch() block so that we don't swallow
					// exceptions from actual bugs in components.
					(error) => {
						//console.log("errrrrror="+error);
					}
				)
		}

		isBinary( val) {
			var regEx = /^[0-1]{1,}$/;
			return val.match(regEx)!=null;
		}

		createFloorItem( itemKey) {
			var key = itemKey; // 001101
			var val = this.state.ioProps[key];
			//console.log("createFloorItem "+key+" val="+val);

			if(this.isBinary(key)) {
				var floor = this.state.ioProps[key];
				var soundPath = this.state.ioProps["sound_path_"+floor];
				var soundURL = this.state.ioProps["sound_"+floor];
				//console.log("///////////// floor="+floor+" imagePath="+imagePath);
				if(soundURL) {
					return <table key={itemKey}>
						<tbody>
							<tr>
								<td><TextField size='small' key={key} label="" disabled={true} value={"Floor "+floor}/></td>
								<td>
									<audio controls src={soundURL} type={"audio/mpeg"}>Your browser does not support the audio element.</audio>
								</td>
								<td>
									<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(soundPath)}>delete</Button>
								</td>
							</tr>
						</tbody>
					</table>
				}
			}
			return <span key={itemKey}/>
		}

		showWindow(divID, show) {
			this.setState({show_overlay:show});
			show?this.setState({window:divID}):this.setState({window:''});
		}

		showInfoMessage(title,msg) {
			this.showWindow('message_div',true);
			this.setState({info_title:title, info_message:msg});
		}

		closeInfoMessage() {
			this.setState({info_message:undefined});
			this.showWindow('message_div',false);
		}

		deleteFile( imgPath) {
			var url = ETVConstants.getServerBase()+"/gcs_upload?action=delete&file_uri="+imgPath;
			//console.log("xxxxxxxxxxxxxx delete file_uri="+imgPath);
			fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("result="+JSON.stringify(result));
            this.setState({
              isLoaded: false,
            });
          },
          (error) => {
            this.setState({
              isLoaded: false,
              error
            });
          }
        )
		}

		hasFile( val) {
			return this.state.ioProps && this.state.ioProps[val]!=null
		}

		createSoundPlay( url) {
			return <audio controls src={url} type={"audio/mpeg"}>Your browser does not support the audio element.</audio>
		}

    render() {
      return(
				<div>
						<Typography variant="h5">{this.props.device_name}</Typography>
            <Typography variant="body2">{"("+this.props.device_id+")"}</Typography>
						<Divider style={{marginTop:10, marginBottom:10}}/>
						<table>
							<tbody>
							<tr>
									<td>
									<FormControl>
										 <InputLabel>Signal</InputLabel>
										 <Select
											 labelId="signal"
											 id="signal"
                       disabled={this.state.isLoading}
											 style={{ width: 200}}
											 value={this.state.signal}
											 onChange={(e)=>{this.setState({signal:e.target.value})}}>
                       <MenuItem value='---'>---</MenuItem>
											 <MenuItem value='arriving'>Arriving</MenuItem>
											 <MenuItem value='overload'>Overload</MenuItem>
											 <MenuItem value='up'>Up</MenuItem>
											 <MenuItem value='down'>Down</MenuItem>
											 <MenuItem value='fire_alarm'>Fire Alarm</MenuItem>

											 <MenuItem value='emergency_power'>Emergency Power(APK85+)</MenuItem>
											 <MenuItem value='maintenance'>Maintenance(APK85+)</MenuItem>
											 <MenuItem value='out_of_service'>Out of Service(APK85+)</MenuItem>
											 <MenuItem value='service_upper_deck'>Service Upper Deck(APK85+)</MenuItem>
											 <MenuItem value='service_lower_deck'>Service Lower Deck(APK85+)</MenuItem>
											 <MenuItem value='special_io_1'>Special IO 1 (APK85+)</MenuItem>
											 <MenuItem value='special_io_2'>Special IO 2 (APK85+)</MenuItem>
											 <MenuItem value='special_io_3'>Special IO 3 (APK85+)</MenuItem>
											 <MenuItem value='special_io_4'>Special IO 4 (APK85+)</MenuItem>

											 <MenuItem value='door_opening'>Door Opening</MenuItem>
											 <MenuItem value='door_open'>Door Open</MenuItem>
											 <MenuItem value='door_closing'>Door Closing</MenuItem>
											 <MenuItem value='door_closed'>Door Closed</MenuItem>
											 {this.state.unusedFloorItems?this.state.unusedFloorItems.map( (item, i) => { return <MenuItem key={item.key} value={item.value}>Floor {item.value}</MenuItem> }):<span/>}
										 </Select>
									 </FormControl>
									</td>
									<td>
										<div style={{marginRight:20}}>
                        {this.state.isLoading?
                            <CircularProgress/>
                            :
    												<Dropzone
    														onDrop={this.onDrop}
    														disabled={!this.state.signal || this.state.signal===undefined || this.state.signal==='---'}
    														accept="audio/mp3, audio/mpeg"
    														multiple={false}
    														minSize={1}
    														maxSize={524288000}	>

    												{({ getRootProps, getInputProps }) => (
    													<div {...getRootProps({ className: "dropzone" })}>
    															<input {...getInputProps()} />
    															<p>{(!this.state.signal || this.state.signal===undefined || this.state.signal==='---')?"please select signal":"click to upload sound file"}</p>
    													</div>
    												)}
    												</Dropzone>
                        }
										</div>
									</td>
									<td>
									</td>
								 </tr>
							</tbody>
						</table>
						<Divider style={{marginTop:10,marginBottom:10}} />
						<span>
							{this.hasFile('sound_door_opening')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'door_opening'} label="" disabled={true} value={'Door Opening'}/></td>
											<td>
												{this.createSoundPlay(this.state.ioProps["sound_door_opening"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_path_door_opening"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
							{this.hasFile('sound_door_closing')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'door_closing'} label="" disabled={true} value={'Door Closing'}/></td>
											<td>
											  {this.createSoundPlay(this.state.ioProps["sound_door_closing"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_path_door_closing"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
							{this.hasFile('sound_door_open')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'door_open'} label="" disabled={true} value={'Door Open'}/></td>
											<td>
												{this.createSoundPlay(this.state.ioProps["sound_door_open"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_path_door_open"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
							{this.hasFile('sound_door_closed')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'door_closed'} label="" disabled={true} value={'Door Closed'}/></td>
											<td>
												{this.createSoundPlay(this.state.ioProps["sound_door_closed"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_path_door_closed"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
							{this.hasFile('sound_arriving')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'arriving'} label="" disabled={true} value={'Arriving'}/></td>
											<td>
												{this.createSoundPlay(this.state.ioProps["sound_arriving"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_path_arriving"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
							{this.hasFile('sound_overload')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'overload'} label="" disabled={true} value={'Overload'}/></td>
											<td>
												{this.createSoundPlay(this.state.ioProps["sound_overload"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_path_overload"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
							{this.hasFile('sound_fire_alarm')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'fire_alarm'} label="" disabled={true} value={'Fire Alarm'}/></td>
											<td>
												{this.createSoundPlay(this.state.ioProps["sound_fire_alarm"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_path_fire_alarm"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
							{this.hasFile('sound_up')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'sound_up'} label="" disabled={true} value={'Going Up'}/></td>
											<td>
												{this.createSoundPlay(this.state.ioProps["sound_up"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_up"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
							{this.hasFile('sound_down')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'sound_down'} label="" disabled={true} value={'Going Down'}/></td>
											<td>
												{this.createSoundPlay(this.state.ioProps["sound_down"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_down"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
							{this.hasFile('sound_maintenance')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'sound_maintenance'} label="" disabled={true} value={'Maintenance'}/></td>
											<td>
												{this.createSoundPlay(this.state.ioProps["sound_maintenance"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_maintenance"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
							{this.hasFile('sound_emergency_power')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'sound_emergency_power'} label="" disabled={true} value={'Emergency Power'}/></td>
											<td>
												{this.createSoundPlay(this.state.ioProps["sound_emergency_power"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_emergency_power"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
							{this.hasFile('sound_service_upper_deck')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'sound_service_upper_deck'} label="" disabled={true} value={'Service Upper Deck'}/></td>
											<td>
												{this.createSoundPlay(this.state.ioProps["sound_service_upper_deck"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_service_upper_deck"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
							{this.hasFile('sound_service_lower_deck')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'sound_service_lower_deck'} label="" disabled={true} value={'Service Lower Deck'}/></td>
											<td>
												{this.createSoundPlay(this.state.ioProps["sound_service_lower_deck"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_service_lower_deck"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
							{this.hasFile('sound_special_io_1')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'sound_special_io_1'} label="" disabled={true} value={'Special IO 1'}/></td>
											<td>
												{this.createSoundPlay(this.state.ioProps["sound_special_io_1"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_special_io_1"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
							{this.hasFile('sound_special_io_2')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'sound_special_io_2'} label="" disabled={true} value={'Special IO 2'}/></td>
											<td>
												{this.createSoundPlay(this.state.ioProps["sound_special_io_2"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_special_io_2"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
							{this.hasFile('sound_special_io_3')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'sound_special_io_3'} label="" disabled={true} value={'Special IO 3'}/></td>
											<td>
												{this.createSoundPlay(this.state.ioProps["sound_special_io_3"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_special_io_3"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
							{this.hasFile('sound_special_io_4')?
								<table>
									<tbody>
										<tr>
											<td><TextField size='small' key={'sound_special_io_4'} label="" disabled={true} value={'Special IO 4'}/></td>
											<td>
												{this.createSoundPlay(this.state.ioProps["sound_special_io_4"])}
											</td>
											<td>
												<Button size='small' color="secondary" variant='contained' onClick={() => this.deleteFile(this.state.ioProps["sound_special_io_4"])}>delete</Button>
											</td>
										</tr>
									</tbody>
								</table>
								:
								<span/>
							}
						</span>
						<Divider />

						{this.state.menuItems?this.state.menuItems.map( (item, i) => { return this.createFloorItem(item.key)}):<span/>}

						<Dialog
			        open={this.state.window==='message_div'}
							maxWidth='lg'
							fullWidth={false}
			        onClose={() => this.closeInfoMessage()}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description">
			        <DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
			        <DialogContent>
			           <Typography variant='body1'>{this.state.info_message}</Typography>
			        </DialogContent>
			        <DialogActions>
			          <Button onClick={() => this.closeInfoMessage()} color="primary">
			            Close
			          </Button>
			        </DialogActions>
			      </Dialog>
		   	</div>
			);
	}
}

export default FloorSounds
