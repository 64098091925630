import React from 'react';
import {Button, Divider,TextField,InputLabel,MenuItem,FormControl,Select,IconButton} from '@material-ui/core';
import BasicTable2 from "../tools/BasicTable2";
import LockIcon from '@material-ui/icons/Lock';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import SelectLayout from './SelectLayout';
import SelectBackground from './SelectBackground';
import LocationMap from './LocationMap';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import SignalWifi2BarLockIcon from '@material-ui/icons/SignalWifi2BarLock';
import InfoIcon from '@material-ui/icons/Info';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import ConfirmDialog from '../tools/ConfirmDialog';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,KeyboardDatePicker,} from '@material-ui/pickers';
import * as ETVConstants from '../ETVConstants';
import DefaultColorFontsChoices from '../tools/DefaultColorFontsChoices';

class DeviceMgmt extends React.Component {

	constructor(props) {
      super(props);
      this.closeLayoutSelection = this.closeLayoutSelection.bind(this);
			this.closeBGSelection = this.closeBGSelection.bind(this);
			this.setLocationGPS = this.setLocationGPS.bind(this);
			this.getPWSetReplyStatus = this.getPWSetReplyStatus.bind(this);
			this.getWIFI_GET_Status = this.getWIFI_GET_Status.bind(this);
			this.getWifiSetReplyStatus = this.getWifiSetReplyStatus.bind(this);
			this.getLaunchAppReplyStatus = this.getLaunchAppReplyStatus.bind(this);
			this.getDeviceReplyStatus = this.getDeviceReplyStatus.bind(this);
			this.resultListener = this.resultListener.bind(this);
			this.fontOrColorChanged = this.fontOrColorChanged.bind(this);
    }

    state = {
      items:[],
      isLoaded: false,
      selected_device_id: '',
			selected_group_id: -1,
      current_layout_id: '',
			device_name: '',
      location_name: '',
      address: '',
      zip: '',
      city: '',
      country:'',
      contact_phone: '',
      contact_email: '',
      lat: '',
      lgt: '',
			hasChanged: false,
			showDeleteConfirmDialog: false,
			showConfimBTDialog:false,
			deleteDeviceID:'',
			showConfimAppLaunchDialog: false,
			appLaunchDeviceID:'',
			btLaunchDeviceID:'',
			usb_pw:'',
			message_id:'',
			aborted:false,
			msg_count: 0,
			new_owner_id: -1,
			cur_owner_id: -1,
			available_users: [],
			ssid:[],
			selected_ssid:'',
			wifi_pw:'',
			showUpdateWIFIConfirmDialog:false,
			show_progress:false,
			app_launch_name:"settings",
			groups:[],
			reply_info_message:"",
			waiting_for_reply:false
    }

		componentDidUpdate() {
      if(!this.state.isLoaded) {
        this.loadItems();
      }
		}

    componentDidMount() {
      this.loadItems();
    }

		groupsContain(groups, deviceID) {
			for(var i=0; i<groups.length; i++) {
				var deviceIDs = groups[i].device_ids;
				if(deviceIDs.includes(deviceID)) return true;
			}
			return false;
		}

		filterOutScreensInGroups( devices, groups) {
				var res = [];
				for(var i=0; i<devices.length; i++) {
					if(!this.groupsContain(groups, devices[i].id)) {
						res.push(devices[i]);
					}
				}
				return res;
		}

    loadItems() {
			//console.log("load items");
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=list&owner_id="+this.props.user_id;
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						var groups = ETVConstants.filterOutEmptyGroups(result.groups);
						//var sorted = this.filterOutScreensInGroups(result.list,groups);
						var sorted = result.list;
						sorted.sort(ETVConstants.compareDevice);
						//console.log(JSON.stringify(sorted));
            this.setState({
              items: sorted,
              isLoaded: true,
							groups: groups,
            });
						this.loadAvailableUsers(this.props.user_id);
          },
          (error) => {
            this.setState({
              items:[],
              isLoaded: false,
              error
            });
          }
        )
    }

		compare( a, b ) {
			var at = new Date(a.last_connected);
			var bt = new Date(b.last_connected);
			var now = new Date();
			var diffMinsA = (now - at)/60000;
			var diffMinsB = (now - bt)/60000;
			var lcA = a.device_name.toLowerCase();
			var lcB = b.device_name.toLowerCase();
			if(diffMinsA<15 && diffMinsB<15) {
					return lcA<lcB?-1:1;
			}else if(diffMinsA<120 && diffMinsB<120) {
					return lcA<lcB?-1:1;
			}else if(diffMinsA<120 && diffMinsB<120) {
					return lcA<lcB?-1:1;
			}else{
					return diffMinsA<diffMinsB?-1:1;
			}
		}

    showWindow(divID, show) {
      show?this.setState({window:divID}):this.setState({window:''});
    }

    closeLocationSelection() {
      this.showWindow('select_bg_div',false);
    }

    closeLayoutSelection() {
      this.showWindow('select_layout_div',false);
      this.setState({isLoaded:false,showLayout:false});
    }

		closeBGSelection() {
			this.showWindow('select_background_div',false);
			this.setState({isLoaded:false,showLayout:false});
		}

    getLayoutID( deviceID) {
      for(var i=0; i<this.state.items.length; i++) {
        if(this.state.items[i].id===deviceID) {
          return this.state.items[i].layout_id;
        }
      }
      return undefined;
    }

    getItem( deviceID) {
      for(var i=0; i<this.state.items.length; i++) {
        if(this.state.items[i].id===deviceID) {
          return this.state.items[i];
        }
      }
      return undefined;
    }

    editLayout(deviceID) {
        var curLayoutID = this.getLayoutID(deviceID);
        this.setState({selected_group_id:-1, selected_device_id:deviceID, current_layout_id: curLayoutID,showLayout:true});
        this.showWindow("select_layout_div",true);
    }

		editGroupLayout(groupID) {
				var curLayoutID = '';
				this.setState({selected_group_id:groupID, selected_device_id:'', current_layout_id: curLayoutID, showLayout:true});
				this.showWindow("select_layout_div",true);
		}

		editBackground(deviceID) {
				this.setState({selected_device_id:deviceID,selected_group_id:-1});
				this.showWindow("select_background_div",true);
		}

		editGroupBackground(groupID) {
				this.setState({selected_group_id:groupID, selected_device_id:''});
				this.showWindow("select_background_div",true);
		}

    editLocation(deviceID) {
        var item = this.getItem(deviceID);
        this.setState({
          selected_device_id:deviceID,
					device_name: item.device_name,
					location_name: item.location_name,
					address: item.address,
					zip: item.zip,
					city: item.city,
					country: item.country,
					contact_phone: item.contact_phone,
					contact_email: item.contact_email,
					lat: item.lat,
					lgt: item.lgt,
        });
        this.showWindow("edit_details_div",true);
    }

		editGroupLocation(groupID) {
        //var item = this.getItem(deviceID);
        this.setState({
          selected_group_id:groupID,
					location_name: '',
					address: '',
					zip: '',
					city: '',
					country: '',
					contact_phone: '',
					contact_email: '',
					lat: '',
					lgt: '',
        });
        this.showWindow("edit_group_location_div",true);
    }

    saveLocationDetails() {
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=save_location&device_id="+this.state.selected_device_id;
  		url += "&device_name="+encodeURIComponent(this.state.device_name);
			url += "&location_name="+encodeURIComponent(this.state.location_name?this.state.location_name:"");
      url += "&address="+encodeURIComponent(this.state.address?this.state.address:"");
      url += "&zip="+encodeURIComponent(this.state.zip?this.state.zip:"");
      url += "&city="+encodeURIComponent(this.state.city?this.state.city:"");
      url += "&country="+encodeURIComponent(this.state.country?this.state.country:"");
      url += "&contact_phone="+encodeURIComponent(this.state.contact_phone?this.state.contact_phone:"");
      url += "&contact_email="+encodeURIComponent(this.state.contact_email?this.state.contact_email:"");
      url += "&lat="+this.state.lat;
      url += "&lgt="+this.state.lgt;
			ETVConstants.sendCMDToServerListener(url,this.resultListener);
			this.showWindow('edit_details_div',false);
    }

		resultListener(val) {
			//console.log("result="+JSON.stringify(val));
			this.setState({isLoaded:false});
		}


		saveGroupLocationDetails() {
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=save_location&group_id="+this.state.selected_group_id;
			url += "&location_name="+encodeURIComponent(this.state.location_name?this.state.location_name:"");
      url += "&address="+encodeURIComponent(this.state.address?this.state.address:"");
      url += "&zip="+encodeURIComponent(this.state.zip?this.state.zip:"");
      url += "&city="+encodeURIComponent(this.state.city?this.state.city:"");
      url += "&country="+encodeURIComponent(this.state.country?this.state.country:"");
      url += "&contact_phone="+encodeURIComponent(this.state.contact_phone?this.state.contact_phone:"");
      url += "&contact_email="+encodeURIComponent(this.state.contact_email?this.state.contact_email:"");
      url += "&lat="+this.state.lat;
      url += "&lgt="+this.state.lgt;
			ETVConstants.sendCMDToServerListener(url,this.resultListener);
			this.showWindow('edit_group_location_div',false);
    }


		setLocationGPS( lat, lgt) {
			this.setState({lat:lat,lgt:lgt, hasChanged: true});
			if(this.state.selected_group_id<0) {
				this.showWindow("edit_details_div",true);
			}else{
				this.showWindow("edit_group_location_div",true);
			}
		}

		deleteDevice( deviceID) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=delete_device&device_id="+deviceID;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then(res => res.json())
				.then(
					(result) => {
						this.setState({
							isLoaded: false,
							showDeleteConfirmDialog: false,
							deleteDeviceID: '',
						});
					},
					(error) => {
					}
				)
		}

		showDeleteConfirmDialog( deviceID) {
				this.setState({showDeleteConfirmDialog:true, deleteDeviceID: deviceID});
		}

		showLaunchAppConfirm( deviceID, appName, swVersion) {
				if(swVersion<56) {
					this.showInfoMessage(ETVConstants.trans("update_software"),ETVConstants.trans("this_function_requires_software_update"));
					return;
				}
				this.setState({showConfimAppLaunchDialog:true, appLaunchDeviceID: deviceID, app_launch_name:appName});
		}

		showLaunchBTConfirm( deviceID, appName, swVersion) {
				if(swVersion<56) {
					this.showInfoMessage(ETVConstants.trans("update_software"),ETVConstants.trans("this_function_requires_software_update"));
					return;
				}
				this.setState({showConfimBTDialog:true, btLaunchDeviceID: deviceID});
		}

		showUpdateWIFIConfirmDialog() {
			 this.setState({showUpdateWIFIConfirmDialog:true});
		}

		setUSBStickPW( deviceID, swVersion) {
			if(swVersion<48) {
				this.showInfoMessage(ETVConstants.trans("update_software"),ETVConstants.trans("this_function_requires_software_update"));
				return;
			}
			this.showWindow('set_usb_pw',true)
			this.setState({selected_device_id: deviceID});
		}

		setWifi( deviceID, swVersion) {
			if(swVersion<50) {
				this.showInfoMessage(ETVConstants.trans("update_software"),ETVConstants.trans("this_function_requires_software_update"));
				return;
			}
			this.setState({window:'set_wifi',selected_device_id: deviceID});
			this.loadWifis(deviceID);
		}

		isValidUSBPW() {
			return (this.state.usb_pw && this.state.usb_pw.length>0);
		}

////////////////////////////////////////////////////////////////////////////////
///// USB Password
////////////////////////////////////////////////////////////////////////////////

		getPWSetReplyStatus() {
			//console.log("................reply status... message count="+this.state.aborted);
			var msgCnt = this.state.msg_count;
			if(msgCnt>10 || this.state.aborted) {
				//console.log("clearTimeout");
				//clearTimeout();
				if (msgCnt>10) this.showInfoMessage(ETVConstants.trans("usb_password"),ETVConstants.trans("could_not_reach_device"));
				return;
			}
			this.showInfoMessageProgress(ETVConstants.trans("info"),ETVConstants.trans("waiting_for_reply"),true);
			this.setState({msg_count:msgCnt+1});
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			fetch(ETVConstants.getServerBase()+'/firebase?action=reply_message_status&message_id='+this.state.message_id,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					//console.log("reply message status="+JSON.stringify(result));
					if(result.hasOwnProperty("reply_message")) {
						var replyMsg = result["reply_message"];
						//console.log("replyMsg="+JSON.stringify(replyMsg));
						var pw_is_set = replyMsg.success;
						if(pw_is_set) {
							this.showInfoMessage(ETVConstants.trans("usb_password"),ETVConstants.trans("usb_stick_password_successfully_set"));
						}else{
							this.showInfoMessage(ETVConstants.trans("error"),replyMsg.reason);
						}
					}else{
						//console.log("////////////////// error //////////////////, not restarting");
						this.setState({msg_count:msgCnt+1});
						setTimeout(this.getPWSetReplyStatus, 1000);
					}
				},
				(error) => {
				}
			)
		}

		submitUSBPw() {
			this.setState({msg_count:0,aborted:false});
			var deviceID = this.state.selected_device_id;
			var pw = this.state.usb_pw;
			this.showInfoMessage(ETVConstants.trans("usb_password"),ETVConstants.trans("sending_usb_stick_password"));
			const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({action: 'send_message', device_id:deviceID, fb_action:'usb_pw', fb_param_0:pw })
			};
			fetch(ETVConstants.getServerBase()+'/firebase', requestOptions)
			.then(res => res.json())
			.then(
				(result) => {
						//console.log('------------->'+JSON.stringify(result));
						if(result['result']) {
							//this.showInfoMessage('Device','....submit password to device, messageID='+result.data.message_id);
							this.setState({message_id:result.data.message_id});
							//console.log("sent message reply="+JSON.stringify(result));
							if(!this.state.aborted) setTimeout(this.getPWSetReplyStatus, 1000);
						}else{
							this.showInfoMessage(ETVConstants.trans("error"),result.reason);
						}
				},
				(error) => {
					this.showInfoMessage(ETVConstants.trans("error"),error);
				}
			)
		}


////////////////////////////////////////////////////////////////////////////////
///// WIFI
////////////////////////////////////////////////////////////////////////////////

		submitWifiPw() {
			this.setState({msg_count:0,aborted:false,showUpdateWIFIConfirmDialog:false});
			var deviceID = this.state.selected_device_id;
			var pw = this.state.wifi_pw;
			var ssid = this.state.selected_ssid;

			this.showInfoMessage(ETVConstants.trans("setting_wifi"),ETVConstants.trans("setting_wifi_password"));
			const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({action: 'send_message', device_id:deviceID, fb_action:'wifi_setter', fb_param_0:ssid, fb_param_1:pw })
			};
			fetch(ETVConstants.getServerBase()+'/firebase', requestOptions)
			.then(res => res.json())
			.then(
				(result) => {
						//console.log('------------->'+JSON.stringify(result));
						if(result['result']) {
							//this.showInfoMessage('Setting WIFI','....submit password to device, messageID='+result.data.message_id);
							this.setState({message_id:result.data.message_id});
							//console.log("sent message reply="+JSON.stringify(result));
							if(!this.state.aborted) setTimeout(this.getWifiSetReplyStatus, 1000);
						}else{
							this.showInfoMessage(ETVConstants.trans("error"),result.reason);
						}
				},
				(error) => {
					//console.log('xxx error happend.............'+error);
					this.showInfoMessage(ETVConstants.trans("error"),error);
				}
			)
		}

		getWifiSetReplyStatus() {
			//console.log("................reply status... message count="+this.state.aborted);
			var msgCnt = this.state.msg_count;
			if(msgCnt>10 || this.state.aborted) {
				if (msgCnt>10) this.showInfoMessage(ETVConstants.trans("setting_wifi"),ETVConstants.trans("could_not_reach_device"));
				return;
			}
			this.showInfoMessageProgress(ETVConstants.trans("setting_wifi"),ETVConstants.trans("waiting_for_reply"),true);
			this.setState({msg_count:msgCnt+1});
			var url = ETVConstants.getServerBase()+'/firebase?action=reply_message_status&message_id='+this.state.message_id;
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					if(result.hasOwnProperty("reply_message")) {
						var replyMsg = result["reply_message"];
						var wifi_is_set = replyMsg.success;
						if(wifi_is_set) {
							this.showInfoMessage(ETVConstants.trans("setting_wifi"),ETVConstants.trans("wifi_successfully_set"));
						}else{
							this.showInfoMessage(ETVConstants.trans("setting_wifi"),ETVConstants.trans("error")+": "+replyMsg.reason);
						}
					}else{
						this.setState({msg_count:msgCnt+1});
						setTimeout(this.getWifiSetReplyStatus, 1000);
					}
				},
				(error) => {
					this.showInfoMessage(ETVConstants.trans("setting_wifi"),ETVConstants.trans("error")+": "+error);
				}
			)
		}

		showInfoMessage(title,msg) {
			this.showInfoMessageProgress(title,msg,false);
		}

		showInfoMessageProgress(title, msg, showProgress) {
			this.showWindow('message_div',true);
			this.setState({info_title:title, info_message:msg, show_progress:showProgress});
		}

		closeInfoMessage() {
			this.setState({window:'',info_title:'', info_message:'', show_progress:false});
		}

		changeOwner( deviceID, curOwner) {
			this.showWindow('change_owner_div',true)
			this.setState({selected_device_id: deviceID, new_owner_id:curOwner, cur_owner_id:curOwner});
		}

		showDeviceVolume( deviceID) {
			this.showWindow('set_device_volume_div',true)
			this.setState({selected_device_id: deviceID});
		}

		loadAvailableUsers( userID) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/useradmin?action=list&user_id="+userID;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then(res => res.json())
				.then(
					(result) => {
						//console.log("----->available users="+JSON.stringify(result));
						this.setState({
							available_users: result.data,
						});
					},
					(error) => {
					}
				)
		}

		getAvailableUserList() {
			if(!this.state.available_users || this.state.available_users.length===0) {
				return (<span/>)
			}

			return (<span>{
				<FormControl>
					 <InputLabel>{ETVConstants.trans("change_owner")}</InputLabel>
					 <Select
						 labelId={"change_owner"}
						 id={"change_owner"}
						 value={this.state.new_owner_id}
						 style={{ width: 200}}
						 onChange={(e)=>{this.setState({new_owner_id:e.target.value})}}>
						 {
							 this.state.available_users.map(
									 ({user_name,user_id}) => { return <MenuItem key={user_id} value={user_id}>{user_name}</MenuItem> }
							 )
						 }
					 </Select>
				 </FormControl>
			}</span>)
		}

		getAvailableSSIDS() {
			return (<span>{
				<FormControl>
					 <InputLabel>SSID</InputLabel>
					 <Select
						 labelId={"ssids"}
						 id={"ssid_list"}
						 value={this.state.selected_ssid}
						 style={{ width: 200}}
						 onChange={(e)=>{this.setState({selected_ssid:e.target.value})}}>
						 {
							 this.state.ssid.length===0?<Typography component={'span'} variant='body1'><CircularProgress/></Typography>:
							 this.state.ssid.map(
									 (ssid) => { return <MenuItem key={Math.random()+"_"+ssid} value={ssid}>{ssid}</MenuItem> }
							 )
						 }
					 </Select>
				 </FormControl>

			}</span>)
		}


		saveNewOwner() {
			var deviceID = this.state.selected_device_id;
			var newOwnerID = this.state.new_owner_id;
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=change_owner&device_id="+deviceID+"&new_owner_id="+newOwnerID;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then(res => res.json())
				.then(
					(result) => {
						//console.log("-->"+JSON.stringify(result))
						this.setState({isLoaded:false});
						if(result["result"]) {
							this.showInfoMessage(ETVConstants.trans("success"),ETVConstants.trans("the_device_is_linked_to_new_owner"));
						}else{
							this.showInfoMessage(ETVConstants.trans("error"),result["reason"]);
						}
					},
					(error) => {
						this.showInfoMessage(ETVConstants.trans("error"),error);
					}
				)
		}

		loadWifis(deviceID) {
			this.setState({msg_count:0,aborted:false,wifi_pw:''});
			const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({action: 'send_message', device_id:deviceID, fb_action:'wifi_getter'})
			};
			fetch(ETVConstants.getServerBase()+'/firebase', requestOptions)
			.then(res => res.json())
			.then(
				(result) => {
						//console.log('------------->'+JSON.stringify(result));
						if(result['result']) {
							//console.log(JSON.stringify(result));
							this.setState({message_id:result.data.message_id});
							//console.log("sent message reply="+JSON.stringify(result));
							if(!this.state.aborted) setTimeout(this.getWIFI_GET_Status, 1000);
						}else{
							this.showInfoMessage(ETVConstants.trans("error"),'failed: '+result.reason);
						}
				},
				(error) => {
				}
			)
		}

		getWIFI_GET_Status() {
			//console.log("wifi status...")
			//console.log("................reply status... message count="+this.state.aborted);
			var msgCnt = this.state.msg_count;
			if(msgCnt>10 || this.state.aborted) {
				//console.log("clearTimeout");
				//clearTimeout();
				if (msgCnt>10) this.showInfoMessage(ETVConstants.trans("setting_wifi"),ETVConstants.trans("could_not_reach_device"));
				return;
			}
			this.setState({msg_count:msgCnt+1});
			var url = ETVConstants.getServerBase()+'/firebase?action=reply_message_status&message_id='+this.state.message_id;
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					if(result.hasOwnProperty("reply_message")) {
						var replyMsg = result["reply_message"];
						//console.log("replyMsg="+JSON.stringify(replyMsg));
						var ssidList = replyMsg['ssid'];
						this.setState({ssid:ssidList});
					}else{
						this.setState({msg_count:msgCnt+1});
						setTimeout(this.getWIFI_GET_Status, 1000);
					}
				},
				(error) => {
					//console.log("error msg="+error.message);
				}
			)
		}

		closeWifiDialog() {
			this.setState({window:'',selected_ssid:'',ssid:[],wifi_pw:'',msgCnt:0,aborted:true});
		}

		getOSVersion( deviceID) {
				var device = this.getItem(deviceID);
				return device.os_version;
		}

		getDeviceName( deviceID) {
				//console.log("------ deviceID="+deviceID);
				var device = this.getItem(deviceID);
				return device.device_name;
		}

		setWarrantyDate = (date) => {
			var dateStr = this.formatDate(date);
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=set_warranty_date&device_id="+this.state.selected_device_id+"&warranty_expiry_date="+dateStr;
			ETVConstants.sendCMDToServerListener(url,this.resultListener);
  	};

		formatDate( date2) {
			if(date2) {
					var formatted_date = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();
					return formatted_date;
			}
		}

		showWarrantyExpiryDate(deviceID) {
			if(!deviceID) return;
			var device = this.getItem(deviceID);
			var d = device.warranty_expiry_date;
			var dateObj = new Date(d);
			return(
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
							margin="normal"
							id="date-picker-dialog_warranty_from"
							format='yyyy-MM-dd'
							value={dateObj?dateObj:null}
							onChange={this.setWarrantyDate}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}/>
				</MuiPickersUtilsProvider>)
		}

		showMaintenanceContractSwitch( deviceID) {
			if(!deviceID) return;
			var device = this.getItem(deviceID);
			var switchActive = device.has_maintenance_contract;
			var toolTip = switchActive?ETVConstants.trans("click_to_set_no_contract"):ETVConstants.trans("click_to_set_has_contract");
			return <FormControlLabel control={<Tooltip title={toolTip}><Switch color='primary' checked={switchActive} onClick={()=>{this.changeMaintenanceContract(deviceID,!switchActive)}} /></Tooltip>} />
		}

		changeMaintenanceContract( deviceID, hasContract) {
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=set_has_maintenance_contract&device_id="+deviceID+"&has_maintenance_contract="+hasContract;
			ETVConstants.sendCMDToServerListener(url,this.resultListener);
		}

		getMaintenanceContract( deviceID) {
				var device = this.getItem(deviceID);
				if(device.has_maintenance_contract) {
					return <span style={{color:'green'}}>{ETVConstants.trans("maintenance_and_security_contract_in_place")}</span>;
				}else{
					return <span style={{color:'red'}}>{ETVConstants.trans("no_contract_in_place")}</span>;
				};
		}

		getWarrantyExpiryDate( deviceID) {
				var device = this.getItem(deviceID);
				if(!device.warranty_expiry_date || device.warranty_expiry_date==="") {
					return <span style={{color:'red'}}>{ETVConstants.trans("expired")}</span>;
				}
				var warrantyDate = device.warranty_expiry_date;
				var d = new Date(warrantyDate);
				if(new Date()>d) {
					return <span style={{color:'red'}}>{ETVConstants.trans("expired")}</span>;
				}
				return device.warranty_expiry_date;
		}

		getSWVersion( deviceID) {
				var device = this.getItem(deviceID);
				return device.sw_version;
		}

		getScreenRatio( deviceID) {
				var device = this.getItem(deviceID);
				return device.screen_ratio;
		}

		getIP_Internal( deviceID) {
				var device = this.getItem(deviceID);
				return device.ip_internal;
		}

		get_MACWLAN0( deviceID) {
				var device = this.getItem(deviceID);
				return device.mac_wlan0;
		}

		get_MACETH0( deviceID) {
				var device = this.getItem(deviceID);
				return device.mac_eth0;
		}

		launchApp(deviceID) {
			this.setState({msg_count:0,aborted:false,showConfimAppLaunchDialog:false});
			var appName = this.state.app_launch_name;
			const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({action: 'send_message',device_id:deviceID, fb_action:'launch_app',fb_param_0:appName })
			};
			fetch(ETVConstants.getServerBase()+'/firebase', requestOptions)
			.then(res => res.json())
			.then(
				(result) => {
					//console.log(JSON.stringify(result));
					if(result['result']) {
						this.showInfoMessageProgress(ETVConstants.trans("launch_app"),ETVConstants.trans("sending_command"),true);
						this.setState({message_id:result.data.message_id, msg_count:0});
						setTimeout(this.getLaunchAppReplyStatus, 1000);
					}else{
						this.showInfoMessage(ETVConstants.trans("error"),result.reason);
					}
				},
				(error) => {
					this.showInfoMessage(ETVConstants.trans("error"),'Error: '+error.message);
				}
			)
		}

		getLaunchAppReplyStatus() {
			var msgCnt = this.state.msg_count;
			if(msgCnt>10 || this.state.aborted) {
				if (msgCnt>10) this.showInfoMessage(ETVConstants.trans("error"),ETVConstants.trans("could_not_reach_device"));
				return;
			}
			this.showInfoMessageProgress(ETVConstants.trans("launch_app"),ETVConstants.trans("waiting_for_reply"),true);
			this.setState({msg_count:msgCnt+1});
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+'/firebase?action=reply_message_status&message_id='+this.state.message_id;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					if(result.hasOwnProperty("reply_message")) {
						var replyMsg = result["reply_message"];
						//console.log("xxx--->"+JSON.stringify(replyMsg));
						var isOK = replyMsg.success;
						if(isOK) {
							this.showInfoMessage(ETVConstants.trans("started"),ETVConstants.trans("window_has_opened_on_screen"));
							return;
						}else{
							//console.log(JSON.stringify(result));
							this.showInfoMessage(ETVConstants.trans("error"),result.reply_message.reason);
							return;
						}
					}else{
						this.setState({msg_count:msgCnt+1});
						setTimeout(this.getLaunchAppReplyStatus, 1000);
					}
				},
				(error) => {
				}
			)
		}

		setVolume(deviceID,vol) {
			this.setState({msg_count:0,aborted:false,reply_info_message:"...",waiting_for_reply:true});
			//console.log(this.state.msg_count);
			const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({action: 'send_message',device_id:deviceID, fb_action:'set_device_volume',fb_param_0:""+vol })
			};
			fetch(ETVConstants.getServerBase()+'/firebase', requestOptions)
			.then(res => res.json())
			.then(
				(result) => {
					//console.log(JSON.stringify(result));
					if(result['result']) {
						//request has been submitted
						this.setState({message_id:result.data.message_id, msg_count:0});
						setTimeout(this.getDeviceReplyStatus, 1000);
					}else{
						this.setState({waiting_for_reply:false,msg_count:0,reply_info_message:result.reason});
						//this.showInfoMessage(ETVConstants.trans("error"),result.reason);
					}
				},
				(error) => {
					this.setState({waiting_for_reply:false,msg_count:0,reply_info_message:error.message});
				}
			)
		}

		setEnableBT(deviceID) {
			this.showWindow("show_device_reply_status_div",true);
			//console.log("enableBT...deviceID="+deviceID);
			this.setState({msg_count:0,aborted:false,reply_info_message:"...",waiting_for_reply:true,showConfimBTDialog:false});
			//console.log(this.state.msg_count);
			const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({action: 'send_message',device_id:deviceID, fb_action:'make_bt_discoverable'})
			};
			fetch(ETVConstants.getServerBase()+'/firebase', requestOptions)
			.then(res => res.json())
			.then(
				(result) => {
					console.log(JSON.stringify(result));
					if(result['result']) {
						//request has been submitted
						this.setState({message_id:result.data.message_id, msg_count:0});
						setTimeout(this.getDeviceReplyStatus, 1000);
					}else{
						this.setState({waiting_for_reply:false,msg_count:0,reply_info_message:result.reason});
						//this.showInfoMessage(ETVConstants.trans("error"),result.reason);
					}
				},
				(error) => {
					this.setState({waiting_for_reply:false,msg_count:0,reply_info_message:error.message});
				}
			)
		}

		getDeviceReplyStatus() {
			//console.log("deviceReplyStatus.."+this.state.msg_count);
			var msgCnt = this.state.msg_count;
			if(msgCnt>10 || this.state.aborted) {
				if (msgCnt>10) this.setState({reply_info_message:ETVConstants.trans("could_not_reach_device"),msg_count:0, waiting_for_reply:false});
				return;
			}
			this.setState({reply_info_message:".... ("+this.state.msg_count+")",msg_count:msgCnt+1});
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+'/firebase?action=reply_message_status&message_id='+this.state.message_id;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					//console.log(JSON.stringify(result));
					if(result.hasOwnProperty("reply_message")) {
						var replyMsg = result["reply_message"];
						//console.log("xxx--->"+JSON.stringify(replyMsg));
						var isOK = replyMsg.success;
						if(isOK) {
							this.setState({reply_info_message:ETVConstants.trans("success"),msg_count:0,waiting_for_reply:false});
							return;
						}else{
							this.setState({reply_info_message:result.reply_message.reason,msg_count:0,waiting_for_reply:false});
							//this.showInfoMessage(ETVConstants.trans("error"),result.reply_message.reason);
							return;
						}
					}else{
						this.setState({msg_count:msgCnt+1});
						setTimeout(this.getDeviceReplyStatus, 1000);
					}
				},
				(error) => {
					this.setState({reply_info_message:ETVConstants.trans("failure"),msg_count:0,waiting_for_reply:false});
				}
			)
		}

		fontOrColorChanged() {
			this.setState({hasChanged:true});
		}

    render() {
			const tableTitleScreenGroups = <Typography variant="h6">{ETVConstants.trans("groups")}</Typography>;
			var columnsDataScreenGroups= [
					{ title: ETVConstants.trans('group_name'), field: 'group_name', render: rowData => <Typography style={{fontWeight:600}} variant='body2'>{rowData.group_name}</Typography> },
					{ title: ETVConstants.trans('details'), field: 'select_location', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editGroupLocation(rowData.group_id)}><InfoIcon/></IconButton>},
          { title: ETVConstants.trans('select_layout'), field: 'select_layout', render: rowData => <div style={{width:50,padding:0,left:0}}><IconButton variant='contained' color='primary' onClick={()=>this.editGroupLayout(rowData.group_id)}><ViewCompactIcon/></IconButton></div>},
					{ title: ETVConstants.trans('change_background'), field: 'select_layout', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editGroupBackground(rowData.group_id)}><ViewCompactIcon/></IconButton>},
        ];

			var isSuperAdmin = this.props.status === "superadmin";
			var isOEM = this.props.status === "oem";
			var columnsData = [];
			if(ETVConstants.ON_PREMISE_VERSION) {
		      columnsData= [
							{ title: ETVConstants.trans('last_connect'), field: 'last_connected', render: rowData => (<div style={{width: "15px", maxWidth:"30px", paddingLeft: "3px"}}> {ETVConstants.getLastConnectSymbol(rowData.last_connected,rowData.startup_datetime,rowData.status)} </div>) },
							{ title: ETVConstants.trans('device_name'), field: 'device_name', render: rowData => (<div style={{minWidth: "200px", paddingLeft: "0px"}}> {rowData.device_name} </div>) },
		          { title: ETVConstants.trans('details'), field: 'select_location', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editLocation(rowData.id)}><InfoIcon/></IconButton>},
		          { title: ETVConstants.trans('select_layout'), field: 'select_layout', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editLayout(rowData.id)}><ViewCompactIcon/></IconButton>},
							{ title: ETVConstants.trans('change_background'), field: 'select_bg', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editBackground(rowData.id)}><ViewCompactIcon/></IconButton>},
							{ title: ETVConstants.trans('device_volume'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.showDeviceVolume(rowData.id)}><VolumeUpIcon/></IconButton>},
							{ title: ETVConstants.trans('delete'), field: '', render: rowData => <IconButton variant='contained' color='secondary' onClick={()=>this.showDeleteConfirmDialog(rowData.id)}><DeleteIcon/></IconButton>}
		        ];

					if(isSuperAdmin) {
						columnsData= [
								{ title: ETVConstants.trans('last_connect'), field: 'last_connected', render: rowData => (<div style={{width: "15px", maxWidth:"30px", paddingLeft: "3px"}}> {ETVConstants.getLastConnectSymbol(rowData.last_connected,rowData.startup_datetime,rowData.status)} </div>) },
								{ title: ETVConstants.trans('device_name'), field: 'device_name', render: rowData => (<div style={{minWidth: "200px", paddingLeft: "0px"}}> {rowData.device_name} </div>) },
								{ title: ETVConstants.trans('change_owner'), field: '', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.changeOwner(rowData.id, rowData.owner_id)}><PersonIcon/></IconButton>},
			          { title: ETVConstants.trans('details'), field: 'select_location', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editLocation(rowData.id)}><InfoIcon/></IconButton>},
			          { title: ETVConstants.trans('select_layout'), field: 'select_layout', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editLayout(rowData.id)}><ViewCompactIcon/></IconButton>},
								{ title: ETVConstants.trans('change_background'), field: 'select_layout', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editBackground(rowData.id)}><ViewCompactIcon/></IconButton>},
								{ title: ETVConstants.trans('device_volume'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.showDeviceVolume(rowData.id)}><VolumeUpIcon/></IconButton>},
								{ title: ETVConstants.trans('settings'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.showLaunchAppConfirm(rowData.id,'settings',rowData.sw_version)}><SettingsIcon/></IconButton>},
								{ title: ETVConstants.trans('bluetooth'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.showLaunchBTConfirm(rowData.id,'bluetooth',rowData.sw_version)}><BluetoothIcon/></IconButton>},
								{ title: ETVConstants.trans('delete'), field: '', render: rowData => <IconButton variant='contained' color='secondary' onClick={()=>this.showDeleteConfirmDialog(rowData.id)}><DeleteIcon/></IconButton>}
			        ];
					}
			}else{
					columnsData= [
							{ title: ETVConstants.trans('last_connect'), field: 'last_connected', render: rowData => (<div style={{width: "15px", maxWidth:"30px", paddingLeft: "3px"}}> {ETVConstants.getLastConnectSymbol(rowData.last_connected,rowData.startup_datetime,rowData.status)} </div>) },
							{ title: ETVConstants.trans('device_name'), field: 'device_name', render: rowData => (<div style={{minWidth: "200px", paddingLeft: "0px"}}> {rowData.device_name} </div>) },
							{ title: ETVConstants.trans('details'), field: 'select_location', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editLocation(rowData.id)}><InfoIcon/></IconButton>},
							{ title: ETVConstants.trans('select_layout'), field: 'select_layout', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editLayout(rowData.id)}><ViewCompactIcon/></IconButton>},
							{ title: ETVConstants.trans('change_background'), field: 'select_bg', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editBackground(rowData.id)}><ViewCompactIcon/></IconButton>},
							{ title: ETVConstants.trans('wifi'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.setWifi(rowData.id,rowData.sw_version)}><SignalWifi2BarLockIcon/></IconButton>},
							{ title: ETVConstants.trans('usb_password'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.setUSBStickPW(rowData.id,rowData.sw_version)}><LockIcon/></IconButton>},
							{ title: ETVConstants.trans('device_volume'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.showDeviceVolume(rowData.id)}><VolumeUpIcon/></IconButton>},
							{ title: ETVConstants.trans('settings'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.showLaunchAppConfirm(rowData.id,'settings',rowData.sw_version)}><SettingsIcon/></IconButton>},
							{ title: ETVConstants.trans('bluetooth'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.showLaunchBTConfirm(rowData.id,'bluetooth',rowData.sw_version)}><BluetoothIcon/></IconButton>},
							{ title: ETVConstants.trans('delete'), field: '', render: rowData => <IconButton variant='contained' color='secondary' onClick={()=>this.showDeleteConfirmDialog(rowData.id)}><DeleteIcon/></IconButton>}
						];

					if(isSuperAdmin) {
						columnsData= [
								{ title: ETVConstants.trans('last_connect'), field: 'last_connected', render: rowData => (<div style={{width: "15px", maxWidth:"30px", paddingLeft: "3px"}}> {ETVConstants.getLastConnectSymbol(rowData.last_connected,rowData.startup_datetime,rowData.status)} </div>) },
								{ title: ETVConstants.trans('device_name'), field: 'device_name', render: rowData => (<div style={{minWidth: "200px", paddingLeft: "0px"}}> {rowData.device_name} </div>) },
								{ title: ETVConstants.trans('change_owner'), field: '', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.changeOwner(rowData.id, rowData.owner_id)}><PersonIcon/></IconButton>},
								{ title: ETVConstants.trans('details'), field: 'select_location', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editLocation(rowData.id)}><InfoIcon/></IconButton>},
								{ title: ETVConstants.trans('select_layout'), field: 'select_layout', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editLayout(rowData.id)}><ViewCompactIcon/></IconButton>},
								{ title: ETVConstants.trans('change_background'), field: 'select_layout', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editBackground(rowData.id)}><ViewCompactIcon/></IconButton>},
								{ title: ETVConstants.trans('usb_password'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.setUSBStickPW(rowData.id,rowData.sw_version)}><SignalWifi2BarLockIcon/></IconButton>},
								{ title: ETVConstants.trans('wifi'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.setWifi(rowData.id,rowData.sw_version)}><LockIcon/></IconButton>},
								{ title: ETVConstants.trans('device_volume'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.showDeviceVolume(rowData.id)}><VolumeUpIcon/></IconButton>},
								{ title: ETVConstants.trans('settings'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.showLaunchAppConfirm(rowData.id,'settings',rowData.sw_version)}><SettingsIcon/></IconButton>},
								{ title: ETVConstants.trans('bluetooth'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.showLaunchBTConfirm(rowData.id,'bluetooth',rowData.sw_version)}><BluetoothIcon/></IconButton>},
								{ title: ETVConstants.trans('power_on_off'), field: '', render: rowData => <IconButton variant='contained' color='primary' disabled={ETVConstants.getLastConnectMins(rowData.last_connected)>=15} onClick={()=>this.showLaunchAppConfirm(rowData.id,'power_on_off',rowData.sw_version)}><PowerSettingsNewIcon/></IconButton>},
								{ title: ETVConstants.trans('delete'), field: '', render: rowData => <IconButton variant='contained' color='secondary' onClick={()=>this.showDeleteConfirmDialog(rowData.id)}><DeleteIcon/></IconButton>}
							];
					}
			}

      const tabletitle = <Typography variant="h6">{ETVConstants.trans("screens")}</Typography>;

      return(
				<div>
						{this.state.groups && this.state.groups.length>0?
							<div style={{marginLeft:5,width:600}}>
								<BasicTable2 data={this.state.groups} columns={columnsDataScreenGroups} title={tableTitleScreenGroups} pageSize={5} allow_search={false} />
							</div>
							:
							<span/>
						}
						<br/>
						<div style={{marginLeft:5}}>
	          	<BasicTable2 data={this.state.items} columns={columnsData} title={tabletitle} pageSize={10} allow_search={true} />
						</div>

						<ConfirmDialog open={this.state.showConfimAppLaunchDialog}
									text={ETVConstants.trans("a_dialog_will_appear_on_the_screen")}
									onClose={()=>{this.setState({showConfimAppLaunchDialog:false, appLaunchDeviceID: '',})}}
									onAgree={()=>{this.launchApp(this.state.appLaunchDeviceID)}}   />

						<ConfirmDialog open={this.state.showConfimBTDialog}
								text={ETVConstants.trans("a_dialog_will_appear_on_the_screen")}
								onClose={()=>{this.setState({showConfimBTDialog:false, btLaunchDeviceID: '',})}}
								onAgree={()=>{this.setEnableBT(this.state.btLaunchDeviceID)}}   />

						<ConfirmDialog open={this.state.showUpdateWIFIConfirmDialog}
									text={ ETVConstants.trans("are_you_sure_to_update_wifi_settings")}
									text_line_2={ETVConstants.trans("wifi_settings_update_risk")}
									onClose={()=>{this.setState({showUpdateWIFIConfirmDialog:false,})}}
									onAgree={()=>{this.submitWifiPw(this.state.deleteDeviceID)}}   />

						<Dialog
			        open={this.state.showDeleteConfirmDialog}
			        onClose={()=>{this.setState({showDeleteConfirmDialog:false, deleteDeviceID: '',})}}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description">
			        <DialogTitle id="alert-dialog-title">{ETVConstants.trans("delete_device")}</DialogTitle>
			        <DialogContent>
			          <DialogContentText id="alert-dialog-description">
			            {ETVConstants.trans("are_you_sure_to_delete_device")}
									{ETVConstants.trans("all_the_configurations_and_files_of_this_device_will_be_removed_from_the_system")}
			          </DialogContentText>
			        </DialogContent>
			        <DialogActions>
			          <Button onClick={()=>{this.setState({showDeleteConfirmDialog:false, deleteDeviceID:'',})}} color="primary">{ETVConstants.trans("cancel")}</Button>
			          <Button onClick={()=>{this.deleteDevice(this.state.deleteDeviceID)}} color="primary" autoFocus>{ETVConstants.trans("agree")}</Button>
			        </DialogActions>
			      </Dialog>

						<Dialog
			        open={this.state.window==='select_layout_div'}
			        onClose={()=>{this.closeLayoutSelection()}}
							maxWidth='xl'
							fullWidth={true}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description">
			        <DialogTitle id="alert-dialog-title">{ETVConstants.trans("select_layout")}</DialogTitle>
			        <DialogContent>
									{ (this.state.selected_device_id || this.state.selected_group_id>0)?
										<SelectLayout user_id={this.props.user_id} group_id={this.state.selected_group_id} device_id={this.state.selected_device_id} current_layout_id={this.state.current_layout_id} closeF={this.closeLayoutSelection} />
									:<span/>
									}
			        </DialogContent>
			        <DialogActions>
			          <Button onClick={()=>{this.closeLayoutSelection()}} color="primary" autoFocus>{ETVConstants.trans("close")}</Button>
			        </DialogActions>
			      </Dialog>

						<Dialog
			        open={this.state.window==='set_usb_pw'}
			        onClose={()=>{this.showWindow('set_usb_pw',false)}}
							maxWidth='xs'
							fullWidth={true}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description">
			        <DialogTitle id="alert-dialog-title">{ETVConstants.trans("usb_password")}</DialogTitle>
			        <DialogContent>
									<TextField
											id="outlined-password-input"
											onChange={ (e)=>{this.setState({usb_pw:e.target.value})}}
											label={ETVConstants.trans("password")}
											type="password"
											autoComplete="current-password"
											variant="outlined"
									/>
									<br/>
									<br/>
									<Divider/>
									<br/>
									<Typography variant="body1">{ETVConstants.trans("usb_pw_setting_instructions")}</Typography>
			        </DialogContent>
			        <DialogActions>
									<Button onClick={()=>{this.showWindow('set_usb_pw',false)}} color="primary" autoFocus>{ETVConstants.trans("close")}</Button>
									<Button onClick={()=>{this.submitUSBPw()} } disabled={!this.isValidUSBPW()} color="secondary" variant='contained' autoFocus>{ETVConstants.trans("save")}</Button>
			        </DialogActions>
			      </Dialog>

						<Dialog
			        open={this.state.window==='set_wifi'}
			        onClose={()=>{}}
							maxWidth='xs'
							fullWidth={false}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description">
			        <DialogTitle id="alert-dialog-title">{ETVConstants.trans("set_wifi")}</DialogTitle>
			        <DialogContent>
										<span>{this.getAvailableSSIDS()}</span>
										<TextField
												id="password_wifi"
												onChange={ (e)=>{this.setState({wifi_pw:e.target.value})}}
												label={ETVConstants.trans("wifi_password")}
												type="password"
												autoComplete="current-password"
												variant="outlined"
												style={{marginTop:20}}
										/>
			        </DialogContent>
			        <DialogActions>
					        <Button onClick={()=>{this.closeWifiDialog()}} color="primary" autoFocus>{ETVConstants.trans("close")}</Button>
									<Button onClick={()=>{this.showUpdateWIFIConfirmDialog()} } disabled={!this.state.selected_ssid || this.state.selected_ssid.trim().length===0 } color="secondary" variant='contained' autoFocus>{ETVConstants.trans("save")}</Button>
			        </DialogActions>
			      </Dialog>

						<Dialog
			        open={this.state.window==='select_background_div'}
			        onClose={()=>{this.closeBGSelection()}}
							maxWidth='xl'
							fullWidth={true}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description">
			        <DialogTitle id="alert-dialog-title">{ETVConstants.trans("background_select")}</DialogTitle>
			        <DialogContent>
									{ (this.state.selected_device_id || this.state.selected_group_id) ?
											<SelectBackground group_id={this.state.selected_group_id} device_id={this.state.selected_device_id} user_id={this.props.user_id} closeF={this.closeBGSelection} />
										:<span/>
									}
			        </DialogContent>
			        <DialogActions>
			          <Button onClick={()=>{this.closeBGSelection()}} color="primary" autoFocus>{ETVConstants.trans("close")}</Button>
			        </DialogActions>
			      </Dialog>

						<Dialog
							open={this.state.window==='location_map_div'}
							onClose={()=>{this.setState({window:'edit_details_div'})}}
							fullWidth={true}
							maxWidth='xl'
							scroll='paper'
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description">
							<DialogTitle id="alert-dialog-title">{ETVConstants.trans("select_location")}</DialogTitle>
							<DialogContent>
									<div style={{height:800,maxHeight:800}}>
										{this.state.window==='location_map_div'?<LocationMap lat={this.state.lat} lgt={this.state.lgt} setLocationGPS={this.setLocationGPS} />:<span/>}
									</div>
							</DialogContent>
							<DialogActions>
									<Button onClick={()=>{this.setState({window:this.state.selected_group_id<0?'edit_details_div':'edit_group_location_div'})}} color="primary" autoFocus>{ETVConstants.trans("close")}</Button>
							</DialogActions>
						</Dialog>

						<Dialog
							open={this.state.window==='message_div'}
							maxWidth='xs'
							fullWidth={true}
							onClose={() => this.setState({window:'',aborted:true, show_progress:false})  }
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description">
							<DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
							<DialogContent>
								 <Typography variant='body1'>{this.state.info_message}</Typography>
								 {this.state.show_progress?<CircularProgress/>:<span/>}
							</DialogContent>
							<DialogActions>
								<Button onClick={() => this.setState({window:'',aborted:true, showProgress:false}) } color="primary">{ETVConstants.trans("close")}</Button>
							</DialogActions>
						</Dialog>

						<Dialog
							open={this.state.window==='change_owner_div'}
							maxWidth='xs'
							fullWidth={true}
							onClose={()=>{}}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description">
							<DialogTitle id="alert-dialog-title">{ETVConstants.trans("change_owner")}</DialogTitle>
							<DialogContent>
								 {this.getAvailableUserList()}
							</DialogContent>
							<DialogActions>
									<Button onClick={() => this.setState({window:'',aborted:true}) } color="primary">{ETVConstants.trans("close")}</Button>
									<Button onClick={() => this.saveNewOwner() } disabled={this.state.new_owner_id<0} variant="contained" color="secondary">{ETVConstants.trans("save")}</Button>
							</DialogActions>
						</Dialog>

						<Dialog
							open={this.state.window==='show_device_reply_status_div'}
							maxWidth='xs'
							fullWidth={true}
							onClose={()=>{}}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description">
							<DialogTitle id="alert-dialog-title">{ETVConstants.trans("status")}</DialogTitle>
							<DialogContent>
									<div>
										{this.state.waiting_for_reply?<CircularProgress/>:<span/>}
									</div>
									<div style={{marginTop:10}}>
										<Typography variant="body2">{this.state.reply_info_message}</Typography>
									</div>
							</DialogContent>
							<DialogActions>
									<Button onClick={() => this.setState({window:'',aborted:true}) } color="primary">{ETVConstants.trans("close")}</Button>
							</DialogActions>
						</Dialog>

						<Dialog
							open={this.state.window==='edit_details_div'}
							maxWidth='xs'
							fullWidth={true}
							onClose={()=>{}}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description">
							<DialogTitle id="alert-dialog-title">{ETVConstants.trans("edit_info")}</DialogTitle>
							<DialogContent>
											{this.state.selected_device_id?<div><Typography variant="body2">{ETVConstants.trans("device_name")}:</Typography><Typography variant="body2" style={{fontWeight:'bold'}}>{this.getDeviceName(this.state.selected_device_id)}</Typography></div>:<span/>}
											<div style={{marginTop:10}}><Typography variant="body2">{ETVConstants.trans("device_id")}:</Typography><Typography variant="body2" style={{fontWeight:'bold'}}>{this.state.selected_device_id}</Typography></div>

											{this.state.selected_device_id?<div style={{marginTop:10}}><Typography variant="body2">{ETVConstants.trans("has_maintenance_contract")}:</Typography><Typography variant="body2" style={{fontWeight:'bold'}}>{this.getMaintenanceContract(this.state.selected_device_id)}</Typography></div>:<span/>}
											{(isSuperAdmin||isOEM)?this.showMaintenanceContractSwitch(this.state.selected_device_id):<span/>}

											{this.state.selected_device_id?<div style={{marginTop:10}}><Typography variant="body2">{ETVConstants.trans("warranty_expiry_date")}:</Typography><Typography variant="body2" style={{fontWeight:'bold'}}>{this.getWarrantyExpiryDate(this.state.selected_device_id)}</Typography></div>:<span/>}
											{(isSuperAdmin)?this.showWarrantyExpiryDate(this.state.selected_device_id):<span/>}

											{this.state.selected_device_id?<div style={{marginTop:10}}><Typography variant="body2">{ETVConstants.trans("os")}:</Typography><Typography variant="body2" style={{fontWeight:'bold'}}>{this.getOSVersion(this.state.selected_device_id)}</Typography></div>:<span/>}
											{this.state.selected_device_id?<div style={{marginTop:10}}><Typography variant="body2">{ETVConstants.trans("software_version")}:</Typography><Typography variant="body2" style={{fontWeight:'bold'}}>{this.getSWVersion(this.state.selected_device_id)}</Typography></div>:<span/>}
											{this.state.selected_device_id?<div style={{marginTop:10}}><Typography variant="body2">{ETVConstants.trans("screen_ratio")}:</Typography><Typography variant="body2" style={{fontWeight:'bold'}}>{this.getScreenRatio(this.state.selected_device_id)}</Typography></div>:<span/>}
											{this.state.selected_device_id?<div style={{marginTop:10}}><Typography variant="body2">IP ADDR:</Typography><Typography variant="body2" style={{fontWeight:'bold'}}>{this.getIP_Internal(this.state.selected_device_id)}</Typography></div>:<span/>}
											{this.state.selected_device_id?<div style={{marginTop:10}}><Typography variant="body2">MAC ETH0:</Typography><Typography variant="body2" style={{fontWeight:'bold'}}>{this.get_MACETH0(this.state.selected_device_id)}</Typography></div>:<span/>}
											{this.state.selected_device_id?<div style={{marginTop:10}}><Typography variant="body2">MAC WLAN0:</Typography><Typography variant="body2" style={{fontWeight:'bold'}}>{this.get_MACWLAN0(this.state.selected_device_id)}</Typography></div>:<span/>}

											<Divider style={{marginTop:10}} />
											<div style={{marginTop:20}}><TextField  fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, device_name:e.target.value})}} label={ETVConstants.trans("device_name")} value={this.state.device_name} /></div>
											<div style={{marginTop:10}}><TextField  fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, location_name:e.target.value})}} label={ETVConstants.trans("location_name")} value={this.state.location_name} /></div>
											<div style={{marginTop:10}}><TextField  fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, address:e.target.value})}} label={ETVConstants.trans("address")} value={this.state.address} /></div>
											<div style={{marginTop:10}}><TextField  fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, zip:e.target.value})}}  label={ETVConstants.trans("zip")} value={this.state.zip} /></div>
											<div style={{marginTop:10}}><TextField  fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, city:e.target.value})}}  label={ETVConstants.trans("city")} value={this.state.city} /></div>
											<div style={{marginTop:10}}><TextField  fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, country:e.target.value})}}  label={ETVConstants.trans("country")} value={this.state.country} /></div>
											<div style={{marginTop:20}}><TextField  fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, contact_phone:e.target.value})}}  label={ETVConstants.trans("phone")} value={this.state.contact_phone} helperText={ETVConstants.trans("notification_sms_in_case_of_issues")}/></div>
											<div style={{marginTop:20}}><TextField  fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, contact_email:e.target.value})}}  label={ETVConstants.trans("email")} value={this.state.contact_email} helperText={ETVConstants.trans("notification_email_in_case_of_issues")}/></div>

											<Divider style={{marginTop:10}} />
											<div style={{marginTop:10}}><Button size='small' variant='contained' color='primary' onClick={()=>this.showWindow("location_map_div",true)}>{ETVConstants.trans("set_location")}</Button></div>
											<div style={{marginTop:10}}><TextField disabled={true} size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, lat:e.target.value})}}  label={ETVConstants.trans("latitude")} value={this.state.lat} /></div>
											<div style={{marginTop:10}}><TextField disabled={true} size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, lgt:e.target.value})}}  label={ETVConstants.trans("longitude")} value={this.state.lgt} /></div>

											<Divider style={{marginTop:10}} />
											<DefaultColorFontsChoices device_id={this.state.selected_device_id} onChange={this.fontOrColorChanged} />

							</DialogContent>
							<DialogActions>
									<Button size='small' color="primary" onClick={() => this.closeLocationSelection()}>{ETVConstants.trans("close")}</Button>
									<Button size='small' disabled={!this.state.hasChanged} variant='contained' color="secondary" onClick={() => this.saveLocationDetails()}>{ETVConstants.trans("save")}</Button>
							</DialogActions>
						</Dialog>

						<Dialog
							open={this.state.window==='edit_group_location_div'}
							maxWidth='xs'
							fullWidth={true}
							onClose={()=>{}}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description">
							<DialogTitle id="alert-dialog-title">{ETVConstants.trans("set_info")}</DialogTitle>
							<DialogContent>
											<div style={{marginTop:10}}><TextField  fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, address:e.target.value})}} label={ETVConstants.trans("address")} value={this.state.address} /></div>
											<div style={{marginTop:10}}><TextField  fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, zip:e.target.value})}}  label={ETVConstants.trans("zip")} value={this.state.zip} /></div>
											<div style={{marginTop:10}}><TextField  fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, city:e.target.value})}}  label={ETVConstants.trans("city")} value={this.state.city} /></div>
											<div style={{marginTop:10}}><TextField  fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, country:e.target.value})}}  label={ETVConstants.trans("country")} value={this.state.country} /></div>
											<div style={{marginTop:20}}><TextField  fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, contact_phone:e.target.value})}}  label={ETVConstants.trans("phone")} value={this.state.contact_phone} helperText={ETVConstants.trans("notification_sms_in_case_of_issues")}/></div>
											<div style={{marginTop:20}}><TextField  fullWidth size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, contact_email:e.target.value})}}  label={ETVConstants.trans("email")} value={this.state.contact_email} helperText={ETVConstants.trans("notification_email_in_case_of_issues")}/></div>
											<div style={{marginTop:10}}><Button size='small' variant='contained' color='primary' onClick={()=>this.showWindow("location_map_div",true)}>{ETVConstants.trans("set_location")}</Button></div>
											<div style={{marginTop:10}}><TextField disabled={true} size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, lat:e.target.value})}}  label={ETVConstants.trans("latitude")} value={this.state.lat} /></div>
											<div style={{marginTop:10}}><TextField disabled={true} size="small" variant="outlined" onChange={(e)=>{this.setState({hasChanged:true, lgt:e.target.value})}}  label={ETVConstants.trans("longitude")} value={this.state.lgt} /></div>
							</DialogContent>
							<DialogActions>
									<Button size='small' variant='outlined' color="primary" onClick={() => this.closeLocationSelection()}>{ETVConstants.trans("close")}</Button>
									<Button size='small' disabled={!this.state.hasChanged} variant='contained' color="secondary" onClick={() => this.saveGroupLocationDetails()}>{ETVConstants.trans("save")}</Button>
							</DialogActions>
						</Dialog>

						<Dialog
							open={this.state.window==='set_device_volume_div'}
							maxWidth='xs'
							fullWidth={true}
							onClose={()=>{}}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description">
							<DialogTitle id="alert-dialog-title">{ETVConstants.trans("set_device_volume")}</DialogTitle>
							<DialogContent>
									<div>
										{this.state.waiting_for_reply>0?<div style={{marginTop:10}}><CircularProgress/></div>:<span/>}
										{this.state.reply_info_message?
											<div style={{marginTop:10}}><Typography variant='body2'>{this.state.reply_info_message}</Typography></div>
											:
											<span/>
										}
									</div>
									<div style={{marginTop:10}}><Button size='small' variant='contained' color="secondary" onClick={() => this.setVolume(this.state.selected_device_id,0)}>{ETVConstants.trans("mute_screen")}<VolumeOffIcon/></Button></div>
									<div style={{marginTop:10}}><Button size='small' variant='contained' color="primary" onClick={() => this.setVolume(this.state.selected_device_id,"down")}>-&nbsp;<VolumeDownIcon/></Button></div>
									<div style={{marginTop:10}}><Button size='small' variant='contained' color="primary" onClick={() => this.setVolume(this.state.selected_device_id,"up")}>+&nbsp;<VolumeUpIcon/></Button></div>
									<Divider style={{marginTop:10}} />
									<div style={{marginTop:10}}><Button size='small' variant='contained' color="primary" onClick={() => this.setVolume(this.state.selected_device_id,25)}>{ETVConstants.trans("25_volume")}<VolumeDownIcon/></Button></div>
									<div style={{marginTop:10}}><Button size='small' variant='contained' color="primary" onClick={() => this.setVolume(this.state.selected_device_id,50)}>{ETVConstants.trans("50_volume")}<VolumeDownIcon/></Button></div>
									<div style={{marginTop:10}}><Button size='small' variant='contained' color="primary" onClick={() => this.setVolume(this.state.selected_device_id,75)}>{ETVConstants.trans("75_volume")}<VolumeUpIcon/></Button></div>
									<div style={{marginTop:10}}><Button size='small' variant='contained' color="primary" onClick={() => this.setVolume(this.state.selected_device_id,100)}>{ETVConstants.trans("max_volume")}<VolumeUpIcon/></Button></div>
							</DialogContent>
							<DialogActions>
									<Button size='small' color="primary" onClick={() => this.setState({reply_info_message:"",window:''}) }>{ETVConstants.trans("close")}</Button>
							</DialogActions>
						</Dialog>


		   	</div>
			);
	}
}

export default DeviceMgmt
