import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

class CheckBoxList2 extends React.Component {

    state={
      checkboxItems : this.props.list,
      itemchanged: false,
    }

    createList() {
      var list = [];
      this.state.checkboxItems.map( (elem,i) => {
        if(elem.isChecked) {
          list.push(elem.name);
        }
      });
      return list;
    }

    componentDidUpdate(prevProps,prevState) {
      if(this.state.checkboxItems!==this.props.list) {
        this.state.checkboxItems = this.props.list;
        this.state.itemchanged = false;
      }
      if(this.state.itemChanged) {
        this.props.callback(this.createList());
        this.setState( {
          itemChanged:false,
        });
      }
    }

    handleCheckBoxChange( i) {
      this.setState( (state, props) => {
        state.checkboxItems[i].isChecked = !state.checkboxItems[i].isChecked;
        return {
          checkboxItems: state.checkboxItems,
          itemChanged: true,
        }
      });
    }

    // need to change the state and re-render
    handleChecked = (i) => (event) => {
      this.setState( (state, props) => {
        state.checkboxItems[i].isChecked = !state.checkboxItems[i].isChecked;
        //
        return {
          checkboxItems: state.checkboxItems,
          itemChanged: true,
        }
      });
    }

    render() {
      return (
        <FormControl component="fieldset" >
            {this.props.title?
              <div>
                <FormLabel component="legend">
                  <Typography variant="h6">{this.props.title}</Typography>
                </FormLabel>
                <Divider/>
              </div>
              :
              <span/>
            }
            <FormGroup>
            { this.props.list.map((elem, i) =>{
              var label = this.props.translateF?this.props.translateF(elem.name):elem.name;
              return(
                this.props.disabled?
                  <FormControlLabel disabled id={i} key={i} control={<Checkbox checked={elem.isChecked} onClick={()=>this.handleCheckBoxChange(i)} name={elem.name} />} label={label} />
                  :
                  <FormControlLabel id={i} key={i} control={<Checkbox checked={elem.isChecked} onClick={()=>this.handleCheckBoxChange(i)} name={elem.name} />} label={label} />
              )
              })
            }
            </FormGroup>
        </FormControl>
      )
    }
}

export default CheckBoxList2;
