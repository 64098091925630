import React from 'react';
import axios from 'axios';
import Image from 'material-ui-image'
import Dropzone from "react-dropzone";
import {Button,Divider} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import * as ETVConstants from '../ETVConstants';

class SelectBackground extends React.Component {

    state = {
			list:[],
      current_bg: undefined,
			bg_color: "#000000",
    }

		componentDidUpdate(prevProps, prevState) {
      if(!this.state.isLoaded) {
        this.loadItems();
      }
			if(prevProps.device_id !== this.props.device_id) {
				this.loadItems();
			}
		}

    componentDidMount() {
      this.loadItems();
    }

    loadItems() {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=get_backgrounds&device_id="+this.props.device_id+"&user_id="+this.props.user_id;
			if(this.props.group_id>0) {
				url = ETVConstants.getServerBase()+"/device_mgmt?action=get_backgrounds&group_id="+this.props.group_id+"&user_id="+this.props.user_id;
			}
      //console.log("load items url="+url);
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						var currentBG = result['current'];
						this.setState({list:result.list, isLoaded:true, current_bg:currentBG});
						if(currentBG.startsWith("#")) {
							this.setState({bg_color:currentBG});
						}else{
							this.setState({bg_color:"#000000"});
						}
          },
          (error) => {
            this.setState({
              current_bg: undefined,
            });
          }
        )
    }

		saveBGColor() {
			this.setBGImage(this.state.bg_color);
			this.closeBackgroundSelection();
		}

		setBGImage( bgImg) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=set_background&device_id="+this.props.device_id+"&background_image="+encodeURIComponent(bgImg);
			if(this.props.group_id>0) {
				url = ETVConstants.getServerBase()+"/device_mgmt?action=set_background&group_id="+this.props.group_id+"&background_image="+encodeURIComponent(bgImg);
			}
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						this.setState({isLoaded:false});
          },
          (error) => {
            this.setState({
              current_bg: undefined,
            });
          }
        )
		}

		deleteBGImage( uri) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=delete_background&background_id="+uri;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then(res => res.json())
				.then(
					(result) => {
						this.setState({isLoaded:false});
					},
					(error) => {
						this.setState({
							current_bg: undefined,
						});
					}
				)
		}

		createBackground(jsonObj) {
			var url = jsonObj.public_url;
			var uri = jsonObj.uri;
			var hasBorder = (url===this.state.current_bg);
			var border = '1px solid lightgray';
			if(hasBorder) {
				border='1px solid red';
			}
			return (
					<div key={Math.random()} id='bgimg' style={{width:200, float:'left', padding:10, border:border,}} >
							<Image src={ETVConstants.ON_PREMISE_VERSION?ETVConstants.makeContentURLRelative(url):url} style={{maxWidth:250}} /><br/>
							{!hasBorder?
								<div>
									<Button size='small' variant='contained' color='primary' style={{float: 'left'}} onClick={()=>{this.setBGImage(url)}}>{ETVConstants.trans("set_background")}</Button>
									<IconButton size='small' variant='outlined' style={{float: 'right'}} onClick={()=>{this.deleteBGImage(uri)}}><DeleteIcon/></IconButton>
								</div>
								:
								<Button size='small' variant='contained' color='secondary' style={{float: 'left'}} onClick={()=>{this.setBGImage("")}}>{ETVConstants.trans("remove_background")}</Button>
							}
					</div>
				)
		}

		onDrop = (files) => {
        //console.log("onDrop");
				var targetDir = "/"+this.props.user_id+"/backgrounds";
				for( var i=0; i<files.length; i++) {
					if(ETVConstants.ON_PREMISE_VERSION) {
						this.uploadFileToOnPremise( files[i], targetDir);
					}else{
						this.uploadFileToGCS( files[i], targetDir);
					}
				}
		}

		updateProgress( size, loaded) {
			//console.log("size="+size+" loaded="+loaded);
		}

		uploadFileToOnPremise( file, targetDir) {
      //console.log("uploadFileToOnPremise");
			var filename = file.name;
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var formData = new FormData();
			formData.append('file', file);
			formData.append('filename', filename);
			formData.append('target_dir',targetDir);
			const config = {
				headers: {	'content-type':'multipart/form-data', 'user_id':reqUserID, 'access_token': accessToken },
				onUploadProgress: progressEvent => this.updateProgress(file.size, progressEvent.loaded)
			}
			var url = ETVConstants.getServerBase()+"/file_premise_upload";
			axios.post(url, formData, config)
					.then((response) => {
						this.setState({isLoaded:false});
					})
					.catch((error) => {
						 console.error(error);
					});
			}

		uploadFileToGCS( file, targetDir) {
      //console.log("uploadFileToGCS");
			var filename = file.name;
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/gcs_upload?action=request_upload&target_dir="+targetDir+"&filename="+filename;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then(res => res.json())
				.then(
					(result) => {
            //console.log("res="+JSON.stringify(result));
						var formData = new FormData();
						Object.entries(result.fields).map(([key,value])=>{
								return formData.append(key,value);
						})
						formData.append('file', file);
						const config = {
							headers: {	'content-type': 'multipart/form-data'}
						}
						axios.post(result.url, formData, config)
								.then((response) => {
                  //console.log("give publicAccess");
									this.givePublicAccess(targetDir,filename);
									this.setState({isLoaded:false});
								})
								.catch((error) => {
									 console.error(error);
								});
					},
					(error) => {
					}
				)
		}

		givePublicAccess(target_dir,filename) {
			var url = ETVConstants.getServerBase()+"/gcs_upload?action=public_access&target_dir="+target_dir+"&filename="+filename;
			ETVConstants.sendCMDToServer(url);
		}

		setBGFingerprint(deviceID, fp) {
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=set_bg_fingerprint&device_id="+deviceID+"&fingerprint="+fp;
			ETVConstants.sendCMDToServer(url);
		}

		closeBackgroundSelection() {
			this.props.closeF();
		}

    render() {
      return(
					<div>
							 <div id='wrapper' style={{width:'100%',border:'',overflow:'hidden',marginTop:10}}>
									 <div id='bgimg' style={{width:200, float:'left', border:'1px solid lightgray', padding:10,}} >
											 <Dropzone
													 onDrop={this.onDrop}
													 disabled={false}
													 accept="image/jpeg"
													 multiple={false}
													 minSize={1024}
													 maxSize={5242880}
													 style={{width:'100%',height:'100%'}}>

											 {({ getRootProps, getInputProps }) => (
												 <div {...getRootProps({ className: "dropzone" })}>
														 <input {...getInputProps()} />
														 <p>{ETVConstants.trans("click_to_upload_files_max_5mb")}</p>
												 </div>
											 )}
											 </Dropzone>
									 </div>
									 { this.state.list.map( (item,key) => { return this.createBackground(item) })}
							 </div>
							 <Divider style={{marginTop:20}}/>
							 <br/>
							 <div><Typography variant="h6">{ETVConstants.trans("background_color")}</Typography></div>
							 <div><input type="color" style={{marginTop:10}} value={this.state.bg_color} onChange={e => this.setState({bg_color:e.target.value} )} /></div>
							 <div><Button size='small' variant='contained' color='primary' style={{float: 'left',marginTop:10}} onClick={()=>{this.saveBGColor()}}>{ETVConstants.trans("save_background_color")}</Button></div>
					 </div>
			);
	}
}
export default SelectBackground
