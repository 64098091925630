import React from 'react';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import * as ETVConstants from '../ETVConstants';

class StatusSetting extends React.Component {

    state = {
			curStatus: 'offline',
			status_validity_date: undefined,
			status_validity_comment: '',
			hasChanged: false,
			needReloadUser: false,
			showValidityDate: false,
    }

    componentDidMount() {
			this.setState({curStatus:this.props.user_object.status});
			this.loadMembership(this.props.user_object.user_id);
    }

		componentDidUpdate(prevProps,prevState) {
			if(prevProps.user_object!==this.props.user_object) {
				this.setState({curStatus:this.props.user_object.status});
				this.loadMembership(this.props.user_object.user_id);
			}
		}

		loadMembership(userID) {
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
			var urlStr = ETVConstants.getServerBase()+"/membershp?action=get_status&user_id="+this.props.user_object.user_id;
      fetch(urlStr,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						//console.log(JSON.stringify(result));
						var status = result.status;
						var statusDate = new Date(result.until_date);
						var comment = result.comment;
						this.setState({hasChanged:false, curStatus:status, status_validity_date:statusDate, status_validity_comment: comment});
          },
          (error) => {
          }
        )
    }

		oneYearLater() {
			var d = new Date();
			return new Date(d.getFullYear()+1,d.getMonth(),d.getDate(),0,0,0,0);
		}

		forever() {
			var d = new Date();
			return new Date(d.getFullYear()+100,d.getMonth(),d.getDate(),0,0,0,0);
		}

    saveStatus() {
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
			var urlStr = ETVConstants.getServerBase()+"/membershp?action=set_status&user_id="+this.props.user_object.user_id+"&status="+this.state.curStatus+"&is_trial=0&until_date="+this.printDate();
			urlStr+="&comment="+encodeURIComponent(this.state.status_validity_comment&&this.state.status_validity_comment.length>0?this.state.status_validity_comment:"");
			//console.log("---------------------saveStatus, url="+urlStr);
      fetch(urlStr,{ headers: { 'user_id':reqUserID,'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						//console.log(JSON.stringify(result));
						this.setState({hasChanged:false, needReloadUser:true});
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {

          }
        )
    }

		changeStatus(nextStatus) {
				if(this.state.curStatus===nextStatus) {
					return;
				}
				if(nextStatus==='premium' || nextStatus==='basic') {
					this.setState({curStatus:nextStatus, hasChanged:true,showValidityDate:true, status_validity_comment:'', status_validity_date: this.oneYearLater()});
				}else{
					this.setState({curStatus:nextStatus, hasChanged:true,showValidityDate:false, status_validity_comment:'', status_validity_date: this.forever()});
				}
		}

		showValidityDate( status) {
			 return status===this.state.curStatus && this.state.status_validity_comment;
		}

		setStatusDate = (date) => {
			this.setState({status_validity_date:date, updateDate:true, hasChanged:true});
		};

		getValidityDate() {
      //console.log("validityDate="+this.state.status_validity_date);
			if (!this.state.status_validity_date || this.state.status_validity_date===null || this.state.status_validity_date.length===0) {
				return this.oneYearLater();
			}
			return this.state.status_validity_date;
		}

		printDate() {
			var d = this.getValidityDate();
			//console.log("------------------->printDate d="+d);
			return d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate();
		}

		printValidity( status) {
			var showDate = (status==='basic' || status==='premium' || status==='enterprise');
			return (
				<div>
						{showDate?
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										margin="normal"
										id="status_date_until"
										label="until date"
										format='yyyy-MM-dd'
                    maxDate='2150-1-1'
										value={this.printDate(this.getValidityDate())}
										onChange={this.setStatusDate}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}} />
							</MuiPickersUtilsProvider>
							:
							<span/>
						}
						<TextField multiline label='comment' minRows={2} variant='outlined' value={this.state.status_validity_comment} onChange={(e)=>{this.setState({status_validity_comment:e.target.value, hasChanged:true})}}/>
				</div>
			)
		}



    render() {
				var parentStatus = this.props.status;

				return (
					<Card>
					 <CardContent>
					 <br/>
					 <FormControl component="fieldset">
							<FormLabel component="legend">Status</FormLabel>
							<RadioGroup style={{marginTop:20}} aria-label="Membership Status" name="status" value={this.state.curStatus} onChange={(e)=>{this.changeStatus(e.target.value)}}>
								<span>
							 			<FormControlLabel value="offline" control={<Radio />} label="offline" />
										{this.state.curStatus==='offline'?this.printValidity('offline'):<span/>}
								</span>
								<Divider style={{marginTop:5}}/>
							 <FormControlLabel value="content_provider" control={<Radio />} label="Content Provider" />
							 <Divider style={{marginTop:5}}/>
							 <span>
							 		<FormControlLabel value="basic" control={<Radio />} label="basic" />
									{this.state.curStatus==='basic'?this.printValidity('basic'):<span/>}
							 </span>
							 	<Divider style={{marginTop:5}}/>
							 <span>
							 		<FormControlLabel value="premium" control={<Radio />} label="premium" />
									{this.state.curStatus==='premium'?this.printValidity('premium'):<span/>}
							 </span>
							 <Divider style={{marginTop:5}}/>
               <span>
							 		<FormControlLabel value="enterprise" control={<Radio />} label="enterprise" />
									{this.state.curStatus==='enterprise'?this.printValidity('enterprise'):<span/>}
							 </span>
							 <Divider style={{marginTop:5}}/>
							 {parentStatus==='superadmin'?
							 			<span>
							 				<FormControlLabel value="oem" control={<Radio />} label="OEM" /><br/>
											<Divider style={{marginTop:5}}/>
											<FormControlLabel value="marketplace_provider" control={<Radio />} label="Market Place Provider" /><br/>
											<Divider style={{marginTop:5}}/>
											<FormControlLabel value="superadmin" control={<Radio />} label="Superadmin" /><br/>
                      <Divider style={{marginTop:5}}/>
											<FormControlLabel value="service_technician" control={<Radio />} label="Service Technician" /><br/>
										</span>
										:
										<span/>
							}
							</RadioGroup>
							</FormControl>
					 </CardContent>
					 <CardActions>
						  <Button size="small" variant='contained' color='secondary' disabled={!this.state.hasChanged}  onClick={() => this.saveStatus()}>{ETVConstants.trans("save")}</Button>
					 </CardActions>
				 </Card>


				)
		}

}

export default StatusSetting
