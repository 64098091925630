import React from 'react';
import ReactDOM from 'react-dom';
import {Card,Button,Divider,TextField} from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import CardMedia from '@material-ui/core/CardMedia';
import {  MuiPickersUtilsProvider,  KeyboardTimePicker,  KeyboardDatePicker,} from '@material-ui/pickers';
import CheckBoxList2 from '../tools/CheckBoxList2';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import BasicTable2 from "../tools/BasicTable2";
import IconButton from '@material-ui/core/IconButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import EditIcon from '@material-ui/icons/Edit';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import ImageIcon from '@material-ui/icons/Image';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import ApartmentIcon from '@material-ui/icons/Apartment';
import EditIO from './EditIO';
import EditFloorNames from './EditFloorNames';
import FloorSounds from './FloorSounds';
import FloorImages from './FloorImages';
import SettingsIcon from '@material-ui/icons/Settings';
import GeneralElevatorConfig from './GeneralElevatorConfig';
import TravelStatistics from './TravelStatistics';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';
import { FormattedMessage} from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as ETVConstants from '../ETVConstants';

class ElevatorStatistics extends React.Component {

	constructor(props) {
      super(props);
    }

    state = {
      items:[],
      isLoaded: false,
			selected_comm_nr: undefined,
    }

		componentDidUpdate() {
      if(!this.state.isLoaded) {
        this.loadItems();
      }
		}

    componentDidMount() {
      this.loadItems();
    }

    loadItems() {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/trip_mgmt?action=list&user_id="+this.props.user_id;
			//console.log("url="+url);
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("items="+result.list.length);
            this.setState({
              items: result.list,
              isLoaded: true,
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
          }
        )
    }

    showWindow(divID, show) {
      { show?this.setState({window:divID}):this.setState({window:''}) };
    }

    getItem( deviceID) {
      for(var i=0; i<this.state.items.length; i++) {
        if(this.state.items[i].id===deviceID) {
          return this.state.items[i];
        }
      }
      return undefined;
    }

		showTravelStatistics( commNr) {
			this.showWindow("travel_statistics_div",true);
			this.setState({selected_comm_nr:commNr});
		}

		formatDist( num) {
			return ( Math.round(num*100)/100000).toFixed(2)+" km";
		}

		formatAvgDay( num) {
			return num.toFixed(2)+"/day";
		}

		resetCounter( deviceID) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/trip_mgmt?action=reset_counter&device_id="+deviceID;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then(res => res.json())
				.then(
					(result) => {
						this.setState({
							isLoaded: false,
						});
					},
					(error) => {
					}
				)
		}

		resetCounterComplete( deviceID) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/trip_mgmt?action=reset_counter_complete&device_id="+deviceID;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then(res => res.json())
				.then(
					(result) => {
						this.setState({
							isLoaded: false,
						});
					},
					(error) => {
					}
				)
		}

		calcAvg( rowData, initialDate) {
			//console.log("rowData="+JSON.stringify(rowData));
			var now = new Date();
			var d0 = new Date(rowData.initial_date);
			var trips = rowData.trips_total;
			if(!initialDate) {
				d0 = new Date(rowData.current_date);
				trips = rowData.trips_current;
			}
			//console.log("isNan(trips): "+isNaN(trips)+" trips="+trips);
			if(isNaN(trips)) return 0;
			if(isNaN(d0)) return 0;

			var diffMs = now.getTime()-d0.getTime();
			var diffDays = diffMs/1000/3600/24;
			diffDays = Math.max(1.0,diffDays);
			//console.log("diffdays="+diffDays);
			return this.formatAvgDay(trips/diffDays);
		}

    render() {

      const columnsData= [
          { title: ETVConstants.trans("device_name"), field: 'device_name', render: rowData => (<div style={{minWidth: "150px", paddingLeft: "10px"}}> {rowData.device_name} </div>) },
					{ title: ETVConstants.trans("location_name"), field: 'location_name'},
					{ title: ETVConstants.trans("trips_current"), field: 'trips_current', render: rowData => (<div style={{minWidth: "30px", paddingLeft: "10px"}}> {rowData.trips_current} </div>)},
					{ title: ETVConstants.trans("distance_current"), field: 'distance_current', render: rowData => (<div style={{minWidth: "30px", paddingLeft: "10px"}}> {this.formatDist(rowData.distance_current)} </div>)},
					{ title: ETVConstants.trans("current_average_day"), field: '', render: rowData => (<div style={{minWidth: "30px", paddingLeft: "10px"}}> {this.calcAvg(rowData,false)} </div>)},
					{ title: ETVConstants.trans("reset"), field: 'reset', render: rowData => (<Button variant='contained' size='small' color='primary' onClick={()=>this.resetCounter(rowData.device_id)}>{ETVConstants.trans("reset")}</Button>)},
					{ title: ETVConstants.trans("trips_total"), field: 'trips_total', render: rowData => (<div style={{minWidth: "30px", paddingLeft: "10px"}}> {rowData.trips_total} </div>)},
					{ title: ETVConstants.trans("distance_total"), field: 'distance_total', render: rowData => (<div style={{minWidth: "30px", paddingLeft: "10px"}}> {this.formatDist(rowData.distance_total)} </div>)},
					{ title: ETVConstants.trans("overall_average"), field: '', render: rowData => (<div style={{minWidth: "30px", paddingLeft: "10px"}}> {this.calcAvg(rowData,true)} </div>)},
					{ title: ETVConstants.trans("reset_completely"), field: 'reset', render: rowData => (<Button variant='contained' size='small' color='secondary' onClick={()=>this.resetCounterComplete(rowData.device_id)}>{ETVConstants.trans("reset_completely")}</Button>)},
					//{ title: ETVConstants.trans("travel_statistics"), field: 'travel_statistics', render: rowData => (<Button variant='contained' disabled={!rowData.commission_nr || rowData.commission_nr.trim().length==0} color='primary' onClick={()=>this.showTravelStatistics(rowData.commission_nr)}>{ETVConstants.trans("statistics")}</Button>)},
        ];
      const tabletitle = "Elevator Screens";

      return(
				<div>
	          <BasicTable2 data={this.state.items} columns={columnsData} title={tabletitle} pageSize={20} allow_search={true} />
						<Dialog
		            open={this.state.window==='travel_statistics_div'}
		            maxWidth='md'
		            fullWidth={true}
		            onClose={()=>{}}
		            aria-labelledby="alert-dialog-title"
		            aria-describedby="alert-dialog-description">
		            <DialogTitle id="alert-dialog-title">{ETVConstants.trans("travel_statistics")}</DialogTitle>
		            <DialogContent>
		                {this.state.selected_comm_nr?<TravelStatistics comm_nr={this.state.selected_comm_nr} />:<span/>}
		            </DialogContent>
		            <DialogActions>
		              <Button onClick={()=>{this.showWindow('travel_statistics',false)}} color="primary">{ETVConstants.trans("close")}</Button>
		            </DialogActions>
	          </Dialog>
		   	</div>
			);
	}
}

export default ElevatorStatistics
