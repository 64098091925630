import React from 'react';
import {Card,Button} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import * as ETVConstants from '../ETVConstants';

class DirectoryItem extends React.Component {

    getDirName( dir) {
      var dirLastPart = dir;
      if(dirLastPart.endsWith("/")) {
        dirLastPart = dirLastPart.substring(0,dirLastPart.lastIndexOf("/"));
      }
      dirLastPart = dirLastPart.substring(dirLastPart.lastIndexOf("/")+1);
      return dirLastPart;
    }

    render() {
      var dir = this.props.directory;
      var isUp = this.props.isUp;
      return (
        isUp?
          <Card style={{width:100,height:180,margin:5,padding:20}}>
            <CardMedia component="img" image="/icons/folder_up.png" alt="back" title="up" onClick={()=>{ this.props.dirClickedFunc(dir)}}/>
            <CardContent>
              <Typography align="center" variant="body2">{ETVConstants.trans("back")}</Typography>
            </CardContent>
          </Card>
          :
          <Card style={{width:100,height:180,margin:5,padding:20}}>
              <CardMedia component="img" image="/icons/folder.svg" alt="up" title={this.getDirName(dir)} onClick={()=>{ this.props.dirClickedFunc(dir)}}/>
              <CardContent>
                  <Typography align="center" variant="body2">{this.getDirName(dir)}</Typography>
              </CardContent>
              <CardActionArea>
                  <Box textAlign="center">
                    <Button component="span" variant='contained' disabled={this.props.hasContents} color='secondary' size='small' onClick={()=>{ this.props.deleteDirFunc(dir) }}>{ETVConstants.trans("delete")}</Button>
                  </Box>
              </CardActionArea>
          </Card>
      )
    }
}

export default DirectoryItem;
