import React from 'react';
import {Button,Divider} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LayoutItem from './LayoutItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as ETVConstants from '../ETVConstants';

class SelectLayout extends React.Component {

	constructor(props) {
      super(props);
			this.saveLayout = this.saveLayout.bind(this);
			this.deleteLayout = this.deleteLayout.bind(this);
    }

    state = {
			portait_items:[],
			portait_items_custom:[],
			landscape_items:[],
			landscape_items_custom:[],
      isLoaded: false,
			window: undefined,
			info_message: undefined,
			info_message_title: undefined,
    }

		componentDidUpdate() {
      if(!this.state.isLoaded) {
        this.loadItems();
      }
		}

    componentDidMount() {
      this.loadItems();
    }

		saveLayout(layoutID) {
			var url = ETVConstants.getServerBase()+"/layout_mgmt?action=save_layout&device_id="+this.props.device_id+"&layout_id="+layoutID;
			if(this.props.group_id>0) {
					url = ETVConstants.getServerBase()+"/layout_mgmt?action=save_layout&group_id="+this.props.group_id+"&layout_id="+layoutID;
			}
			//console.log("select layout, url="+url);
      fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
						//console.log(JSON.stringify(result));
          	this.props.closeF();
          },
          (error) => {
          }
        )
		}

		deleteLayout(layoutID) {
			this.showInfoMessage("delete","deleting layout...");
			var url = ETVConstants.getServerBase()+"/layout_mgmt?action=delete_layout&user_id="+this.props.user_id+"&layout_id="+layoutID;
			fetch(url)
				.then(res => res.json())
				.then(
					(result) => {
						if(result["result"]) {
							this.showInfoMessage("delete","successfully deleted layout");
							this.setState({isLoaded:false});
						}else{
							this.showInfoMessage("error","failed to delete: "+result["reason"]);
						}
					},
					(error) => {
						this.showInfoMessage("error",error);
					}
				)
		}

		filterList( elems, needLandscape, custom) {
			var result = [];
			for(var i=0; i<elems.length; i++) {
				//console.log("xxx elems["+i+"]="+JSON.stringify(elems[i]));
				if(needLandscape && elems[i].landscape) result.push(elems[i]);
				if(!needLandscape && !elems[i].landscape) result.push(elems[i]);
			}
			return result;
		}

    loadItems() {
			var url = ETVConstants.getServerBase()+"/layout_mgmt?action=list&user_id="+this.props.user_id+"&device_id="+this.props.device_id;
			if(this.props.group_id>0) {
				url = ETVConstants.getServerBase()+"/layout_mgmt?action=list&user_id="+this.props.user_id+"&group_id="+this.props.group_id;
			}
      fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("items="+result.list.length);
						var portrait_items = this.filterList(result.list, false, false);
						//var portrait_items_custom = this.filterList(result.list, false, true);
						//console.log("portrait_items_custom="+portrait_items_custom.length);

						var landscape_items = this.filterList(result.list, true, false);
						//var landscape_items_custom = this.filterList(result.list, true, true);

            this.setState({
              landscape_items: landscape_items,
							//landscape_items_custom: landscape_items_custom,
							portait_items: portrait_items,
							//portrait_items_custom: portrait_items_custom,
              isLoaded: true,
            });
						//console.log(JSON.stringify(landscape_items[0]));
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              items:[],
              isLoaded: false,
              error
            });
          }
        )
    }

		showInfoMessage(title,text) {
			this.setState({info_message_title:title,info_message:text});
			this.showWindow('message_div',true);
		}

		closeInfoMessage() {
			this.setState({info_message:undefined});
			this.showWindow('message_div',false);
		}

		showWindow(divID, show) {
			show?this.setState({window:divID}):this.setState({window:''});
		}

    render() {
      return(
						<span>
								<span>
									 { this.state.landscape_items.map( item => {
											return(
												<div key={item.layout_id} style={{display: 'inline-block', width: 250, height:200, margin: 5}}>
														<LayoutItem id={item.layout_id} screen_ratio={item.screen_ratio} landscape={item.landscape} can_delete={item.can_delete} key={item.layout_id} current_layout_id={this.props.current_layout_id} layout_id={item.layout_id} filename={item.layout_name} thumbnail={item.thumbnail} deleteLayoutF={this.deleteLayout} saveLayoutF={this.saveLayout}/>
												</div>
											)
										}
									)}
								</span>
								<Divider />
								<span>
									<br/>
									{ this.state.landscape_items_custom.map( item => {
											 return(
												 <div key={item.layout_id} style={{display: 'inline-block', width: 250, height:200, margin: 5}}>
														 <LayoutItem id={item.layout_id} screen_ratio={item.screen_ratio} landscape={item.landscape} can_delete={item.can_delete} key={item.layout_id} current_layout_id={this.props.current_layout_id} layout_id={item.layout_id} filename={item.layout_name} thumbnail={item.thumbnail} deleteLayoutF={this.deleteLayout} saveLayoutF={this.saveLayout}/>
												 </div>
											 )
										 }
									 )}
								 </span>
								 <Divider />
								 <span>
								 	<br/>
									{this.state.portait_items.map( item => {
	 										return(
	 											<div key={item.layout_id} style={{display: 'inline-block', width: 170, height:200, margin: 5}}>
	 													<LayoutItem id={item.layout_id} screen_ratio={item.screen_ratio} landscape={item.landscape} can_delete={item.can_delete} key={item.layout_id} current_layout_id={this.props.current_layout_id} layout_id={item.layout_id} filename={item.layout_name} thumbnail={item.thumbnail} deleteLayoutF={this.deleteLayout} saveLayoutF={this.saveLayout}/>
	 											</div>
	 										)
	 									}
	 								)}
								</span>
								<Divider />
								<span>
									<br/>
									{this.state.portait_items_custom.map( item => {
	 										return(
	 											<div key={item.layout_id} style={{display: 'inline-block', width: 170, height:200, margin: 5}}>
	 													<LayoutItem id={item.layout_id} screen_ratio={item.screen_ratio} landscape={item.landscape} can_delete={item.can_delete} key={item.layout_id} current_layout_id={this.props.current_layout_id} layout_id={item.layout_id} filename={item.layout_name} thumbnail={item.thumbnail} deleteLayoutF={this.deleteLayout} saveLayoutF={this.saveLayout}/>
	 											</div>
	 										)
	 									}
	 								)}
								</span>
								<Dialog
									open={this.state.window==='message_div'}
									maxWidth='xs'
									fullWidth={true}
									onClose={() => this.closeInfoMessage() }
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description">
									<DialogTitle id="alert-dialog-title">{this.state.info_message_title}</DialogTitle>
									<DialogContent>
										 <Typography variant='body1'>{this.state.info_message}</Typography>
									</DialogContent>
									<DialogActions>
										<Button onClick={() => this.closeInfoMessage() } color="primary">{ETVConstants.trans("close")}</Button>
									</DialogActions>
								</Dialog>
						</span>
			);
	}
}

export default SelectLayout
