import React from 'react';
import Chart from "react-google-charts";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import { MuiPickersUtilsProvider, KeyboardDatePicker,} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as ETVConstants from '../ETVConstants';

class PageHitChart extends React.Component {

    state = {
      report_type: 'by_date',
      isLoaded: false,
      from_date: '2020-01-01',
      to_date:undefined,
      data:[[]],
    }

    componentDidMount() {
      this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.content_id !== prevProps.content_id) {
        this.loadData();
      }
      if(!this.state.isLoaded) {
        this.loadData();
      }
    }

    getDate( d) {
      var yyyy = d.getFullYear();
      var mm = d.getMonth()+1;
      if(mm<10) {
        mm = "0"+mm;
      }
      var dd = d.getDate();
      if(dd<10) {
        dd = "0"+dd;
      }
      return yyyy+"-"+mm+"-"+dd;
    }

    getFromDate() {
      if(!this.state.from_date) return undefined;
      return this.getDate(new Date(this.state.from_date));
    }

    getToDate() {
      if(!this.state.to_date) return undefined;
      return this.getDate(new Date(this.state.to_date));
    }

    loadData() {
      var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/hit_report?content_id="+this.props.content_id+"&report_item="+this.state.report_type;
      if(this.state.from_date) {
        url+="&from_date="+this.getFromDate();
      }
      if(this.state.to_date) {
        url+="&to_date="+this.getToDate();
      }
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            var data = result.data;
            this.setState({data:data,isLoaded:true});
          },
          (error) => {
          }
        )
    }

    setToDate = (date) => {
      this.setState({to_date:date, isLoaded:false});
    };

    setFromDate = (date) => {
      this.setState({from_date:date, isLoaded:false});
    };

    closeMe() {
      this.props.closeF();
    }

    render() {
      return (
            <div>
                <fieldset>
                  <FormControl>
                     <InputLabel>{ETVConstants.trans("choose_report_type")}</InputLabel>
                     <Select
                       labelId="content_format"
                       id="content_format"
                       style={{ width: 250}}
                       value={this.state.report_type}
                       onChange={(e)=>{this.setState({report_type:e.target.value, isLoaded:false})}}>
                        <MenuItem value='by_date'>{ETVConstants.trans("report_by_date")}</MenuItem>
                        <MenuItem value='by_device'>{ETVConstants.trans("report_by_device")}</MenuItem>
                     </Select>
                   </FormControl>
                   <br/>
                   <MuiPickersUtilsProvider utils={DateFnsUtils}>
                       <KeyboardDatePicker
                         margin="normal"
                         id="date-picker-dialog"
                         label={ETVConstants.trans("from_date")}
                         value={this.state.from_date}
                         format='yyyy-MM-dd'
                         onChange={this.setFromDate}
                         KeyboardButtonProps={{
                           'aria-label': 'change date',
                         }} />
                          <br/>
                         <KeyboardDatePicker
                           margin="normal"
                           id="date-picker-dialog"
                           label={ETVConstants.trans("until_date")}
                           format='yyyy-MM-dd'
                           value={this.state.to_date}
                           onChange={this.setToDate}
                           KeyboardButtonProps={{
                             'aria-label': 'change date',
                           }} />
                    </MuiPickersUtilsProvider>
                </fieldset>
                 <Divider/>
                  <div style={{ display: 'flex', maxWidth: 900 }}>
                    <Chart
                      width={600}
                      height={400}
                      chartType="BarChart"
                      loader={<div>{ETVConstants.trans("loading")}</div>}
                      data={this.state.data}
                      options={{
                        title: this.props.chart_title,
                        chartArea: { width: '50%' },
                        hAxis: {
                          title: '',
                          minValue: 0,
                        },
                        vAxis: {
                          title: '',
                          minValue: 0,
                        },
                      }}
                      legendToggle
                    />
              </div>
          </div>
      )
    }
}

export default PageHitChart;
