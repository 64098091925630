import React from 'react';
import InfoMessage2 from '../tools/InfoMessage2';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage} from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as ETVConstants from '../ETVConstants';

class Support extends React.Component {

    state = {
      isLoaded: false,
			title:'',
			message:'',
			parent_email:'',
			parent_company: '',
			parent_addr1: '',
			parent_addr2: '',
			parent_zip: '',
			parent_city: '',
			parent_country: '',
			parent_phone: '',
			parent_url: '',
      info_message_title:'',
      info_message:'',
      window:'',
    }

    componentDidMount() {
			this.loadData();
    }

		loadData() {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/useradmin?action=get_parent&user_id="+this.props.user_id;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
      .then((response) => response.json())
      .then((user) => {
         this.setState({
            parent_email: user.user_name,
						parent_company: user.company,
						parent_addr1: user.address_1,
						parent_addr2: user.address_2,
						parent_zip: user.zip,
						parent_city: user.city,
						parent_country: user.country,
						parent_phone: user.phone,
						parent_url: user.url,
						isLoaded:true,
         })
      })
      .catch((error) => {
      });
    }

		showWindow(divID, show) {
			show?this.setState({window:divID}):this.setState({window:''});
		}

		showInfoMessage(title,text) {
			this.setState({info_message_title:title,info_message:text});
			this.showWindow('message_div',true);
		}

		closeInfoMessage() {
			this.setState({info_message:'',info_message_title:''});
			this.showWindow('message_div',false);
		}

		sendCMDToServer(url) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then((response) => response.json())
			.then((responseJson) => {
				if(responseJson['result']) {
					this.showInfoMessage(ETVConstants.trans("support_request_sent"));
					this.setState({title:'',message:''});
				}else{
					this.showInfoMessage(responseJson['reason']);
				}
			})
			.catch((error) => {
				 console.error(error);
			});
		}

		sendSupport() {
			var url = ETVConstants.getServerBase()+"/useradmin?action=get_support&user_id="+this.props.user_id+"&subject="+encodeURIComponent(this.state.title)+"&message="+encodeURIComponent(this.state.message);
			this.sendCMDToServer(url);
		}

    render() {

			var showButton = this.state.message && this.state.title;

      return(
				<span>
							<div style={{width:500, display:'flex', alignItems:'center', justifyContent:'center', flex:'1', margin:'0 auto' }}>
									<Card style={{width:500}}>
										<CardContent>
											<Typography variant='h6'>{ETVConstants.trans("support_contact")}</Typography>
											<Divider style={{marginTop:20}}/>
											<div style={{marginTop:20}}/>
											<Typography variant='body1' style={{fontWeight:'bold'}}>{this.state.parent_company}</Typography>
											<Typography variant='body2'>
													{this.state.parent_addr1}<br/>
													{this.state.parent_addr2 && this.state.parent_addr2.length>0?<span>{this.state.parent_addr2}<br/></span>:<span/>}
													{this.state.parent_zip}<br/>
													{this.state.parent_city}<br/>
													{this.state.parent_country}<br/>
													{this.state.parent_phone}<br/>
													{this.state.parent_url}<br/>
													{this.state.parent_email}<br/>
													<br/>
													<br/>
											</Typography>
											<Typography variant='h6'>{ETVConstants.trans("support_request")}</Typography>
											<TextField required key='title' label={ETVConstants.trans("subject")} fullWidth={true} value={this.state.title} onChange={(event)=>{this.setState({title:event.target.value})}} />
											<br/>
											<br/>
											<TextField required multiline key='message_body' variant='outlined' label={ETVConstants.trans("message")} fullWidth={true} maxRows={8} minRows={8} value={this.state.message} onChange={(event)=>{this.setState({message:event.target.value})}} />
										</CardContent>
										<CardActions>
											<Button color="primary" disabled={!showButton}  variant='contained' color='primary' onClick={() => this.sendSupport()}>{ETVConstants.trans("send")}</Button>
										</CardActions>
									</Card>
							</div>
              <Dialog
                  open={this.state.window==='message_div'}
                  maxWidth='xs'
                  fullWidth={true}
                  onClose={() => this.setState({window:''})  }
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description">
                  <DialogTitle id="alert-dialog-title">{this.state.info_message_title}</DialogTitle>
                  <DialogContent>
                      <Typography variant='body1'>{this.state.info_message}</Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => this.setState({window:''}) } color="primary">{ETVConstants.trans("close")}</Button>
                  </DialogActions>
              </Dialog>
	   		</span>);
	}
}

export default Support
