import React from 'react';
import axios from 'axios';
import Dropzone from "react-dropzone";
import {Card,Button,TextField} from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import {  MuiPickersUtilsProvider, KeyboardDatePicker,} from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import BasicTable2 from "../tools/BasicTable2";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Image from 'material-ui-image';
import Chart from "react-google-charts";
import SelectAvailableScreensNew from './SelectAvailableScreensNew';
import * as ETVConstants from '../ETVConstants';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class VoucherMgmt extends React.Component {

	constructor(props) {
      super(props);
			this.selectedScreens = this.selectedScreens.bind(this);
			this.closeScreenSelection = this.closeScreenSelection.bind(this);
    }

		setDefaultState() {
			this.setState({
				items:[],
	      isLoaded: false,
	      selected_device_id: undefined,
				window: undefined,
				show_overlay: false,
				info_message: '',
				info_title: '',
				selected_screens: 0,
				selected_channel: 0,
				bg_image_url: undefined,
				qr_image: undefined,
				qr_image_title: undefined,
				new_title:'',
				new_desc:'',
				new_terms:'Each coupon can be used only 1 time. There is only 1 coupon per device. Each coupon is valid for a limited time only and expires at the time specified. Coupons can not be replaced. Coupons may be discontinued at any time. No refund possible. Coupons may be invalidated for any kind of reason, and no claims can be made.',
				max_available_vouchers:200,
				max_vouchers_per_day:-1,
				max_vouchers_per_user:1,
				validity_date:undefined,
				validity_hours:168,
				terminal_id: '',
				validating: false,
				statistics_data:[],
				location_id:'web',
      });
		}

    state = {
			items:[],
			isLoaded: false,
			selected_device_id: undefined,
			window: undefined,
			show_overlay: false,
			info_message: '',
			info_title: '',
			selected_screens: 0,
			selected_channel: 0,
			bg_image_url: undefined,
			qr_image: undefined,
			qr_image_title: undefined,
			new_title:'',
			new_desc:'',
			new_terms:'Each coupon can be used only 1 time. There is only 1 coupon per device. Each coupon is valid for a limited time only and expires at the time specified. Coupons can not be replaced. Coupons may be discontinued at any time. No refund possible. Coupons may be invalidated for any kind of reason, and no claims can be made.',
			max_available_vouchers:200,
			max_vouchers_per_day:-1,
			max_vouchers_per_user:1,
			validity_date:undefined,
			validity_hours:168,
			terminal_id: '',
			validating: false,
			statistics_data:[],
			location_id:'web',
    }

		componentDidUpdate() {
      if(!this.state.isLoaded) {
        this.loadItems();
      }
		}

    componentDidMount() {
      this.loadItems();
    }

    loadItems() {
			var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/voucher_mgmt?action=get_voucher_def&user_id="+this.props.user_id;
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              items: result.voucher_definitions,
              isLoaded: true,
            });
          },
          (error) => {
            this.setState({
              items:[],
              isLoaded: true,
            });
          }
        )
    }

		loadStatistics( voucherDefID) {
			var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/voucher_mgmt?action=statistics&voucher_def_id="+voucherDefID;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then(res => res.json())
				.then(
					(result) => {
						this.setState({
							statistics_data:result.statistics,
						});
					},
					(error) => {
						this.setState({
							statistics_data:undefined,
						});
					}
				)
		}

    showWindow(divID, show) {
      this.setState({show_overlay:show});
      show?this.setState({window:divID}):this.setState({window:''});
    }

		showInfoMessage(title,msg) {
			this.showWindow('message_div',true);
			this.setState({info_title:title, info_message:msg});
		}

		closeInfoMessage() {
			this.setState({info_message:undefined});
			this.showWindow('message_div',false);
		}

		deleteVoucher( voucherDefID) {
			var url = ETVConstants.getServerBase()+"/voucher_mgmt?action=delete_voucher_def&voucher_def_id="+voucherDefID;
			this.cmdSubmit(url,"Coupon was deleted.","Failed to delete.");
		}

		cmdSubmit( url, successTxt, failText) {
			var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then(res => res.json())
				.then(
					(result) => {
						if(result.result) {
							this.showInfoMessage(ETVConstants.trans("success"),successTxt);
							this.setState({isLoaded:false});
						}else{
							this.showInfoMessage(ETVConstants.trans("error"),failText);
						}
					},
					(error) => {
						this.showInfoMessage(ETVConstants.trans("error"),error);
					}
				)
		}

		getItem( id) {
			for( var i=0; i<this.state.items.length; i++) {
				if(this.state.items[i].id===id) return this.state.items[i];
			}
			return undefined
		}

		analyzeVoucherStatistics( id) {
				this.showWindow('analyze_voucher_div',true);
				this.loadStatistics(id);
		}

		selectedScreens( screenIDs) {
			this.setState({selected_screens:screenIDs});
			this.showWindow("create_new_voucher_div",true);
		}

		closeScreenSelection() {
			this.showWindow("create_new_voucher_div",true);
		}

		onDrop = (files) => {
				var targetDir = "/"+this.props.user_id+"/voucher";
				//this.setState({isLoaded:false});
				for( var i=0; i<files.length; i++) {
					//console.log("drop files="+files[i]);
					this.uploadFileToGCS( files[i], targetDir);
				}
				this.setState({bg_image_url:"loading"})
		}

		uploadFileToGCS( file, targetDir) {
			var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
			var filename = file.name;
			var url = ETVConstants.getServerBase()+"/gcs_upload?action=request_upload&target_dir="+targetDir+"&filename="+filename;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then(res => res.json())
				.then(
					(result) => {
						var formData = new FormData();
						Object.entries(result.fields).map(([key,value])=>{
								formData.append(key,value);
								//console.log("1=======>"+key+"="+value);
						})
						formData.append('file', file);
						//formData.append('success_action_redirect',)
						const config = {
							headers: {	'content-type': 'multipart/form-data'}
						}
						axios.post(result.url, formData, config)
								.then((response) => {
									this.givePublicAccess(targetDir,filename);
									this.setState({isLoaded:false});
								})
								.catch((error) => {
									 console.error(error);
								});
					},
					(error) => {
						//console.log("errrrrror="+error);
					}
				)
		}

		// make public access to uploaded files
		givePublicAccess(target_dir,filename) {
			var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/gcs_upload?action=public_access&target_dir="+target_dir+"&filename="+filename;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then( res => res.json() )
				.then(
						(result) => {
							this.setState({bg_image_url:result.url});
						},
						(error) => {
						}
					)
		}

		showNewCouponDiv() {
			this.setState({window:"create_new_voucher_div"});
		}

		formatDate(date) {
		    var d = new Date(date),
		        month = '' + (d.getMonth() + 1),
		        day = '' + d.getDate(),
		        year = d.getFullYear();
		    if (month.length < 2)
		        month = '0' + month;
		    if (day.length < 2)
		        day = '0' + day;
		    return [year, month, day].join('-');
		}

		validateInputs() {
			if(!this.state.new_title || this.state.new_title.length===0) return false;
			if(!this.state.new_desc || this.state.new_desc.length===0) return false;
			if(!this.state.terminal_id || this.state.terminal_id.length===0) return false;
			if(!this.state.validity_date) return false;
			return true;
		}

		registerNewVoucher() {
			this.setState({validating:true});
			var vs = this.validateInputs();
			if(!vs) {
				return;
			}
			this.showInfoMessage(ETVConstants.trans("coupon"),ETVConstants.trans("creating_new_coupon"));
			var url = ETVConstants.getServerBase()+"/voucher_mgmt?action=add_new_voucher";
			url += "&title="+encodeURIComponent(this.state.new_title);
			url += "&desc="+encodeURIComponent(this.state.new_desc);
			url += "&terms="+encodeURIComponent(this.state.new_terms);
			url += "&user_id="+this.props.user_id;
			url += "&image_url="+encodeURIComponent(this.state.bg_image_url);
			url += "&nof_vouchers="+this.state.max_available_vouchers;
			url += "&max_per_user="+this.state.max_vouchers_per_user;
			url += "&max_per_day="+this.state.max_vouchers_per_day;
			url += "&validity_date="+this.formatDate(this.state.validity_date);
			url += "&validity_hours="+this.state.validity_hours;
			url += "&terminal_id="+encodeURIComponent(this.state.terminal_id);
			url+="&channel="+this.state.selected_channel;
			var deviceIDs = this.state.selected_screens;
			for(var i=0; i<deviceIDs.length; i++) {
				url += "&device_id="+deviceIDs[i];
			}
			url += "&template_path="+encodeURIComponent("templates/voucher/content_on_screen.html");
			var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then( res => res.json() )
				.then(
						(result) => {
							if(result["result"]) {
									//console.log("------------> "+JSON.stringify(result));
									this.showInfoMessage(ETVConstants.trans("coupon"),ETVConstants.trans("coupon_registered"));
									this.setDefaultState();
									this.setState({isLoaded:false});
							}
							//this.setState({isLoaded:false, new_question: undefined, bg_image_url: undefined, channel:0, notification_email: undefined});
							this.showWindow('create_voucher_div',false);
						},
						(error) => {
							//console.log("yyy-------> "+error);
							this.showInfoMessage(ETVConstants.trans("error"),error);
						}
					)
		}

		printPercentage( a, b) {
			var num = a/b;
			return num.toFixed(2)+"%";
		}

		printQRCoupon( voucherDefID, title) {
			var reDirectURL = "https://c.e1e.tv/iv/"+voucherDefID; //+ "locationID will be added"
			var qrCode = "https://e1e.tv/q?c="+encodeURIComponent(reDirectURL);
			this.setState({qr_image:qrCode, qr_image_title: title});
			this.setState({window:'qr_preview_div'});
		}

		setValidityDate = (date) => {
			this.setState({validity_date:date});
		};

		printValidityHours( hours) {
			if(hours<0) return "-";
			if(hours===1) return ETVConstants.trans("1h");
			if(hours===2) return ETVConstants.trans("2h");
			if(hours===4) return ETVConstants.trans("4h");
			if(hours===8) return ETVConstants.trans("8h");
			if(hours<24) return hours+" hours";

			if(hours===24) return ETVConstants.trans("1_day");
			if(hours===48) return ETVConstants.trans("2_days");
			if(hours<168) return (hours/24)+" days";

			if(hours===168) return ETVConstants.trans("1_week");
			if(hours===672) return ETVConstants.trans("4_weeks");
			return (hours/168)+" weeks";
		}

		printMaxPerDay( nof ) {
			if(nof<0) return ETVConstants.trans("no_limit");
			return nof;
		}

		inputOK( field) {
			if(!field) return false;
			if(field.length===0) return false;
			return true;
		}

		render() {
      const columnsData= [
					{ title: ETVConstants.trans("title"), field: 'title', render: rowData => (<div style={{minWidth: "150px", paddingLeft: "0px"}}> {rowData.title} </div>) },
          { title: ETVConstants.trans("view_qr"), field: 'desc', render: rowData => (<Button variant='contained' color='primary' onClick={()=>this.printQRCoupon(rowData.voucher_def_id, rowData.title)}>QR</Button>) },
					{ title: ETVConstants.trans("terminal_id"), field: 'terminalID', render: rowData => (<div style={{minWidth: "50px", paddingLeft: "0px"}}> {rowData.terminalID} </div>) },
					{ title: ETVConstants.trans("validity_after_distribution"), field: 'validity_hours', render: rowData => (<div style={{minWidth: "60px", paddingLeft: "0px"}}> {this.printValidityHours(rowData.validity_hours)} </div>) },
					{ title: ETVConstants.trans("expiry_date"), field: 'validity_date' },
					{ title: ETVConstants.trans("max_per_user"), field: 'max_per_user', render: rowData => (<span>{rowData.max_per_user>0?rowData.max_per_user:ETVConstants.trans("no_limit")}</span>) },
					{ title: ETVConstants.trans("max_per_day"), field: 'max_per_day', render: rowData => (<div style={{minWidth: "50px", paddingLeft: "0px"}}> {this.printMaxPerDay(rowData.max_per_day)} </div>) },
          { title: ETVConstants.trans("total_available"), field: 'max_vouchers', render: rowData => (<div style={{width:"50px", maxWidth: "50px"}}> {rowData.max_vouchers} </div>) },
					{ title: ETVConstants.trans("distributed"), field: 'distributed_vouchers', render: rowData => (<div style={{width:"100px", maxWidth: "100px"}}> {this.printPercentage(rowData.distributed_vouchers,rowData.max_vouchers)} ({rowData.distributed_vouchers}) </div>) },
					{ title: ETVConstants.trans("used"), field: 'used_vouchers'},
					{ title: ETVConstants.trans("analyze"), field: '', render: rowData => <Button variant='contained' color='primary' onClick={()=>this.analyzeVoucherStatistics(rowData.voucher_def_id)}>{ETVConstants.trans("analyze")}</Button>},
          { title: ETVConstants.trans("delete"), field: 'select_layout', render: rowData => <IconButton size='small' color='secondary' onClick={()=>this.deleteVoucher(rowData.voucher_def_id)}><DeleteIcon/></IconButton>},
			];

      const tabletitle = "Coupon Management";

      return(
				<div>
	          <BasicTable2 data={this.state.items} columns={columnsData} title={tabletitle} pageSize={10} allow_search={true} />
						<br/>
						<Button color="primary" variant='contained' color='primary' onClick={() => this.showNewCouponDiv()}>{ETVConstants.trans("create_new_coupon")}</Button>
						<Dialog
								open={this.state.window==='message_div'}
								maxWidth='xs'
								fullWidth={true}
								onClose={()=>{}}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description">
								<DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
								<DialogContent>
										<Typography variant="body1">{this.state.info_message}</Typography>
								</DialogContent>
								<DialogActions>
									<Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
								</DialogActions>
						</Dialog>
						<Dialog
								open={this.state.window==='qr_preview_div'}
								maxWidth='xs'
								fullWidth={true}
								onClose={()=>{}}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description">
								<DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
								<DialogContent>
										<Typography variant="h5" component="h6">
												{this.state.qr_image_title}
										</Typography>
										<br style={{marginTop:30}}/>
										<TextField helperText={ETVConstants.trans("optional_location_reference_for_future_tracking")}
															key='tracking_location'
															variant='outlined'
															label={ETVConstants.trans("location_reference")}
															inputProps={{ maxLength: 15 }}
															fullWidth={true}
															value={this.state.location_id}
															onChange={(event)=>{this.setState({location_id:event.target.value})}} />
										<br style={{marginTop:30}}/>
										{this.state.qr_image?<Image style={{marginTop:30}} src={this.state.qr_image+"/"+(this.state.location_id.length>0?this.state.location_id:"web")} />:<span/>}
								</DialogContent>
								<DialogActions>
									<Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
								</DialogActions>
						</Dialog>

						<Dialog
								open={this.state.window==='create_new_voucher_div'}
								maxWidth='md'
								fullWidth={true}
								onClose={()=>{}}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description">
								<DialogTitle id="alert-dialog-title">{ETVConstants.trans("create_new_coupon")}</DialogTitle>
								<DialogContent>
										<table style={{verticalAlign:'top'}}>
											<tbody>
												<tr>
													<td style={{padding:20, width:'50%', verticalAlign:'top'}}>
																<div>
																		<Dropzone
																					onDrop={this.onDrop}
																					disabled={false}
																					accept="image/jpeg"
																					multiple={false}
																					minSize={1024}
																					maxSize={5242880}
																					style={{width:'100%',height:'100%'}}>
																					{({ getRootProps, getInputProps }) => (
																						<div {...getRootProps({ className: "dropzone" })}>
																								<input {...getInputProps()} />
																								<p>{ETVConstants.trans("click_to_upload_files_max_5mb")}</p>
																						</div>
																					)}
																			</Dropzone>
																</div>
																<br/>
																<div>
																	{this.state.bg_image_url?<Image key={"bg_image"} src={this.state.bg_image_url} style={{ aspectRatio: 1.33 }} />:<span/>}
																</div>
																<br/>
																<div><TextField error={this.state.validating && !this.inputOK(this.state.new_title)}
																								helperText={!this.state.validating || this.inputOK(this.state.new_title)?"":"required field"}
																								required
																								key='new_title'
																								label={ETVConstants.trans("title_max_15_chars")}
																								inputProps={{ maxLength: 15 }}
																								fullWidth={true}
																								value={this.state.new_title}
																								onChange={(event)=>{this.setState({new_title:event.target.value})}} />
																</div>
																<br/>
																<div><TextField error={this.state.validating && !this.inputOK(this.state.new_desc)}
																											helperText={!this.state.validating || this.inputOK(this.state.new_desc)?"":ETVConstants.trans("required_field")}
																											required
																											multiline
 																											minRows={3}
 																											maxRows={3}
																											variant="outlined"
																											key='new_desc'
																											label={ETVConstants.trans("desc_max_60_chars")}
																											inputProps={{ maxLength: 60 }}
																											fullWidth={true}
																											value={this.state.new_desc}
																											onChange={(event)=>{this.setState({new_desc:event.target.value})}} />
																</div>
													</td>
													<td>
														<br/><div><TextField	key='new_terms'
																									multiline
																									minRows={4}
																									variant='outlined'
																									label={ETVConstants.trans("terms_conditions")}
																									fullWidth={true}
																									value={this.state.new_terms}
																									onChange={(event)=>{this.setState({new_terms:event.target.value})}} />
																	</div>
														<br/><div><FormControl>
																<InputLabel>{ETVConstants.trans("total_number_coupons")}</InputLabel>
																<Select
																		 labelId="max_vouchers"
																		 id="max_vouchers"
																		 style={{ width: 300}}
																		 value={this.state.max_available_vouchers}
																		 onChange={(e)=>{this.setState({max_available_vouchers:e.target.value})}}>
																			<MenuItem value='20'>20</MenuItem>
																			<MenuItem value='50'>50</MenuItem>
																			<MenuItem value='100'>100</MenuItem>
																			<MenuItem value='200'>200</MenuItem>
																			<MenuItem value='500'>500</MenuItem>
																			<MenuItem value='1000'>1000</MenuItem>
																			<MenuItem value='5000'>5000</MenuItem>
																</Select>
														</FormControl>
														</div>
														<br/><div><FormControl>
																<InputLabel>{ETVConstants.trans("max_coupons_per_day")}</InputLabel>
																<Select
																		 labelId="max_vouchers_per_day"
																		 id="max_vouchers_per_day"
																		 style={{ width: 300}}
																		 value={this.state.max_vouchers_per_day}
																		 onChange={(e)=>{this.setState({max_vouchers_per_day:e.target.value})}}>
																			<MenuItem value='10'>10</MenuItem>
																			<MenuItem value='20'>20</MenuItem>
																			<MenuItem value='50'>50</MenuItem>
																			<MenuItem value='100'>100</MenuItem>
																			<MenuItem value='250'>250</MenuItem>
																			<MenuItem value='500'>500</MenuItem>
																			<MenuItem value='1000'>1000</MenuItem>
																			<MenuItem value='5000'>5000</MenuItem>
																			<MenuItem value='-1'>unlimited</MenuItem>
																</Select>
														</FormControl>
														</div>
														<br/><div><FormControl>
																<InputLabel>{ETVConstants.trans("max_coupons_per_user")}</InputLabel>
																<Select
																		 labelId="max_vouchers_per_user"
																		 id="max_vouchers_per_user"
																		 style={{ width: 300}}
																		 value={this.state.max_vouchers_per_user}
																		 onChange={(e)=>{this.setState({max_vouchers_per_user:e.target.value})}}>
																			<MenuItem value='1'>1</MenuItem>
																			<MenuItem value='2'>2</MenuItem>
																			<MenuItem value='3'>3</MenuItem>
																			<MenuItem value='5'>5</MenuItem>
																			<MenuItem value='10'>10</MenuItem>
																			<MenuItem value='-1'>{ETVConstants.trans("no_limit")}</MenuItem>
																</Select>
														</FormControl>
														</div>
														<br/><div><FormControl>
																<InputLabel>{ETVConstants.trans("coupon_expiry_after_distribution")}</InputLabel>
																<Select
																		 labelId="validity_hours"
																		 id="validity_hours_id"
																		 style={{ width: 300}}
																		 value={this.state.validity_hours}
																		 onChange={(e)=>{this.setState({validity_hours:e.target.value})}}>
																			<MenuItem value='1'>{ETVConstants.trans("1h")}</MenuItem>
																			<MenuItem value='2'>{ETVConstants.trans("2h")}</MenuItem>
																			<MenuItem value='4'>{ETVConstants.trans("4h")}</MenuItem>
																			<MenuItem value='8'>{ETVConstants.trans("8h")}</MenuItem>
																			<MenuItem value='24'>{ETVConstants.trans("1_day")}</MenuItem>
																			<MenuItem value='48'>{ETVConstants.trans("2_days")}</MenuItem>
																			<MenuItem value='168'>{ETVConstants.trans("1_week")}</MenuItem>
																			<MenuItem value='672'>{ETVConstants.trans("4_weeks")}</MenuItem>
																			<MenuItem value='-1'>{ETVConstants.trans("no_expiry_date")}</MenuItem>
																</Select>
														</FormControl>
														</div>
														<MuiPickersUtilsProvider utils={DateFnsUtils}>
																	<KeyboardDatePicker
																			 margin="normal"
																			 required
																			 error={this.state.validating && !this.state.validity_date}
																			 helperText={this.state.validating && !this.state.validity_date?ETVConstants.trans("required_field"):""}
																			 id="date-picker-dialog"
																			 label={ETVConstants.trans("validity_date")}
																			 format='yyyy-MM-dd'
																			 value={this.state.validity_date?this.state.validity_date:null}
																			 onChange={this.setValidityDate }
																			 KeyboardButtonProps={{
																				 'aria-label': 'change date',
																			 }}
																	 />
														</MuiPickersUtilsProvider>
														<div><TextField error={this.state.validating && !this.inputOK(this.state.terminal_id)}
																						helperText={!this.state.validating || this.inputOK(this.state.terminal_id)?"":ETVConstants.trans("required_field")}
																						required
																						key='terminal_id'
																						label={ETVConstants.trans("coupon_validation_terminal_code")}
																						fullWidth={true}
																						value={this.state.terminal_id}
																						onChange={(event)=>{this.setState({terminal_id:event.target.value})}} />
														</div>
														<br/>
														<div>
																				<FormControl>
																						<InputLabel>{ETVConstants.trans("channel")}</InputLabel>
																						<Select
																								 labelId="code"
																								 id="code_format"
																								 style={{ width: 250}}
																								 value={this.state.selected_channel}
																								 onChange={(e)=>{this.setState({selected_channel:e.target.value})}}>
																									<MenuItem value='0'>{ETVConstants.trans("channel")} 0</MenuItem>
																									<MenuItem value='1'>{ETVConstants.trans("channel")} 1</MenuItem>
																									<MenuItem value='2'>{ETVConstants.trans("channel")} 2</MenuItem>
																									<MenuItem value='3'>{ETVConstants.trans("channel")} 3</MenuItem>
																									<MenuItem value='4'>{ETVConstants.trans("channel")} 4</MenuItem>
																						</Select>
																				</FormControl>
																</div>
																<br/>
																<div>
																	<Button color="primary" variant='contained' onClick={() => this.showWindow('select_screens_div',true)}>{ETVConstants.trans("select_screens")} ({this.state.selected_screens.length})</Button>
																</div>
															</td>
														</tr>
													</tbody>
											</table>
								</DialogContent>
								<DialogActions>
										<Button color="primary" onClick={() => this.showWindow('create_new_voucher_div',false)}>{ETVConstants.trans("close")}</Button>
										<Button color="secondary" disabled={!this.validateInputs()}  variant='contained' color='secondary' onClick={() => this.registerNewVoucher()}>{ETVConstants.trans("create_new_coupon")}</Button>
								</DialogActions>
						</Dialog>

						<Dialog
								open={this.state.window==='analyze_voucher_div'}
								maxWidth='md'
								fullWidth={true}
								onClose={()=>{}}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description">
								<DialogTitle id="alert-dialog-title">{ETVConstants.trans("analyze_coupon")}</DialogTitle>
								<DialogContent>
										<Chart
											width={600}
											height={400}
											chartType="BarChart"
											loader={<div>{ETVConstants.trans("loading")}</div>}
											data={this.state.statistics_data}
											options={{
												title: this.props.chart_title,
												chartArea: { width: '50%' },
												hAxis: {
													title: '',
													minValue: 0,
												},
												vAxis: {
													title: '',
													minValue: 0,
												},
											}}
											legendToggle
										/>
								</DialogContent>
								<DialogActions>
										<Button color="primary" onClick={() => this.showWindow('analyze_voucher_div',false)}>{ETVConstants.trans("close")}</Button>
								</DialogActions>
						</Dialog>

						<div id='select_screens_div' style={{display:this.state.window==='select_screens_div'?'block':'none'}}>
	                <SelectAvailableScreensNew isOpen={this.state.window==='select_screens_div'} user_id={this.props.user_id} closeHook={this.closeScreenSelection} selectedScreens={this.selectedScreens} selected={this.state.selected_screens?this.state.selected_screens:[]}/>
	          </div>

		   	</div>
			);
	}
}

export default VoucherMgmt
