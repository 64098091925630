import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Dropzone from "react-dropzone";
import {Card,Button,Divider,TextField} from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import CardMedia from '@material-ui/core/CardMedia';
import {  MuiPickersUtilsProvider,  KeyboardTimePicker,  KeyboardDatePicker,} from '@material-ui/pickers';
import CheckBoxList2 from '../tools/CheckBoxList2';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import BasicTable2 from "../tools/BasicTable2";
import IconButton from '@material-ui/core/IconButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import EditIcon from '@material-ui/icons/Edit';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import ImageIcon from '@material-ui/icons/Image';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import ApartmentIcon from '@material-ui/icons/Apartment';
import InfoMessage2 from '../tools/InfoMessage2';
import * as ETVConstants from '../ETVConstants';

class EditFloorNames extends React.Component {

	constructor(props) {
      super(props);
    }

    state = {
			ioProps:undefined,
      ioCodes:[],
			code_type:'gray',
			bits:'6',
			info_message:"",
			info_title:"",
    }

    componentDidMount() {
      this.loadItems();
    }

    loadItems() {
			var url = ETVConstants.getServerBase()+"/elevator_config?device_id="+this.props.device_id;
      fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("loaded Items="+JSON.stringify(result));
						var codeType = 'gray';
						if(result['code_type']) {
							 codeType = result['code_type'];
						}
            this.setState({
							ioProps:result,
							code_type:codeType,
              isLoaded: true,
            });
						// this will create the codes
						this.updateCodeType(codeType,false);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              ioProps: undefined,
              isLoaded: false,
              error
            });
          }
        )
    }

    showWindow(divID, show) {
      this.setState({show_overlay:show});
      { show?this.setState({window:divID}):this.setState({window:''}) };
    }

    getItem( deviceID) {
      for(var i=0; i<this.state.items.length; i++) {
        if(this.state.items[i].id===deviceID) {
          return this.state.items[i];
        }
      }
      return undefined;
    }

		setFloorValue( key, value) {
			//console.log("setting key="+key+" value="+value);
			var props = this.state.ioProps;
			props[key]=value;
			this.setState({ioProps:props});
		}

		createIOItem( code,index) {
			//console.log("create IO Item, code="+code);
			if(this.state.ioProps) {
				var curVal = this.state.ioProps[code];
				//console.log("currentVal="+curVal);
			}
			var code1 ="";
			if(index<this.state.ioCodes.length-1) {
				code1 = this.state.ioCodes[index+1]
				//console.log("code["+index+"]="+code+" code["+(index+1)+"]="+code1);
			}

			return <tr key={"tr_"+code}>
								<td><TextField size='small' key={code} label="i/o code" variant="outlined" disabled={true} value={code} /></td>
								{this.state.ioProps?
										<span>
											<td><TextField size='small' key={code} label="" variant="outlined" label='floor name' value={this.state.ioProps[code]} onChange={(e)=>{this.setFloorValue(code,e.target.value)}} /></td>
											{index===(this.state.ioCodes.length-1)?<td/>:<td><TextField size='small' key={code} label="" variant="outlined" type='number' label='floor distance' value={this.state.ioProps["dist_"+code+"_"+code1]} onChange={(e)=>{this.setFloorValue("dist_"+code+"_"+code1,e.target.value)}} /></td>}
										</span>
										:
										<td/>
								}
						 </tr>
		}

		// see: https://www.geeksforgeeks.org/generate-n-bit-gray-codes/
		generateGrayCode( n) {
	    var arr = [];
			arr.push("0");
    	arr.push("1");
	    var i, j;
	    for (i = 2; i < (1<<n); i = i<<1) {
	        for (j = i-1 ; j >= 0 ; j--)
	            arr.push(arr[j]);

	        for (j = 0 ; j < i ; j++)
	            arr[j] = "0" + arr[j];

	        for (j = i ; j < 2*i ; j++)
	            arr[j] = "1" + arr[j];
	    }
	    //for (i = 0 ; i < arr.length ; i++ ) {
	       // console.log(arr[i]);
			//}
			return arr;
		}

		generateCanOpenLiftCodes( n) {
			var arr = [];
			for(var i=0; i<n; i++) {
				arr.push(i)
			}
			return arr;
		}

		updateCodeType( code, reset) {
			if (code==='gray') {
				var arr = this.generateGrayCode(this.state.bits);

			}else if(code==='binary'){
				var arr = this.generateBinaryCode(this.state.bits);

			}else if(code==='bit_per_floor'){
				var arr = this.generateBitPerFloorCode(10);

			}else if(code==='can_open_lift') {
				var arr = this.generateCanOpenLiftCodes(20);
			}

			var props = this.state.ioProps;
			if(reset) {
				props = {};
			}
			if (props) {
				props["code_type"]=code;
				arr.map( function(code,index) {
					var curVal = props[code];
					//console.log("code="+code+" this.state.ioProps[code]="+curVal)
					props[code]=curVal?curVal:index;
				});
			}
			this.setState({ioCodes:arr, code_type:code, ioProps:props});
		}

		generateBinaryCode( bits) {
			 var arr = [];
			 var maxNo = Math.pow(2,bits);
			 //console.log("generate Binary code, maxNo="+maxNo);
			 for(var i=0; i<maxNo; i++) {
				 arr[i] = this.fillZeros(bits,i.toString(2));
			 }
			 return arr;
		}

		reverseString(s){
		    return s.split("").reverse().join("");
		}

		generateBitPerFloorCode( totalFloors) {
			 var arr = [];
			 for(var whichFloor=0; whichFloor<totalFloors; whichFloor++) {
				 var s = this.createZeros(totalFloors);
				 var before = s.substring(0,whichFloor);
				 var after = s.substring(whichFloor+1,s.length);
				 s = before + "1" + after;
				 arr[whichFloor] = this.reverseString(s);
			 }
			 return arr;
		}

		createZeros(nof) {
			var s ="";
			for(var i=0; i<nof; i++) {
				s = "0"+s;
			}
			return s;
		}

		fillZeros( bits, code) {
			var nof = Math.max(0,bits - code.length);
			for(var i=0; i<nof; i++) {
				code = "0"+code;
			}
			return code;
		}


		showInfoMessage(title,msg) {
			this.showWindow('message_div',true);
			this.setState({info_title:title, info_message:msg});
		}

		closeInfoMessage() {
			this.setState({info_message:undefined});
			this.showWindow('message_div',false);
		}

		saveMapping() {
			this.showInfoMessage("Floor Mapping","saving...");
			//console.log("---------------------save mapping");
		  var configJSON = JSON.stringify(this.state.ioProps);
			console.log(configJSON);
			var url = ETVConstants.getServerBase()+"/elevator_config?action=save_floor_mapping&device_id="+this.props.device_id+"&config_json="+encodeURIComponent(configJSON);
			fetch(url)
				.then(res => res.json())
				.then(
					(result) => {
						//console.log("result="+JSON.stringify(result, null, 2));
						this.showInfoMessage("Floor Mapping","success");
						//console.log("xxxxxxxxxxxxxx="+result["fileurl"]);
					},
					// Note: it's important to handle errors here
					// instead of a catch() block so that we don't swallow
					// exceptions from actual bugs in components.
					(error) => {
						this.setState({
							ioProps: undefined,
							isLoaded: false,
							error
						});
					}
				)
		}

    render() {

      return(
				<div>
						<Typography variant="h6">
							DeviceID: {this.props.device_id}
						</Typography>
	          <table style={{marginTop:15}}>
							<tbody>
								<tr><td>
										<FormControl>
											 <InputLabel>Code</InputLabel>
											 <Select
												 labelId="code"
												 id="code_format"
												 style={{ width: 250}}
												 value={this.state.code_type}
												 onChange={(e)=>{this.updateCodeType(e.target.value,true)}}>
													<MenuItem value='gray'>Gray Code</MenuItem>
													<MenuItem value='binary'>Binary Code</MenuItem>
													<MenuItem value='bit_per_floor'>Bit per Floor</MenuItem>
													<MenuItem value='can_open_lift'>Can Open Lift</MenuItem>
											 </Select>
										 </FormControl>
										</td>
										<td>
											<Button size='small' variant="contained" color="secondary" onClick={() => this.saveMapping()}>Save</Button>
										</td>
								 </tr>
							</tbody>
						</table>
						<table style={{marginTop:15}}>
							<tbody>
								{this.state.ioCodes.map( (code,index) => { return this.createIOItem(code,index)})}
							</tbody>
						</table>
						<div id='overlay_gray' style={{display: this.state.show_overlay === true ? 'block' : 'none'}}>
	               <div id='message_div' style={{display:this.state.window==='message_div'?'block':'none'}}>
	                   <Card>
	                       <CardContent>
	                         <InfoMessage2 title={this.state.info_title} message={this.state.info_message} />
	                       </CardContent>
	                       <CardActions>
	                         <Button color="primary" onClick={() => this.closeInfoMessage()}>Close</Button>
	                       </CardActions>
	                   </Card>
	               </div>
	          </div>
		   	</div>
			);
	}
}

export default EditFloorNames
