import React from 'react';
import {Button,TextField} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import BasicTable2 from "../tools/BasicTable2";
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as ETVConstants from '../ETVConstants';

class SecurityMgmtIPCamera extends React.Component {

    state = {
      items:[],
      isLoaded: false,
			window:'',
			info_title:'',
			info_message:'',
			selected_device_id:'',
			ip_rtsp_stream:'',
			cam_ip:'',
			cam_user:'',
			cam_pw:'',
			show_password:true
    }

		componentDidUpdate() {
      if(!this.state.isLoaded) {
        this.loadItems();
      }
		}

    componentDidMount() {
      this.loadItems();
    }

    loadItems() {
      //console.log("---------------------loading screens");
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=list&owner_id="+this.props.user_id;
      //console.log("loading url="+url);
      fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
						//console.log(JSON.stringify(result));
            //console.log("items="+result.list.length);
            this.setState({
              items: result.list,
              isLoaded: true,
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              items:[],
              isLoaded: false,
              error
            });
          }
        )
    }

    showWindow(divID, show) {
      show?this.setState({window:divID}):this.setState({window:''});
    }

		getLastConnectSymbol(time, startup) {
			var d = new Date(time.replace(/-/g, "/"));
			var diffMins = this.getLastConnectMins(time);
			var msg = d.toLocaleString();
			if(startup && startup.trim().length>0) {
				var startupDt = new Date(startup);
				msg = Math.round(diffMins)+" min ago (login: "+startupDt.toLocaleString()+")";
			}

			if(diffMins<15) {
					return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: 'lightgreen' }}  /></Tooltip>
			}else if(diffMins<120) {
					return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: 'yellow' }}  /></Tooltip>
			}else{
				 return <Tooltip title={msg} ><FiberManualRecordIcon size='large' style={{ color: 'red' }}  /></Tooltip>
			}
		}

		getLastConnectMins( time) {
			// careful on IOS! --> use ".replace(..)"
			var d = new Date(time.replace(/-/g, "/"));
			var dNow = Date.now();
			var diffMins = (dNow - d)/60000;
			return diffMins;
		}

		showInfoMessage(title,msg) {
			this.showWindow('message_div',true);
			this.setState({info_title:title, info_message:msg});
		}

		closeInfoMessage() {
			this.setState({info_message:undefined});
			this.showWindow('message_div',false);
		}

		liveStream(rtsp) {
			this.setState({ip_rtsp_stream:rtsp});
			this.showWindow("camera_view_div",true);
		}

		ipCamSettings(id, rtsp) {
			//console.log("ipcamsettings, id="+id+" rtsp="+rtsp);
			this.setState({selected_device_id:id, ip_rtsp_stream:rtsp});
			this.showWindow("ip_cam_settings_div",true);
			this.getInfoFromRTSPUrl(rtsp);
		}

		getInfoFromRTSPUrl( rtsp) {
			var temp = rtsp;
			var RTSP = "rtsp://";
			temp = temp.substring(RTSP.length);
			//console.log("temp="+temp);
			var cam_user = temp.substring(0,temp.indexOf(":"));
			var cam_pw = temp.substring(temp.indexOf(":")+1,temp.indexOf("@"));
			var ip = temp.substring(temp.indexOf("@")+1);
			//console.log("camUser="+cam_user+" camPw="+cam_pw+" ip="+ip);
			this.setState({cam_ip:ip,cam_user:cam_user,cam_pw:cam_pw,show_password:false});
			//console.log("camUser="+cam_user);
		}

		componentDidCatch(error, errorInfo) {
			// You can also log the error to an error reporting service
			console.log("error="+error+" /info="+JSON.stringify(errorInfo));
		}

		getIPLink() {
				var link = "rtsp://"+this.state.cam_user+":"+this.state.cam_pw+"@"+this.state.cam_ip;
				//console.log(link);
				return link;
		}

		saveIPCam() {
	      var url = ETVConstants.getServerBase()+"/device_mgmt?action=save_ip_cam&device_id="+this.state.selected_device_id+"&link="+encodeURIComponent(this.getIPLink());
	      var accessToken = localStorage.getItem("etv.access_token");
	      var reqUserID = localStorage.getItem("etv.user_id");
	      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
	        .then(res => res.json())
	        .then(
	          (result) => {
							//console.log(JSON.stringify(result));
	            this.setState({
	              cam_user:'',
								cam_pw:'',
								show_password:false,
								can_ip:'',
								isLoaded:false
	            });
	            this.closeInfoMessage();
	          },
	          (error) => {
	            this.showInfoMessage("error happened: "+error);
	          }
	        )
    }

    render() {
      const columnsData= [
					{ title: 'Last connect', field: 'last_connected', render: rowData => (<div style={{width: "15px", maxWidth:"30px", paddingLeft: "3px"}}> {this.getLastConnectSymbol(rowData.last_connected,rowData.startup_datetime)} </div>) },
					{ title: 'Device Name', field: 'device_name', render: rowData => (<div style={{minWidth: "200px", paddingLeft: "0px"}}> {rowData.device_name} </div>) },
          { title: 'Location', field: 'location_name'},
          { title: 'Address', field: 'address'},
          { title: 'Zip', field: 'zip'},
          { title: 'City', field: 'city'},
					{ title: 'Settings', field: '', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.ipCamSettings(rowData.id, rowData.ip_cam_rtsp)}><SettingsIcon/></IconButton>},
					//{ title: 'Live Stream', field: '', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.liveStream(rowData.ip_cam_rtsp)}><VideocamIcon/></IconButton>},
        ];

      const tabletitle = "Cameras";
      return(
				<div>
	          <BasicTable2 data={this.state.items} columns={columnsData} title={tabletitle} pageSize={10} allow_search={true} />

						<Dialog
			        open={this.state.window==='camera_view_div'}
			        onClose={()=>{this.setState({window:'',})}}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description">
			        <DialogTitle id="alert-dialog-title">Live Stream</DialogTitle>
			        <DialogContent>
									{this.state.ip_rtsp_stream}
			        </DialogContent>
			        <DialogActions>
			          <Button onClick={()=>{this.setState({window:'',})}} color="primary">{ETVConstants.trans("close")}</Button>
			        </DialogActions>
			      </Dialog>

						<Dialog
			        open={this.state.window==='ip_cam_settings_div'}
              maxWidth='xs'
			        onClose={()=>{}}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description">
			        <DialogTitle id="alert-dialog-title">IP Cam Settings</DialogTitle>
			        <DialogContent>
									<TextField key='key_cam_ip'
														variant='standard'
														label={ETVConstants.trans("cam_ip")}
														fullWidth={true}
														value={this.state.cam_ip}
														InputProps={{startAdornment:<InputAdornment position='start'>rtsp://</InputAdornment>}}
														onChange={(event)=>{this.setState({cam_ip:event.target.value})}} />
									<TextField style={{marginTop:20}}
														key='key_cam_user'
														variant='standard'
														label={ETVConstants.trans("cam_user")}
														fullWidth={true}
														value={this.state.cam_user}
														onChange={(event)=>{this.setState({cam_user:event.target.value})}} />
									<TextField style={{marginTop:20}}
														key='key_cam_pw'
														type={this.state.show_password?"text":"password"}
														variant='standard'
														label={ETVConstants.trans("cam_password")}
														fullWidth={false}
														value={this.state.cam_pw}
														onChange={(event)=>{this.setState({cam_pw:event.target.value})}}
														InputProps={{
            										endAdornment: <InputAdornment position="end">
																			<IconButton
																					aria-label={ETVConstants.trans("show_password")}
																					onClick={(event)=>{this.setState({show_password:this.state.show_password?false:true})}}
																					edge="end">
																						{this.state.show_password ? <VisibilityOff/> : <Visibility />}
																			 </IconButton>
																</InputAdornment>,
          									}}
									 />
			        </DialogContent>
			        <DialogActions>
			          <Button onClick={()=>{this.setState({window:'',})}} size="small" color="primary">{ETVConstants.trans("close")}</Button>
                <Button onClick={()=>{this.saveIPCam()}} variant='contained' size="small" color="secondary">{ETVConstants.trans("save")}</Button>
			        </DialogActions>
			      </Dialog>

						<Dialog
			        open={this.state.window==='message_div'}
			        onClose={()=>{this.setState({window:'',})}}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description">
			        <DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
			        <DialogContent>
			          <DialogContentText id="alert-dialog-description">
			            <Typography variant='body1'>{this.state.info_message}</Typography>
			          </DialogContentText>
			        </DialogContent>
			        <DialogActions>
			          <Button onClick={()=>{this.setState({window:'',})}} color="primary">
			            Close
			          </Button>
			        </DialogActions>
			      </Dialog>

		   	</div>
			);
	}
}

export default SecurityMgmtIPCamera
