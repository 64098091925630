import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import * as ETVConstants from '../ETVConstants';

class SelectDeviceDropDown extends React.Component {

    state = {
      devices:[],
			groups:[],
			select_group_devices:[], //object of devices with groups
      isLoaded: false,
      selected_screens:[]
    }

		getGroupName( deviceID, groups) {
			for(var i=0; i<groups.length; i++) {
				if(groups[i]["device_ids"].includes(deviceID)) return groups[i].group_name;
			}
			return "-- no group --";
		}

		createGroupDevices(devices,groups) {
			var result = [];
			for(var i=0; i<groups.length; i++) {
				var obj = {};
				obj["id"]="_group_"+groups[i].group_id;
				obj["device_name"]=groups[i].group_name;
				obj["group_name"]="groups";
				result.push(obj);
			}
			for(var j=0; j<devices.length; j++) {
				var obj2 = {};
				obj2["id"]=devices[j].id;
				obj2["device_name"]=devices[j].device_name;
				obj2["group_name"]="devices";
				result.push(obj2);
			}
			result.sort( (a, b) => a.group_name >= b.group_name ? 1 : -1);
			return result;
		}

    componentDidMount() {
      this.loadData();
    }

		handleDeviceChangeNew = (e,value) => {
			if(!value) {
				//console.log("no value chosen");
				return;
			}
			if(this.isGroup(value)) {
				//console.log("group "+value+" chosen");
				var groupStr = value.id;
				var groupID = this.getGroupID(groupStr);
				//console.log("----->groupID="+groupID);
				var group = this.getGroup( groupID);
				//console.log("group="+JSON.stringify(group));
				this.props.devicesSelected(group.device_ids);
			}else{
				this.props.deviceSelected(value);
			}
		}

		isGroup( val) {
			return val.id.startsWith("_group_");
		}

		getGroup( groupID) {
			for(var i=0; i<this.state.groups.length; i++) {
				if(this.state.groups[i].group_id===groupID) {
					return this.state.groups[i];
				}
			}
			return null;
		}

		getGroupID( groupStr) {
			if(groupStr.indexOf("_group_")!==0) {
				return -1;
			}
			return parseInt(groupStr.substring("_group_".length));
		}

    loadData() {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=list&owner_id="+this.props.user_id;
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            var groups = ETVConstants.filterOutEmptyGroups(result.groups);
						//console.log("xxxx groups="+JSON.stringify(groups));
            var devices = result.list;
            this.setState({
              isLoaded: true,
              devices: devices,
							groups: groups,
							select_group_devices:this.createGroupDevices(devices,groups)
            });
          },
          (error) => {
            this.setState({
              isLoaded: false,
              error
            });
          }
        )
    }

    render() {
      return(
        <div>
						<Autocomplete
							id="select screen"
							options={this.state.select_group_devices}
							groupBy={(option) => option.group_name}
							getOptionLabel={(option) => option.device_name}
							style={{ width: 300, marginTop:20 }}
							//onChange={(e,value)=>{if(value)this.setState({edit_device_id:value.id})}}
							onChange={this.handleDeviceChangeNew}
							renderInput={(params) => <TextField {...params} label="select device" variant="outlined" />}
						/>
        </div>
	 		);
	}
}

export default SelectDeviceDropDown;
