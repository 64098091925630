import React from 'react';
import BasicTable2 from '../tools/BasicTable2';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Delete from '@material-ui/icons/Delete'
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Face} from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import {MuiPickersUtilsProvider,TimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SkillsDefinition from './SkillsDefinition';
import BuildIcon from '@material-ui/icons/Build';
import * as ETVConstants from '../ETVConstants';

class ServiceTechnician extends React.Component {

	constructor(props) {
      super(props);
	    this.insertUser = this.insertUser.bind(this);
			this.closeScreenSelection = this.closeScreenSelection.bind(this);
			this.closeStatusSetting = this.closeStatusSetting.bind(this);
    }

    state = {
      isLoaded: false,
      items: [],
			elevators:[], // list of objects
			selected_elevators:[], //list of ids
			need_save_assignments:false,
			window:undefined,
			changePasswordValue:'',
			change_pw_helper_ok:true,
			new_user_email: '',
			new_user_firstname: '',
			new_user_lastname: '',
			new_user_pw1:'',
			new_user_pw2:'',
			info_message:'',
			info_message_title:'Info',
			all_roles:[],
			new_user_email_helper_ok:true,
			new_user_pw_helper_ok:true,
			new_user_pw2_helper_ok:true,
			selected_user: undefined,
			showDeleteUserDialog: false,
			deleteUserID: undefined,
			otp_phone:'',
			otp_phone_helper_ok:true,
			otp_enabled:false,
			use_secure_pw:false,
			change_userid_value:'',
			change_userid_helper_ok:true,
			selected_new_parent:-2,
			root_user_id:-1,
			root_user_name:'',
			chip_values: [],
			chips_changed:false,
			user_skills_changed:false,
			selected_user_skills:[],
			selected_work_shift:{},
			work_shift_changed:false,
			shift1_start:'',
			shift1_end:'',
			shift2_start:'',
			shift2_end:'',
			shifts_changed:false,
			has_second_shift:true,
    }

    handleUsers(items) {
      var data = [];
      for(var i=0; i<items.length; i++) {
        var item = items[i];
        item["row_id"]=item["user_id"];
        data.push(item);
      }
      return data;
    }

    componentDidMount() {
			this.loadUsers();
			this.loadAvailableSkills();
			this.loadElevators();
    }

		componentDidUpdate() {
			if(!this.state.isLoaded) {
				this.loadUsers();
				this.loadAvailableSkills();
				this.loadElevators();
			}
		}

		loadElevators() {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=list_all_devices&owner_id="+this.props.user_id;
			url += "&device_type=0&device_type=1&device_type=2";
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("items="+result.list.length);
            this.setState({
              elevators: result.list,
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              elevators:[],
              error
            });
          }
        )
    }

		loadAvailableSkills() {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var urlStr = ETVConstants.getServerBase()+"/useradmin?action=get_skills&user_id="+this.props.user_id;
      fetch(urlStr,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						//console.log("userID="+this.props.user_id+" skills available="+JSON.stringify(result));
            this.setState({
              chip_values: result.skills,
            });
          },
          (error) => {
            this.setState({
              chip_values: [],
              error
            });
          }
        )
    }

    loadUsers() {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var urlStr = ETVConstants.getServerBase()+"/emergency?action=list_service_technicians&user_id="+this.props.user_id;
      fetch(urlStr,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						//console.log("users="+JSON.stringify(result));
            var rx = this.handleUsers(result.children);
            this.setState({
              isLoaded: true,
              items: rx,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }

		// Add Chips
		handleAddChip = (chip) => {
			var curChips = this.state.chip_values;
			curChips.push(chip);
			this.setState({chip_values:curChips,chips_changed:true});
  	}
		// Delete Chips
		handleDeleteChip = (chip) => {
				var curChips = this.state.chip_values;
				var index = curChips.indexOf(chip);
				if(index>-1) {
					curChips.splice(index,1);
				}
				this.setState({chip_values:curChips,chips_changed:true});
  	}

		sendCMDToServer(url) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then((response) => response.json())
				.then((responseJson) => {
					 this.setState({
							isLoaded: false
					 })
				})
				.catch((error) => {
					 console.error(error);
				});
    }

    blockUser( userID, value) {
			var url = ETVConstants.getServerBase()+'/useradmin?user_id='+userID;
			if(value) {
				url += "&action=block";
			}else{
				url += "&action=unblock";
			}
			this.sendCMDToServer(url);
		}

    deleteUser( userID) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/useradmin?action=delete&user_id="+userID;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then((response) => response.json())
			.then((responseJson) => {
						if (!responseJson["result"]) {
							if(responseJson["reason"]==='has_children') {
								this.showInfoMessage(ETVConstants.trans("error"), ETVConstants.trans("can_not_delete_a_user_who_has_children"));
							}else{
								this.showInfoMessage(ETVConstants.trans("error"),  responseJson['reason']);
							}
						}else{
							this.setState({
									isLoaded: false, showDeleteUserDialog:false,
							})
						}
			})
			.catch((error) => {
				 console.error(error);
			});
	  }

    insertUser() {
			//console.log("insert user");
      var username = this.state.new_user_email.trim();
			if(!ETVConstants.isEmail(username)) {
				this.setState({new_user_email_helper_ok:false});
				return;
			}
			var pw = this.state.new_user_pw1.trim();
			if(pw.length<6) {
				this.setState({new_user_pw_helper_ok:false});
				return;
			}
			var pw2 = this.state.new_user_pw2.trim();
			if(pw2!==pw) {
				this.setState({new_user_pw2_helper_ok:false});
				return;
			}
      var firstname = this.state.new_user_firstname.trim();
      var lastname = this.state.new_user_lastname.trim();
      var parentID = this.props.user_id;
      this.addServiceTechnician(username,firstname,lastname,pw,parentID );
			this.showWindow('add_user',false);
    }

    addServiceTechnician( username, firstname, lastname, password, parentID) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
      //console.log("adding user id="+username+"/firstname="+firstname+" last="+lastname+" pw="+password+" parentID="+parentID);
			var url = ETVConstants.getServerBase()+'/useradmin?action=add_user&username='+encodeURIComponent(username)+'&parent_id='+parentID+'&firstname='+encodeURIComponent(firstname)+'&lastname='+encodeURIComponent(lastname)+'&password='+encodeURIComponent(password)+"&status=service_technician";
			//console.log("--------------->url="+url);
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
      .then((response) => response.json())
      .then((result) => {
					if(result["error"]) {
						this.showInfoMessage(ETVConstants.trans("error"),ETVConstants.trans("user_exists_already"));
						return;
					}
         	this.setState({
            isLoaded: false,
						new_user_email:'',
						new_user_firstname:'',
						new_user_lastname:'',
						new_user_pw1:'',
						new_user_pw2:'',
         });
      })
      .catch((error) => {
         this.showInfoMessage(ETVConstants.trans("error"),ETVConstants.trans("internal_error_happend"));
      });
    }

		activateUser( userID) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+'/useradmin?action=activate_user_id&user_id='+userID;
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then((response) => response.json())
			.then((responseJson) => {
				 this.setState({
						isLoaded: false,
				 })
			})
			.catch((error) => {
				 //console.log("Error="+error);
			});
		}

		showRoles(userID) {
			this.setState( state => ({
				selected_user:userID,
			}));
			this.showWindow('show_roles',true);
		}

		pwChange(userID) {
			this.setState( state => ({
				selected_user:userID,
			}));
			this.showWindow('show_pw_change',true);
		}

		userIDChange(user) {
			this.setState({selected_user:user.user_id,change_userid_value:user.user_name});
			this.showWindow('show_userid_change',true);
		}

		submitNewPW(userID) {
			var newPW = this.state.changePasswordValue;
			if(!newPW || newPW.trim().length<6) {
				this.setState({change_pw_helper_ok:false});
				return;
			}

			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+'/useradmin?action=change_password&user_id='+userID+"&password="+encodeURIComponent(newPW);
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then((response) => response.json())
			.then((responseJson) => {
				 //console.log(JSON.stringify(responseJson));
				 if(responseJson["result"]===true) {
					 	this.setState({changePasswordValue:''});
						this.showInfoMessage(ETVConstants.trans("success"),ETVConstants.trans("password_successfully_changed"));
				 }else{
					 	this.setState({changePasswordValue:''});
						this.showInfoMessage(ETVConstants.trans("error"),ETVConstants.trans("could_not_update_password"));
				 }
			})
			.catch((error) => {
				 //console.log("Error="+error);
			});
		}

		submitNewUserID(userID) {
			var newUserID = this.state.change_userid_value;
			//console.log("check submit new userID, userID="+userID+" /new:"+newUserID);
			if(!newUserID || !ETVConstants.isEmail(newUserID)) {
				this.setState({change_userid_helper_ok:false});
				return;
			}
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+'/useradmin?action=change_user_id&user_id='+userID+"&new_user_id="+encodeURIComponent(newUserID);
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then((response) => response.json())
			.then((responseJson) => {
				 //console.log(JSON.stringify(responseJson));
				 if(responseJson["result"]===true) {
						this.setState({change_userid_value:'',isLoaded:false});
						this.showInfoMessage(ETVConstants.trans("success"),ETVConstants.trans("user_id_successfully_changed"));
				 }else{
						this.setState({change_userid_value:''});
						this.showInfoMessage(ETVConstants.trans("error"),ETVConstants.trans("could_not_update_user_id"));
				 }
			})
			.catch((error) => {
				 //console.log("Error="+error);
			});
		}

		showWindow(divID, show) {
			show?this.setState({window:divID}):this.setState({window:''});
		}

		getSelectedUser() {
			return this.state.selected_user;
		}

		closeScreenSelection() {
			this.showWindow('extra_screen_access_div',false);
		}

		closeStatusSetting( needReloadUsers) {
			this.showWindow('show_status_div',false);
			this.setState({isLoaded:!needReloadUsers});
		}

		showInfoMessage(title,text) {
			this.setState({info_title:title,info_message:text});
			this.showWindow('message_div',true);
		}

		closeInfoMessage() {
			this.setState({info_message:undefined});
			this.showWindow('message_div',false);
		}

		showCyberSecurity(user) {
			//console.log(JSON.stringify(user));
			this.setState({selected_user:user.user_id, otp_phone:user.phone, otp_enabled:user.use_2_factor_authentication, use_secure_pw:user.use_secure_pw});
			this.showWindow('cyber_security_div',true);
		}

		saveCyberSecuritySettings() {
				var phone = this.state.otp_phone.trim();
				if(this.state.otp_enabled) {
					if(!this.isPhone(phone)) {
						this.setState({otp_phone_helper_ok:false});
						return;
					}
				}else{
					// otp not enabled --> phone === 0 or then correct
					if(phone.trim().length>0 && !this.isPhone(phone)) {
						this.setState({otp_phone_helper_ok:false});
						return;
					}
				}
				phone = phone.replace(/\s/g, '');
				var url = ETVConstants.getServerBase()+"/useradmin?action=update_2_factor_authentication&phone="+encodeURIComponent(phone)+"&user_id="+this.state.selected_user+"&use_2_factor_authentication="+this.state.otp_enabled+"&use_secure_pw="+this.state.use_secure_pw;
				var accessToken = localStorage.getItem("etv.access_token");
				var reqUserID = localStorage.getItem("etv.user_id");
				//console.log(url);
				fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then((response) => response.json())
				.then((result) => {
						if(result["result"]!==true) {
							this.showInfoMessage(ETVConstants.trans("error"),ETVConstants.trans("could_not_update_cybersecurity_settings"));
						}else{
							this.setState({isLoaded:false, otp_enabled:false, use_safe_pw:false, otp_phone_helper_ok:true, otp_phone:'', window:''});
						}
				})
				.catch((error) => {
						//console.log(error);
					  //this.showInfoMessage("Error",error);
				});
		}

		isPhone( phone) {
			// remarks: ^abc$ --> means exact match
			// "/" start and "/" end tags
			// \s* as many whitespace as wishes
			// {7,} at least 7 numbers
			// \+ must start with +
			const PHONE_REGEX =  /^\+\s*[0-9\s*]{7,}$/;
			//console.log("testing phone="+PHONE_REGEX.test(phone));
			return PHONE_REGEX.test(phone);
		}

		showAddUserWindow() {
			this.setState({new_user_pw1:'',new_user_pw2:'',new_user_name:'',new_user_firstname:'',new_user_lastname:''});
			this.showWindow('add_user',true);
		}

		showAvailableSwitch( rowData) {
			var switchActive = rowData.available;
			var toolTip = rowData.available?ETVConstants.trans("click_to_set_unavailable"):ETVConstants.trans("click_to_set_available");
			return <FormControlLabel control={<Tooltip title={toolTip}><Switch color='primary' checked={switchActive} onClick={()=>{this.changeAvailability(rowData.user_id,!rowData.available)}} /></Tooltip>} />
		}

		changeAvailability( userID, available) {
			var url = ETVConstants.getServerBase()+"/useradmin?action=set_availability&user_id="+userID+"&availability="+available;
			this.sendCMDToServer(url);
		}

		showUserSkills( rowData) {
			this.setState({selected_user:rowData.user_id, selected_user_object:rowData, selected_user_skills:rowData.skillset, user_skills_changed:false});
			this.showWindow("skills_div",true)
		}

		saveAvailableSkillSet() {
			var url = ETVConstants.getServerBase()+"/useradmin?action=set_skills&user_id="+this.props.user_id;
			for(var i=0; i<this.state.chip_values.length; i++) {
				url += "&skill="+encodeURIComponent(this.state.chip_values[i]);
			}
			//console.log("saveAvailableSkillSet url="+url);
			this.sendCMDToServer(url,true);
			this.setState({chips_changed:false});
		}

		saveUserSkills() {
			var url = ETVConstants.getServerBase()+"/useradmin?action=set_skills&user_id="+this.state.selected_user;
			for(var i=0; i<this.state.selected_user_skills.length; i++) {
				url += "&skill="+encodeURIComponent(this.state.selected_user_skills[i]);
			}
			this.sendCMDToServer(url);
			this.setState({user_skills_changed:false});
			//this.showWindow("skills_div",false);
		}

		handleCheckBoxChange( item ) {
			var curSkills = this.state.selected_user_skills;
			if(curSkills.includes(item)) {
					var index = curSkills.indexOf(item);
					curSkills.splice(index,1);
			}else{
				curSkills.push(item);
			}
			this.setState({user_skills_changed:true,selected_user_skills:curSkills});
		}

		printUserSkills() {
			if(!this.state.selected_user_object) return <span/>;
			if(!this.state.selected_user_object.skillset) return <span>no skills</span>;
			return (
				this.state.chip_values.map( (item,index)=> {return <FormControlLabel id={index} key={index} control={<Checkbox checked={this.hasSkill(item)} onClick={()=>this.handleCheckBoxChange(item)}>{item}</Checkbox>} name={item} label={item} />})
			)
		}

		hasSkill( skill) {
			return this.state.selected_user_skills.includes(skill);
		}

		dateToHoursMin( d) {
			var h = d.getHours();
			var m = d.getMinutes();
			if(h<10) h = "0"+h;
			if(m<10) m = "0"+m;
			return h+":"+m;
		}

////////////////////////////////////////////////////////////////////////////////
// Assignment
////////////////////////////////////////////////////////////////////////////////

		loadAssignments(userID) {
			var url = ETVConstants.getServerBase()+"/emergency?action=list_devices&user_id="+userID;
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then((response) => response.json())
			.then((result) => {
					var selected = result.list;
					var selIDs = [];
					for(var i=0; i<selected.length; i++) {
						selIDs.push(selected[i].id);
					}
					this.setState({selected_elevators:selIDs});
			})
			.catch((error) => {

			});
		}

		showAssignment( rowData) {
			this.setState({selected_user:rowData.user_id, selected_user_object:rowData});
			this.showWindow("assign_devices_div",true);
			this.loadAssignments(rowData.user_id);
		}

		isAssigned(elevatorID, userID) {
			if(!this.state.selected_elevators) return false;
			for(var i=0; i<this.state.selected_elevators.length; i++) {
				if(this.state.selected_elevators[i]===elevatorID) return true;
			}
			return false;
		}

		allAssigned() {
			var all = this.state.elevators;
			var assigned = this.state.selected_elevators;
			return all.length===assigned.length;
		}

		assignAllScreens() {
			if(this.allAssigned()) {
				this.setState({selected_elevators:[], need_save_assignments:true});
				return;
			}
			console.log("assigning all screens");
			var allElevators = this.state.elevators;
			var assignedIDs = [];
			for(var i=0; i<allElevators.length; i++) {
				assignedIDs.push(allElevators[i].id);
			}
			console.log("allElevators.length="+allElevators.length);
			this.setState({selected_elevators:assignedIDs, need_save_assignments:true});
		}

		printAssignment() {
			if (!this.state.elevators) return <span>no elevators</span>
			var userID = this.state.selected_user;
			return (
				<div>
					<Button size="small" color="secondary" variant='contained' onClick={()=>this.assignAllScreens()} >{ETVConstants.trans("all")}</Button>
					{
						this.state.elevators.map( (item,index) => {return <p><FormControlLabel id={index} key={item.id} control={<Checkbox checked={this.isAssigned(item.id,userID)} onClick={()=>this.handleAssignmentCheckBoxChange(item.id)}></Checkbox>} name={item.id} label={item.device_name} /></p>})
					}
				</div>
			)
		}

		handleAssignmentCheckBoxChange(item) {
			//console.log("checkedItem="+JSON.stringify(item));
			var selectedAssignments = this.state.selected_elevators;
			if(selectedAssignments.includes(item)) {
				//then remove
				var index = selectedAssignments.indexOf(item);
				selectedAssignments.splice(index,1);
			}else{
				//then add news
				selectedAssignments.push(item);
			}
			this.setState({selected_elevators:selectedAssignments, need_save_assignments:true});
		}

		saveTechnicianAssignment() {
			var url = ETVConstants.getServerBase()+"/emergency?action=save_assignment&user_id="+this.state.selected_user;
			for(var i=0; i<this.state.selected_elevators.length; i++) {
				url += "&device_id="+this.state.selected_elevators[i];
			}
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then((response) => response.json())
			.then((result) => {
					if(result["result"]) {
						//console.log("success");
						this.setState({selected_elevators:[],selected_user:'',need_save_assignments:false});
						this.showWindow("assign_devices_div",false);
					}else{
						//console.log("error="+JSON.stringify(result));
					}
			})
			.catch((error) => {
					//console.log("error");
			});
		}

////////////////////////////////////////////////////////////////////////////////
// SHIFT
////////////////////////////////////////////////////////////////////////////////

		initShift(time) {
			if(!time || time.length===0) {
				time = "00:00";
			}
			var d = new Date();
			d.setHours(time.substring(0,2),time.substring(3));
			return d;
		}

		showWorkShifts( rowData) {
			var sh1start = this.initShift(rowData.shift.shift1_start);
			var sh1end = this.initShift(rowData.shift.shift1_end);
			var sh2start = this.initShift(rowData.shift.shift2_start);
			var sh2end = this.initShift(rowData.shift.shift2_end);
			var has2nd = true;
			if(this.dateToHoursMin(sh1end) === this.dateToHoursMin(sh2start)) {
				sh1end = sh2end;
				has2nd = false;
			}
			this.setState({has_second_shift:has2nd, selected_user:rowData.user_id, selected_user_object:rowData, shift1_start:sh1start, shift1_end:sh1end, shift2_start:sh2start, shift2_end:sh2end});
			this.showWindow("shift_div",true)
		}

		saveShift() {
			var sh1_start = this.state.shift1_start;
			var sh1_end = this.state.shift1_end;
			if(sh1_start && sh1_end) {
				if(sh1_end<sh1_start) {
					this.setState({shift1_end:sh1_start});
				}
			}

			var sh2_start = this.state.shift2_start;
			var sh2_end = this.state.shift2_end;
			if(sh2_start && sh2_end) {
				if(sh2_end<sh2_start) {
					this.setState({shift2_end:sh2_start});
				}
			}
			var url = ETVConstants.getServerBase()+"/useradmin?action=set_working_time&user_id="+this.state.selected_user;
			if(this.state.has_second_shift) {
				url+="&start1="+this.dateToHoursMin(this.state.shift1_start);
				url+="&start2="+this.dateToHoursMin(this.state.shift2_start);
				url+="&end1="+this.dateToHoursMin(this.state.shift1_end);
				url+="&end2="+this.dateToHoursMin(this.state.shift2_end);
			}else{
				url+="&start1="+this.dateToHoursMin(this.state.shift1_start);
				url+="&start2="+this.dateToHoursMin(this.state.shift2_start);
				url+="&end1=00:00";
				url+="&end2=00:00";
			}
			this.sendCMDToServer(url);
			this.setState({isLoaded:false});
		}

		updateShiftFrom( from, until) {
			if(from>until) {
				return until;
			}
			return from;
		}

		updateShiftTo( from, until) {
			if(until<from) {
				return from;
			}
			return until;
		}

		updateHas2ndShift() {
			var has2nd = !this.state.has_second_shift;
			if(has2nd) {
				this.setState({has_second_shift:true,shift2_start:this.state.shift1_end,shift2_end:this.state.shift1_end});
			}else{
				this.setState({has_second_shift:false});
			}
		}

		printWorkShifts() {
			return <span>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<span>
									<TimePicker
							 			label={ETVConstants.trans("shift_start")}
										ampm={false}
							 			value={this.state.shift1_start}
							 			onChange={(newValue) => {
								 			this.setState({shift1_start:this.updateShiftFrom(newValue,this.state.shift1_end), shifts_changed:true})
							 			}}
							 			renderInput={(params) => <TextField {...params} />}
						 			/>&nbsp;
									<TimePicker
							 			label={ETVConstants.trans("shift_end")}
										ampm={false}
							 			value={this.state.shift1_end}
							 			onChange={(newValue) => {
								 			this.setState({shift1_end:this.updateShiftTo(this.state.shift1_start,newValue), shifts_changed:true})
							 			}}
							 			renderInput={(params) => <TextField {...params} />}
						 			/>
								</span>
								<br/>
								<br/>
								<br/>
								<FormControlLabel control={<Checkbox checked={this.state.has_second_shift} onChange={()=>{this.updateHas2ndShift()}} />} label={ETVConstants.trans("has_second_shift")} />
								<br/>
								{this.state.has_second_shift?
										<span>
											<TimePicker
												label={ETVConstants.trans("shift_start")}
												ampm={false}
												value={this.state.shift2_start}
												onChange={(newValue) => {
													this.setState({shift2_start:this.updateShiftFrom(newValue,this.state.shift2_end), shifts_changed:true})
												}}
												renderInput={(params) => <TextField {...params} />}
											/>&nbsp;
											<TimePicker
												label={ETVConstants.trans("shift_end")}
												ampm={false}
												value={this.state.shift2_end}
												onChange={(newValue) => {
													this.setState({shift2_end:this.updateShiftTo(this.state.shift2_start,newValue), shifts_changed:true})
												}}
												renderInput={(params) => <TextField {...params} />}
											/>
										</span>
										:
										<span/>
								}

						</MuiPickersUtilsProvider>
				</span>
		}

    render() {
      if(!this.state.isLoaded) {
        return <div style={{display:'block',margin:'auto',width:'100%',height:'100%'}} ><CircularProgress /></div>
      }

      const columnsData= [
          //{ title: '', field: 'row_id', render: rowData => <input type='hidden' value={rowData.user_id}></input> },
					{ title: ETVConstants.trans("activated"), field: 'actvation', render: rowData => !rowData.activation?<Tooltip title={ETVConstants.trans("click_to_activate_user")}><IconButton style={{color:'red'}} size='small' label={ETVConstants.trans("activate_user")} onClick={()=>this.activateUser(rowData.user_id)}><ReportProblemOutlinedIcon/></IconButton></Tooltip>:<HowToRegIcon style={{color:'green'}}/>},
					{ title: ETVConstants.trans("available"), field: 'available', render: rowData => this.showAvailableSwitch(rowData)},
          { title: ETVConstants.trans("username"), field: 'user_name'},
					{ title: ETVConstants.trans("phone"), field: 'phone'},
					{ title: ETVConstants.trans("elevators"), field: 'elevators', render: rowData => <Button size='small' variant='contained' color='primary' onClick={()=>{this.showAssignment(rowData)}}>{ETVConstants.trans("elevators")}</Button>},
					{ title: ETVConstants.trans("shift"), field: 'shift', render: rowData => <Button size='small' variant='contained' color='primary' onClick={()=>{this.showWorkShifts(rowData)}}>{ETVConstants.trans("shift")}</Button>},
					{ title: ETVConstants.trans("skills"), field: 'skills', render: rowData => <IconButton size='small' variant='contained' color='primary' onClick={()=>{this.showUserSkills(rowData)}}><BuildIcon/></IconButton>},
					{ title: ETVConstants.trans("cyber_security"), field: '', render: rowData => <Button size='small' variant='contained' color={rowData.use_2_factor_authentication || rowData.use_secure_pw?'secondary':'primary'} onClick={()=>this.showCyberSecurity(rowData)}>{rowData.use_2_factor_authentication || rowData.use_secure_pw?ETVConstants.trans("disable"):ETVConstants.trans("enable")}</Button>},
					{ title: ETVConstants.trans("password"), field: 'pw_change', render: rowData => <Button size='small' variant='contained' color='primary' onClick={()=>this.pwChange(rowData.user_id)}>{ETVConstants.trans("change")}</Button>},
					{ title: ETVConstants.trans("access"), field: 'blocked', render: rowData => !rowData.active?<Tooltip title={ETVConstants.trans("click_to_unblock_user")}><IconButton size='small' style={{color:'red'}} onClick={()=>this.blockUser(rowData.user_id,false)}><BlockIcon/></IconButton></Tooltip>:<Tooltip title={ETVConstants.trans("block_user")}><IconButton size='small' style={{color:'green'}} onClick={()=>this.blockUser(rowData.user_id,true)}><CheckCircleIcon/></IconButton></Tooltip>},
					{ title: ETVConstants.trans("delete"), field: 'actions', render: rowData => <IconButton size='small' label='delete' variant='contained' color='secondary' onClick={()=>this.setState({showDeleteUserDialog:true, deleteUserID:rowData.user_id})}><Delete/></IconButton>},
        ];

      const tabletitle = "Service Technicians";

      return(
        <div><BasicTable2 data={this.state.items} columns={columnsData} title={tabletitle} pageSize={10} deleteF={this.deleteF} allow_search={true} />

				<Dialog
					open={this.state.showDeleteUserDialog}
					onClose={()=>{this.setState({showDeleteUserDialog:false, deleteUserID: undefined,})}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{ETVConstants.trans("delete_user")}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{ETVConstants.trans("are_you_sure_to_delete_user")}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={()=>{this.setState({showDeleteUserDialog:false, deleteUserID:undefined,})}} autoFocus color="primary">{ETVConstants.trans("cancel")}</Button>
						<Button onClick={()=>{this.deleteUser(this.state.deleteUserID)}} color="secondary" variant='contained'>{ETVConstants.trans("agree")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='cyber_security_div'}
					maxWidth='sm'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle id="alert-dialog-title">{ETVConstants.trans("cyber_security")}</DialogTitle>
					<DialogContent>
						<FormControlLabel control={<Checkbox checked={this.state.use_secure_pw} onChange={()=>{this.setState({use_secure_pw:!this.state.use_secure_pw})}} />} label={ETVConstants.trans("enforce_secure_pw")} />
						<Typography variant='body1'>{ETVConstants.trans("secure_pw_requirements")}</Typography>
						<Typography variant='body2'>{ETVConstants.trans("pw_at_least_1_digit")}</Typography>
						<Typography variant='body2'>{ETVConstants.trans("pw_at_least_1_lowercase")}</Typography>
						<Typography variant='body2'>{ETVConstants.trans("pw_at_least_1_uppercase")}</Typography>
						<Typography variant='body2'>{ETVConstants.trans("pw_at_least_1_special_char")}</Typography>
						<Typography variant='body2'>{ETVConstants.trans("pw_len_8_20")}</Typography>
						<Divider style={{marginTop:20,marginBottom:20}}/>
						<FormControlLabel control={<Checkbox checked={this.state.otp_enabled} onChange={()=>{this.setState({otp_enabled:!this.state.otp_enabled})}} />} label={ETVConstants.trans("2_factor_authentication_enabled")} />
						<TextField autoComplete='off' error={!this.state.otp_phone_helper_ok} helperText={this.state.otp_phone_helper_ok?"":ETVConstants.trans("invalid_phone_number")} style={{marginTop:10}} fullWidth={true} size="small" required id="otp_phone" label={ETVConstants.trans("phone")} value={this.state.otp_phone} onChange={(e)=>{this.setState({otp_phone:e.target.value})}} onInput={()=>{this.setState({otp_phone_helper_ok:true})}} />
					</DialogContent>
					<DialogActions>
						<Button size="small" onClick={()=>{this.showWindow('cyber_security_div',false)}} color="primary">{ETVConstants.trans("close")}</Button>
						<Button size="small" disabled={ (this.state.otp_phone.trim().length===0 && this.state.otp_enabled) || !this.state.otp_phone_helper_ok} onClick={()=>{this.saveCyberSecuritySettings()}} color="secondary" variant='contained'>{ETVConstants.trans("save")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='add_user'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle id="adduser-dialog-title">{ETVConstants.trans("add_new_user")}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description" component={'span'}>
										<TextField
											InputProps={{
										    startAdornment: (
										      <InputAdornment position="start">
										        <Face />
										      </InputAdornment>
										    )
										  }}
											error={!this.state.new_user_email_helper_ok}	helperText={this.state.new_user_email_helper_ok?"":ETVConstants.trans("enter_valid_email_address")}	size="small" fullWidth={true} required id="new_user_name" autoComplete="off"  label={ETVConstants.trans("username")} value={this.state.new_user_email} onChange={(e)=>{this.setState({new_user_email:e.target.value})}} onInput={()=>{this.setState({new_user_email_helper_ok:true})}}/>
										<TextField style={{marginTop:10}} fullWidth={true} size="small" id="new_firstname_1" label={ETVConstants.trans("firstname")}  value={this.state.new_user_firstname} onChange={(e)=>{this.setState({new_user_firstname:e.target.value})}} />
										<TextField style={{marginTop:10}} fullWidth={true} size="small" id="new_lastname_1"  label={ETVConstants.trans("lastname")}  value={this.state.new_user_lastname} onChange={(e)=>{this.setState({new_user_lastname:e.target.value})}}/>
										<TextField style={{marginTop:10}} error={!this.state.new_user_pw_helper_ok}	helperText={this.state.new_user_pw_helper_ok?"":ETVConstants.trans("password_at_least_6_characters")} fullWidth={true} size="small" required id='new_password_1' autoComplete="off" label={ETVConstants.trans("password")} type='password'  value={this.state.new_user_pw1} onChange={(e)=>{this.setState({new_user_pw1:e.target.value})}} onInput={()=>{this.setState({new_user_pw_helper_ok:true})}} />
										<TextField style={{marginTop:10}} error={!this.state.new_user_pw2_helper_ok}	helperText={this.state.new_user_pw2_helper_ok?"":ETVConstants.trans("password_mismatch")} fullWidth={true} size="small" required id='new_password2_1' autoComplete="off" label={ETVConstants.trans("repeat_password")} type='password'  value={this.state.new_user_pw2} onChange={(e)=>{this.setState({new_user_pw2:e.target.value})}} onInput={()=>{this.setState({new_user_pw2_helper_ok:true})}} />
						</DialogContentText>
					</DialogContent>
					<DialogActions>
							<Button size='small' color="primary" onClick={() => this.showWindow('add_user',false)}>{ETVConstants.trans("cancel")}</Button>
							<Button size='small' variant="contained" color="secondary" disabled={!this.state.new_user_email_helper_ok || !this.state.new_user_pw_helper_ok || !this.state.new_user_pw2_helper_ok} onClick={()=>{this.insertUser()}}>{ETVConstants.trans("insert")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='message_div'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{this.setState({window:''})}}>
					<DialogTitle id="message div">{this.state.info_title}</DialogTitle>
					<DialogContent>
							<Typography variant="body1">{this.state.info_message}</Typography>
					</DialogContent>
					<DialogActions>
						<Button size="small" onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='show_pw_change'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle id="reset pw div">{ETVConstants.trans("reset_password")}</DialogTitle>
					<DialogContent>
								<TextField size="small"
												error={!this.state.change_pw_helper_ok}
												helperText={this.state.change_pw_helper_ok?"":ETVConstants.trans("password_at_least_6_characters")}
												type='password'
												variant="outlined"
												autoComplete="off"
												fullWidth={true}
												required id="new_password"
												value={this.state.changePasswordValue}
												onChange={(event) => this.setState({ changePasswordValue: event.target.value })}
												label={ETVConstants.trans("new_password")}
												onInput={ (e)=>{this.setState({change_pw_helper_ok:true})}} />
					</DialogContent>
					<DialogActions>
						<Button size="small" color="primary" onClick={() => this.showWindow('show_pw_change',false)}>{ETVConstants.trans("cancel")}</Button>
						<Button disabled={this.state.changePasswordValue.trim().length<6} size="small" variant="contained" color="secondary" onClick={() => this.submitNewPW(this.state.selected_user)}>{ETVConstants.trans("save")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='skills_div'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle id="skills">{ETVConstants.trans("skills")}</DialogTitle>
					<DialogContent>
							{
								this.printUserSkills()
							}
					</DialogContent>
					<DialogActions>
						<Button size="small" color="primary" onClick={() => this.showWindow('skills_div',false)}>{ETVConstants.trans("cancel")}</Button>
						<Button disabled={!this.state.user_skills_changed} size="small" variant="contained" color="secondary" onClick={() => this.saveUserSkills()}>{ETVConstants.trans("save")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='shift_div'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle id="skills">{ETVConstants.trans("shift")}</DialogTitle>
					<DialogContent>
							{
								this.printWorkShifts()
							}
					</DialogContent>
					<DialogActions>
						<Button size="small" color="primary" onClick={() => this.showWindow('shift_div',false)}>{ETVConstants.trans("cancel")}</Button>
						<Button disabled={!this.state.shifts_changed} size="small" variant="contained" color="secondary" onClick={() => this.saveShift()}>{ETVConstants.trans("save")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='define_skills'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle id="Skillsdefinition">{ETVConstants.trans("define_skills")}</DialogTitle>
					<DialogContent>
								<SkillsDefinition user_id={this.props.user_id} />
					</DialogContent>
					<DialogActions>
						<Button size="small" color="primary" onClick={() => this.showWindow('define_skills',false)}>{ETVConstants.trans("close")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='assign_devices_div'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle id="Assign Devices">{ETVConstants.trans("assign_devices")}</DialogTitle>
					<DialogContent>
							{
								this.printAssignment()
							}
					</DialogContent>
					<DialogActions>
						<Button size="small" color="primary" onClick={() => this.showWindow('assign_devices_div',false)}>{ETVConstants.trans("cancel")}</Button>
						<Button disabled={!this.state.need_save_assignments} size="small" variant="contained" color="secondary" onClick={() => this.saveTechnicianAssignment()}>{ETVConstants.trans("save")}</Button>
					</DialogActions>
				</Dialog>

        <div>
          <br/>
					<Button variant="contained" color="secondary" onClick={() => this.showWindow('define_skills',true)} >{ETVConstants.trans("define_skills")}</Button>&nbsp;
          <Button variant="contained" color="primary" onClick={() => this.showAddUserWindow()} >{ETVConstants.trans("add_new_user")}</Button>
        </div>
	   </div>);
	}
}

export default ServiceTechnician
