import React from 'react';
import {Card,Button} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SelectAvailableScreensNew from './SelectAvailableScreensNew';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import * as ETVConstants from '../ETVConstants';

class SelectDevice extends React.Component {

	constructor(props) {
      super(props);
      this.selectedScreens = this.selectedScreens.bind(this);
      this.closeScreenSelection = this.closeScreenSelection.bind(this);
    }

    state = {
      devices:[],
			groups:[],
			select_group_devices:[], //object of devices with groups
      result_json: undefined,
      selectedDevice:"all_devices",
      layoutUrl: '',
      isLandscape: true,
			screenRatio:16.0/9.0,
      isLoaded: false,
      selected_screens:[],
      info_message_title: undefined,
      info_message: undefined,
			group_is_selected:false,
			device_is_selected:false
    }

		getGroupName( deviceID, groups) {
			for(var i=0; i<groups.length; i++) {
				if(groups[i]["device_ids"].includes(deviceID)) return groups[i].group_name;
			}
			return "-- no group --";
		}

		createGroupDevices(devices,groups) {
			var result = [];
			for(var i=0; i<groups.length; i++) {
				var obj = {};
				obj["id"]="_group_"+groups[i].group_id;
				obj["device_name"]=groups[i].group_name;
				obj["group_name"]="groups";
				result.push(obj);
			}
			for(var j=0; j<devices.length; j++) {
				var obj2 = {};
				obj2["id"]=devices[j].id;
				obj2["device_name"]=devices[j].device_name;
				obj2["group_name"]="devices";
				result.push(obj2);
			}
			//result.sort( (a, b) => a.group_name >= b.group_name ? -1 : 1);
			result.sort( this.sortDeviceGroupList);
			return result;
		}

		sortDeviceGroupList( a, b) {
			//both groups
			if(a.id.startsWith("_group_") && b.id.startsWith("_group_")) {
				return a.group_name.localeCompare(b.group_name);
			}else if(a.id.startsWith("_group_")) {
				return -1;
			}else if(b.id.startsWith("_group_")) {
				return 1;
			}else{
					return (a.device_name.localeCompare(b.device_name));
			}
		}

    componentDidMount() {
      this.loadData();
    }

		handleDeviceChangeNew = (e,value) => {
			if(!value) return;
			var selectedDevice = value.id;
			this.setState({selectedDevice:selectedDevice});
      if(selectedDevice==="all_devices") {
          this.setState({selectedDevice:"all_devices",layoutUrl:undefined});
					this.props.layoutSelectedF( null,-1, null);
					return;
      }
			//can be from group or device
			this.loadLayout(value.id);
			var groupID = this.getGroupID(selectedDevice);
			if(groupID>=0) {
				//is a group
				var commonLayoutID = this.getCommonLayoutID(groupID);
				this.props.layoutSelectedF( null ,groupID, commonLayoutID);
				this.setState({group_is_selected:true,device_is_selected:false});
			}else{
				var device = this.getDevice(selectedDevice);
				var screenRatio = 16.0/9.0;
				try{
						screenRatio = parseFloat(device.screen_ratio);
				}catch(e) {
					//console.log("could not parse float: "+device.screen_ratio);
				}
				//console.log("---->device selected screenrRatio: "+screenRatio);
	      this.props.layoutSelectedF( device,-1, device.layout_id);
				this.setState({group_is_selected:false,device_is_selected:true,screenRatio:screenRatio});
			}
		}

    getDevice( deviceID) {
      for( var i=0; i<this.state.devices.length; i++) {
        if(this.state.devices[i].id===deviceID) {
          return this.state.devices[i];
        }
      }
      return "all_devices";
    }

		getGroup( groupID) {
			for(var i=0; i<this.state.groups.length; i++) {
				if(this.state.groups[i].group_id===groupID) {
					return this.state.groups[i];
				}
			}
			return null;
		}

		getCommonLayoutID(groupID) {
			var group = this.getGroup(groupID);
			var deviceIDs = group.device_ids;
			var commonLayout = -1;
			for(var i=0; i<deviceIDs.length; i++) {
				var d = this.getDevice(deviceIDs[i]);
				if(commonLayout<0) {
						commonLayout = d.layout_id;
				}else{
						if(d.layout_id!==commonLayout) return null;
				}
			}
			return commonLayout;
		}

		getGroupID( groupStr) {
			if(groupStr.indexOf("_group_")!==0) {
				return -1;
			}
			return parseInt(groupStr.substring("_group_".length));
		}

    loadLayout( selectedDeviceID) {
      if(selectedDeviceID==="all_devices") {
        return;
      }
			var layoutID = '';
			var groupID = this.getGroupID(selectedDeviceID);
			if(groupID>=0) {
				//group is selected
				layoutID = this.getCommonLayoutID(groupID);
				if(layoutID===null) {
					this.showInfoMessage(ETVConstants.trans("error"),ETVConstants.trans('error_group_has_no_common_layout'));
				}
			}else{
				//device is selected
      	var dev = this.getDevice(selectedDeviceID);
      	layoutID = dev.layout_id;
			}

			var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/layout_mgmt?action=get_layout&layout_id="+layoutID;
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              layoutUrl: result.thumbnail,
              isLandscape: result.landscape,
            });
          },
          (error) => {
            this.setState({
              layoutUrl: undefined,
              error
            });
          }
        )
    }

    loadData() {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=list&owner_id="+this.props.user_id;
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						//console.log("xxxx-->"+JSON.stringify(result));
            var groups = ETVConstants.filterOutEmptyGroups(result.groups);
            var devices = result.list;
            this.setState({
              isLoaded: true,
              result_json: result,
              devices: devices,
							groups: groups,
							select_group_devices:this.createGroupDevices(devices,groups)
            });
          },
          (error) => {
            this.setState({
              isLoaded: false,
              error
            });
          }
        )
    }

    showWindow(divID, show) {
			show?this.setState({window:divID}):this.setState({window:''});
		}

    showCopyPlaylist() {
      this.showWindow('copy_playlist_div',true);
    }

    // hook function
    selectedScreens(screenIDs) {
      this.showWindow('copy_playlist_div',false);
      this.setState({selected_screens:screenIDs});
      this.submitCopyPasteScreens(screenIDs);
    }

    submitCopyPasteScreens(screenIDs) {
      this.showInfoMessage("copy","copy settings from "+this.state.selectedDevice+" to screens="+screenIDs);
      var url = ETVConstants.getServerBase()+"/playlist?action=copy_playlist&from_device="+this.state.selectedDevice;
      for(var i=0; i<screenIDs.length; i++) {
        url += "&to_device="+screenIDs[i];
      }
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            if(result["result"]) {
              this.showInfoMessage(ETVConstants.trans("success"),ETVConstants.trans("playlist_copied_to_selected_devices"));
            }else{
              this.showInfoMessage(ETVConstants.trans("error"),result["reason"]);
            }
          },
          (error) => {
            this.showInfoMessage(ETVConstants.trans("error"),error);
          }
        )
    }

    closeScreenSelection() {
      this.showWindow('copy_playlist_div',false);
    }

    showInfoMessage(title,text) {
      this.setState({info_message_title:title,info_message:text});
      this.showWindow('message_div',true);
    }

    closeInfoMessage() {
      this.setState({info_message:undefined});
      this.showWindow('message_div',false);
    }

		//calculates the best image width depending on layout
		getOptimalLayoutWidth( isLandscape, screenRatio) {
			if(isLandscape) {
				return 250*screenRatio;
			}else{
				return 250;
			}
		}

		getOptimalLayoutHeight( isLandscape, screenRatio) {
			if(isLandscape) {
				return 250;
			}else{
				return 250*screenRatio;
			}
		}

    render() {
      return(
        <div>
            <Card>
              <CardContent>
								<TableContainer>
									<Table size='small'>
										<colgroup>
		                    <col width="300" />
												<col width="450" />
		                </colgroup>
	                  <TableBody>
											<TableRow>
												<TableCell style={{verticalAlign:'top',border:0}}>
													<Autocomplete
														id="select screen"
														options={this.state.select_group_devices}
														groupBy={(option) => option.group_name}
														getOptionLabel={(option) => option.device_name}
														style={{ width: 300, marginTop:20 }}
														onChange={this.handleDeviceChangeNew}
														renderInput={(params) => <TextField {...params} label="select device" variant="outlined" />}
													/>
													{(this.state.device_is_selected && this.props.can_copy)?<Button style={{marginTop:'30px'}} disabled={this.state.selectedDevice==='all_devices' || !this.props.can_copy} outline="contained" variant='contained' color="primary" onClick={() => this.showCopyPlaylist()}>{ETVConstants.trans("copy_playlist_and_layout")}</Button>:<span/>}
												</TableCell>
												<TableCell style={{verticalAlign:'top',border:0}}>
													{
														this.state.layoutUrl?
																<img key={"layout_img"} alt='layout' src={ETVConstants.ON_PREMISE_VERSION?ETVConstants.makeLayoutURLRelative(this.state.layoutUrl):this.state.layoutUrl} style={{width:this.getOptimalLayoutWidth(this.state.isLandscape,this.state.screenRatio),height:this.getOptimalLayoutHeight(this.state.isLandscape,this.state.screenRatio)}} />
																:
																<span/>
													}
												</TableCell>
											</TableRow>
	                  </TableBody>
	                </Table>
								</TableContainer>
              </CardContent>
            </Card>

						<Dialog
							open={this.state.window==='copy_playlist_div'}
							maxWidth='xs'
							fullWidth={true}
							onClose={()=>{this.setState({window:''})}}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description">
							<DialogTitle id="alert-dialog-title">{this.state.info_message_title}</DialogTitle>
							<DialogContent>
									<SelectAvailableScreensNew isOpen={this.state.window==='copy_playlist_div'} user_id={this.props.user_id} closeHook={this.closeScreenSelection} excluded_screen_ids={[this.state.selectedDevice]} selectedScreens={this.selectedScreens} selected={this.state.selected_screens?this.state.selected_screens:[]}/>
							</DialogContent>
							<DialogActions>
								<Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
							</DialogActions>
						</Dialog>

						<Dialog
	            open={this.state.window==='message_div'}
	            maxWidth='xs'
	            fullWidth={true}
	            onClose={()=>{this.setState({window:''})}}
	            aria-labelledby="alert-dialog-title"
	            aria-describedby="alert-dialog-description">
	            <DialogTitle id="alert-dialog-title">{this.state.info_message_title}</DialogTitle>
	            <DialogContent>
	                <Typography variant="body1">{this.state.info_message}</Typography>
	            </DialogContent>
	            <DialogActions>
	              <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
	            </DialogActions>
	          </Dialog>

        </div>
	 		);
	}
}

export default SelectDevice
