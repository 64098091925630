import React from 'react';
import {Card,Button} from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import CardMedia from '@material-ui/core/CardMedia';
import PublicIcon from '@material-ui/icons/Public';
import Tooltip from '@material-ui/core/Tooltip';
import TimelineIcon from '@material-ui/icons/Timeline';
import Chip from '@material-ui/core/Chip';
import PersonIcon from '@material-ui/icons/Person';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {InputLabel,MenuItem,FormControl,Select} from '@material-ui/core';
import * as ETVConstants from '../ETVConstants';

class ContentLibraryItem extends React.Component {

    state={
      window:'',
      new_owner_id:-1,
    }

    constructor(props) {
        super(props);
        this.saveNewOwnerResult = this.saveNewOwnerResult.bind(this);
    }

    componentDidMount() {
      this.setState({new_owner_id:this.props.user_id,user_name:localStorage.getItem("etv.user_name")});
    }

    isMovie( filename) {
      if (filename.endsWith(".mpg")) return true;
      if (filename.endsWith(".mpeg")) return true;
      if (filename.endsWith(".mp4")) return true;
      return false;
    }

    isHTML( filename) {
      if (filename.endsWith(".htm")) return true;
      if (filename.endsWith(".html")) return true;
      return false;
    }

    isMusic( filename) {
      if (filename.endsWith(".mp3")) return true;
      if (filename.endsWith(".m4a")) return true;
      return false;
    }

    getThumbnail() {
      var imageUrl = this.props.item.thumbnail;
      var title = this.props.item.filename;
      if(!imageUrl) {
        if(this.isMovie(title.toLowerCase())) {
          imageUrl = "/images/content/movie_placeholder.png";
        }else if(this.isHTML(title.toLowerCase())) {
          imageUrl = "/images/content/html_placeholder.png";
        }else if(this.isMusic(title.toLowerCase())) {
          imageUrl = "/images/content/music_placeholder.png";
        }
      }
      return imageUrl;
    }

    addToChannel(contentID, channel, deviceID, groupID) {
      //console.log("add content to channel: contentID="+contentID+" channel="+channel+" device="+deviceID+" groupID="+groupID);
      var url = ETVConstants.getServerBase()+"/playlist?action=add_to_playlist&channel="+channel+"&device_id="+deviceID+"&user_id="+this.props.user_id+"&content_id="+contentID;
      if(groupID>=0) {
        url = ETVConstants.getServerBase()+"/playlist?action=add_to_group_playlist&channel="+channel+"&group_id="+groupID+"&user_id="+this.props.user_id+"&content_id="+contentID;
      }
      //console.log("xxxxUrl="+url);
      ETVConstants.sendCMDToServer(url);
      this.props.addedToChannelF();
    }

    userCanContributeMarketplace() {
      return this.props.user_roles.includes("admin.content.contribute_marketplace");
    }

    ownerIsChild( ownerID) {
      var includes = this.props.children.includes(ownerID);
      //console.log("includes="+includes);
      return includes;
    }

    showChangeOwner( contentID) {
      this.showWindow("show_change_owner_div",true);
    }

    showWindow(divID, show) {
      show?this.setState({window:divID}):this.setState({window:''});
    }

    getAvailableUserList() {
      if(!this.props.available_users || this.props.available_users.length===0) {
        return (<span/>)
      }
      return (<span>{
        <FormControl>
           <InputLabel>{ETVConstants.trans("change_owner")}</InputLabel>
           <Select
             labelId={"change_owner"}
             id={"change_owner"}
             value={this.state.new_owner_id}
             style={{ width: 200}}
             onChange={(e)=>{this.setState({new_owner_id:e.target.value})}}>
                <MenuItem key={this.props.user_id} value={this.props.user_id}>{this.state.user_name}</MenuItem>
               {
                 this.props.available_users.map(
                     ({user_name,user_id}) => { return <MenuItem key={user_id} value={user_id}>{user_name}</MenuItem> }
                 )
               }
           </Select>
         </FormControl>
      }</span>)
    }

    saveNewOwner() {
      //console.log("saving new owner ("+this.state.new_owner_id+") contentID="+this.props.id);
      var url = ETVConstants.getServerBase()+"/content_library?action=update_owner&user_id="+this.state.new_owner_id+"&content_id="+this.props.id;
      //console.log("--->url="+url);
      ETVConstants.sendCMDToServerListener(url,this.saveNewOwnerResult);
    }

    saveNewOwnerResult(res) {
      //console.log(JSON.stringify(res));
      this.showWindow("show_change_owner_div",false);
    }


    makeImageUrlRelative( url) {
      var res = url.substring(url.indexOf('/contents'));
      return res;
    }

    render() {

      //var id = this.props.item.key;
      var title = this.props.item.filename;
      var desc = this.props.item.description;
      // note: there is a random number in front, in order to allow same image uploaded several times
      title = title.substring(title.indexOf("_")+1, title.length);
      var imageUrl = this.getThumbnail();
      var active = this.props.item.active;
      var isPublic = this.props.item.public;
      var canEdit = (this.props.item.owner_id === this.props.user_id) || this.ownerIsChild(this.props.item.owner_id);

      var cardStyle = {
        display: 'block',
        width: '200px',
        transitionDuration: '0.3s',
        //height: '300px'
      }

      var contentRatio = this.props.item.content_ratio;
      //console.log("xxx contentRatio="+contentRatio);
      var paddingTop = "75%";
      if(contentRatio>0) {
        var pT = 100.0/contentRatio;
        paddingTop = pT+"%";
        //console.log("----->paddingTop="+paddingTop);
        //console.log(imageUrl);
      }
      return (
        <div>
            <Card style={cardStyle}>
              <CardMedia
                //style={{width: '100%', height: 'auto', paddingTop: paddingTop}}
                style={{width: '100%', height: 0, paddingTop: paddingTop}}
                image={ETVConstants.ON_PREMISE_VERSION?this.makeImageUrlRelative(imageUrl):imageUrl}
                title={desc}
              />
              {this.props.item.content_category.length>0?<Chip style={{marginTop:5, marginLeft:5}} label={ETVConstants.trans(this.props.item.content_category)} size="small" color='secondary' />:<Chip style={{marginTop:5,marginLeft:5}} disabled={true} size="small" label={ETVConstants.trans("uncategorized")} variant='outlined' color='default'/>}
              <CardContent>
                <Typography component={'span'} variant={'body2'} color="textSecondary">
                  {this.props.marketplace?<span/>:<div className="cuttext">{title}</div>}
                  <div className="library_item_desc">{desc}</div>
                </Typography>

                {this.props.addedToChannelF?
                   <Button variant="contained" color="primary" onClick={() => this.addToChannel(this.props.id, this.props.channel, this.props.device_id, this.props.group_id)}>{ETVConstants.trans("add")}</Button>
                   :
                  <span>
                    {!active?
                      <Tooltip title={ETVConstants.trans("set_active")}>
                        <span>
                          <IconButton size='small' disabled={!canEdit} aria-label="activate" style={{color:"#ff0000"}} onClick={() => this.props.activeF(this.props.id,!this.props.active)}> <BlockIcon/> </IconButton>
                        </span>
                      </Tooltip>
                      :
                      <Tooltip title={ETVConstants.trans("set_inactive")}>
                        <span>
                          <IconButton size='small' disabled={!canEdit} aria-label="active" style={{color:"#00ad0e"}} onClick={() => this.props.activeF(this.props.id,!this.props.active)}> <CheckIcon/> </IconButton>
                        </span>
                      </Tooltip>
                    }
                    {(this.userCanContributeMarketplace() && !ETVConstants.ON_PREMISE_VERSION)?
                     isPublic?
                      <Tooltip title={ETVConstants.trans("unpublish_from_marketplace")}>
                        <span>
                          <IconButton size='small' disabled={!canEdit} aria-label="make public" color="primary" onClick={() => this.props.publicF(this.props.id,!isPublic)}> <PublicIcon/> </IconButton>
                        </span>
                      </Tooltip>
                      :
                      <Tooltip title={ETVConstants.trans("publish_to_marketplace")}>
                        <span>
                          <IconButton size='small' disabled={!canEdit} aria-label="make public" color="secondary" onClick={() => this.props.publicF(this.props.id,!isPublic)}> <BlockIcon/> </IconButton>
                        </span>
                      </Tooltip>

                    :<span/>
                    }
                    <Tooltip title={ETVConstants.trans("settings")}>
                      <IconButton size='small' aria-label="settings" color='primary' onClick={() => this.props.showSettingsF(this.props.id)}> <SettingsIcon/> </IconButton>
                    </Tooltip>
                    <Tooltip title={ETVConstants.trans("hit_report")}>
                      <IconButton size='small' aria-label="settings" color='primary' onClick={() => this.props.showHitReportF(this.props.id)}> <TimelineIcon/> </IconButton>
                    </Tooltip>

                    {this.props.available_users && this.props.available_users.length>=1?
                      <Tooltip title={ETVConstants.trans("change_owner")}>
                        <IconButton size='small' aria-label="change owner" color='primary' onClick={() => this.showChangeOwner(this.props.id)}> <PersonIcon/> </IconButton>
                      </Tooltip>
                      :
                      <span/>
                    }

                    <Tooltip title={ETVConstants.trans("delete")}>
                      <span>
                      <IconButton size='small' disabled={!canEdit} aria-label="delete" color={canEdit?"secondary":"primary"}  onClick={() => this.props.deleteF(this.props.id)}> <DeleteIcon/> </IconButton>
                      </span>
                    </Tooltip>
                  </span>
                }
              </CardContent>
              <CardActions>
              </CardActions>
            </Card>

            <Dialog
              open={this.state.window==='show_change_owner_div'}
              maxWidth='xs'
              fullWidth={true}
              onClose={()=>{}}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{ETVConstants.trans("change_owner")}</DialogTitle>
              <DialogContent>
                  {this.getAvailableUserList()}
              </DialogContent>
              <DialogActions>
                <Button size="small" onClick={()=>{this.setState({window:'',isLoaded:false})}} color="primary">{ETVConstants.trans("close")}</Button>
                <Button size="small" onClick={()=>{this.saveNewOwner()}} variant="contained" color="secondary">{ETVConstants.trans("save")}</Button>
              </DialogActions>
            </Dialog>
        </div>
      )
    }
}

export default ContentLibraryItem;
