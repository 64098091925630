import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ChipInput from "material-ui-chip-input";
import TextField from '@material-ui/core/TextField';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import * as ETVConstants from '../ETVConstants';

class ErrorHandling extends React.Component {

    constructor(props) {
      super(props);
    }

    state = {
      device_id:'',
      error_handling_config:[],
      handling_changed:false,
      job_definitions:[],
    }

    componentDidMount() {
			this.loadErrorHandling();
      this.loadJobDefinitions();
    }

		componentDidUpdate() {
			if(!this.state.isLoaded) {
				this.loadErrorHandling();
			}
		}

    loadJobDefinitions() {
      console.log("----loading job definitinos");
      var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
      var url = ETVConstants.getServerBase()+"/job_definition?action=list&user_id="+this.props.user_id;
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("jobdefs="+JSON.stringify(result));
            this.setState({
              job_definitions:result.list,
            });
          },
          (error) => {
            this.setState({
              job_definitions: [],
              error
            });
          }
        )
    }

		loadErrorHandling() {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
      var url = ETVConstants.getServerBase()+"/elevator_config?action=get_error_handling&device_id="+this.props.device_id;
      //console.log("url="+url);
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("result="+JSON.stringify(result));
            this.setState({
              error_handling_config:result.list,
              isLoaded: true,
              handling_changed:false,
            });
          },
          (error) => {
            this.setState({
              error_handling_config: {},
              error
            });
          }
        )
    }

    createJobDefinitionSelect(index, elem) {
      return(
        <FormControl style={{width:200}} >
              <InputLabel id="job_definition">{ETVConstants.trans("job_definition")}</InputLabel>
              <Select
                  labelId="job_def_"
                  id="job_def_nr"
                  value={elem.action_param}
                  onChange={(e)=>{this.setActionParam(index,e.target.value)}}>
                  {this.state.job_definitions.map((elem,index) => {return <MenuItem value={elem.job_def_id}> {elem.desc} </MenuItem>})}
              </Select>
        </FormControl>
      )
    }

    saveConfig() {
      var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
      var url = ETVConstants.getServerBase()+"/elevator_config?action=save_error_handling&device_id="+this.props.device_id;
      for(var i=0; i<this.state.error_handling_config.length; i++) {
        url+="&error_type."+i+"="+this.state.error_handling_config[i].error_type;
        url+="&error_value."+i+"="+encodeURIComponent(this.state.error_handling_config[i].error_value);
        url+="&error_action."+i+"="+this.state.error_handling_config[i].error_action;
        url+="&action_param."+i+"="+encodeURIComponent(this.state.error_handling_config[i].action_param);
      }
      //console.log(url);
      ETVConstants.sendCMDToServer(url);
      this.setState({handling_changed:false});
    }

    setErrorType(index,val) {
      var curConfig = this.state.error_handling_config;
      curConfig[index].error_type=val;
      curConfig[index].error_value='';
      this.setState({handling_changed:true,error_handling_config:curConfig});
    }

    setErrorValue(index,val) {
      var curConfig = this.state.error_handling_config;
      curConfig[index].error_value=val;
      this.setState({handling_changed:true,error_handling_config:curConfig});
    }

    setActionType(index,val) {
      var curConfig = this.state.error_handling_config;
      curConfig[index].error_action=val;
      curConfig[index].action_param='';
      this.setState({handling_changed:true,error_handling_config:curConfig});
    }

    setActionParam(index,val) {
      var curConfig = this.state.error_handling_config;
      curConfig[index].action_param=val;
      this.setState({handling_changed:true,error_handling_config:curConfig});
    }

    createActionLine( index, elem) {
      return(
          <div style={{width:800,background:'#f0f2f0',marginTop:10,padding:10}}>
              <FormControl style={{width:200}} >
                    <InputLabel id="error_type">{ETVConstants.trans("error_type")}</InputLabel>
                    <Select
                        labelId="error_type_"
                        id="error_type_nr"
                        value={elem.error_type}
                        onChange={(e)=>{this.setErrorType(index,e.target.value)}}>
                      <MenuItem value={"error_code"}>{ETVConstants.trans("error_code")}</MenuItem>
                      <MenuItem value={"out_of_service_on"}>{ETVConstants.trans("out_of_service")}</MenuItem>
                      <MenuItem value={"working_status"}>{ETVConstants.trans("working_status")}</MenuItem>
                      <MenuItem value={"fire_alarm_on"}>{ETVConstants.trans("fire_alam_on")}</MenuItem>
                      <MenuItem value={"intercom_on"}>{ETVConstants.trans("intercom_on")}</MenuItem>
                      <MenuItem value={"trip_counter"}>{ETVConstants.trans("trip_counter")}</MenuItem>
                    </Select>
              </FormControl>
              <TextField style={{marginLeft:10,width:100}} disabled={elem.error_type==='out_of_service_on' || elem.error_type==='fire_alarm_on' || elem.error_type==='intercom_on'} size="small" multiline={false} id="comment" label={ETVConstants.trans("value")} value={elem.error_value} onChange={(e)=>{this.setErrorValue(index,e.target.value)}} />
            &nbsp;<ArrowForwardIcon size="small"/>&nbsp;
              <FormControl style={{width:200,marginLeft:10}} >
                    <InputLabel id="action">{ETVConstants.trans("action_type")}</InputLabel>
                    <Select
                        labelId="action_type_"
                        id="action_type_nr"
                        value={elem.error_action}
                        onChange={(e)=>{this.setActionType(index,e.target.value)}}>
                      <MenuItem value={"create_job"}>{ETVConstants.trans("create_job")}</MenuItem>
                      <MenuItem value={"send_notification"}>{ETVConstants.trans("send_notification")}</MenuItem>
                      <MenuItem value={"video_call_technician"}>{ETVConstants.trans("video_call_technician")}</MenuItem>
                      <MenuItem value={"sms"}>{ETVConstants.trans("sms")}</MenuItem>
                      <MenuItem value={"email"}>{ETVConstants.trans("email")}</MenuItem>
                      <MenuItem value={"send_cmd_to_controller"}>{ETVConstants.trans("send_cmd_to_controller")}</MenuItem>
                      <MenuItem value={"show_ip_camera"}>{ETVConstants.trans("show_ip_camera")}</MenuItem>
                    </Select>
              </FormControl>
              {elem.error_action==='create_job'?this.createJobDefinitionSelect(index,elem)
               :elem.error_action==='video_call_technician'?<span/>
               :
                <TextField style={{marginLeft:10}} size="small" multiline={false} id="action_param" label={ETVConstants.trans("param")} value={elem.action_param} onChange={(e)=>{this.setActionParam(index,e.target.value)}} />
              }
          </div>
        )
    }

    printErrorHandlingConfig() {
      return(
        this.state.error_handling_config.map( (elem,index) => {return this.createActionLine(index,elem)})
      )
    }

    addAction() {
      var curConfig = this.state.error_handling_config;
      curConfig.push({});
      this.setState({error_handling_config:curConfig});
    }

    render() {
      return(
				<Card>
					<CardContent>
            {this.printErrorHandlingConfig()}
					</CardContent>
					<CardActions>
            <Button size="small" variant="contained" color="primary" onClick={() => this.addAction()}>{ETVConstants.trans("add")}</Button>
            <Button disabled={!this.state.handling_changed} size="small" variant="contained" color="secondary" onClick={() => this.saveConfig()}>{ETVConstants.trans("save")}</Button>
					</CardActions>
				</Card>
			)
	}
}

export default ErrorHandling
