import React from 'react';
import {Card,Button,Divider,TextField} from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InfoMessage2 from '../tools/InfoMessage2';
import * as ETVConstants from '../ETVConstants';

class EditIO extends React.Component {

    state = {
			ioMap: {bit_0:'bit_0_floor',	bit_1:'bit_1_floor', bit_2:'bit_2_floor', bit_3:'bit_3_floor', bit_4:'bit_4_floor', bit_5:'bit_5_floor', bit_6:'bit_6_floor', bit_7:'bit_out_of_service', bit_8:'bit_arriving',bit_9:'bit_overload',bit_10:'bit_up',	bit_11:'bit_down',bit_12:'bit_fire_alarm',bit_13:'bit_door_state_1',bit_14:'bit_door_state_2',bit_15:'bit_intercom'},
			door_map: {closed:'10',opening:'11',open:'01',closing:'11'},
			xor_mask: '000000000000',
			info_message:"",
			info_title:"",
    }

    componentDidMount() {
      this.loadConfig();
    }

		getBit( bitNr, jsonResult) {
			if(bitNr>=jsonResult.length) {
				return -1;
			}
			if (jsonResult[bitNr]) return jsonResult[bitNr];
			return "-1";
		}

    loadConfig() {
      //console.log("---------------------loading deviceIOs");
			var url = ETVConstants.getServerBase()+"/elevator_config?device_id="+this.props.device_id+"&action=get_io_mapping";
      //console.log("loading url="+url);
      fetch(url)
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("items="+JSON.stringify(result));
						var ioMap = {
									bit_0:this.getBit("0",result),
									bit_1:this.getBit("1",result),
									bit_2:this.getBit("2",result),
									bit_3:this.getBit("3",result),
									bit_4:this.getBit("4",result),
									bit_5:this.getBit("5",result),
									bit_6:this.getBit("6",result),
									bit_7:this.getBit("7",result),
									bit_8:this.getBit("8",result),
									bit_9:this.getBit("9",result),
									bit_10:this.getBit("10",result),
									bit_11:this.getBit("11",result),
									bit_12:this.getBit("12",result),
									bit_13:this.getBit("13",result),
									bit_14:this.getBit("14",result),
									bit_15:this.getBit("15",result),
								};
						var closing = result['bits_door_closing'];
						if(closing) {
							closing = closing.substring(0,2);
						}else{
							closing = '11';
						}
						var opening = result['bits_door_opening'];
						if(opening) {
							opening = opening.substring(0,2);
						}else{
							opening = '11';
						}
						var open = result['bits_door_open'];
						if(open) {
							open = open.substring(0,2);
						}else{
							open = '01';
						}
						var closed = result['bits_door_closed'];
						if (closed) {
							closed = closed.substring(0,2);
						}else{
							closed = '10';
						}
						var xorMask = result['xor_mask'];
						var doorMap = {open:open,closing:closing,closed:closed,opening:opening};
						this.setState({ioMap:ioMap,door_map:doorMap,xor_mask:xorMask});
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              error
            });
          }
        )
    }

    showWindow(divID, show) {
      this.setState({show_overlay:show});
      show?this.setState({window:divID}):this.setState({window:''});
    }

		setIOMap( key, value) {
			////console.log("xxx setting key="+key+" value="+value);
			var props = this.state.ioMap;
			////console.log(JSON.stringify(props, null, 2));
			props[key]=value;
			this.setState({ioMap:props});
		}

		showInfoMessage(title,msg) {
			this.showWindow('message_div',true);
			this.setState({info_title:title, info_message:msg});
		}

		closeInfoMessage() {
			this.setState({info_message:undefined});
			this.showWindow('message_div',false);
		}

		setDefault() {
			var defaultMap = {
						bit_0:'bit_0_floor',
						bit_1:'bit_1_floor',
						bit_2:'bit_2_floor',
						bit_3:'bit_3_floor',
						bit_4:'bit_4_floor',
						bit_5:'bit_5_floor',
						bit_6:'bit_6_floor',
						bit_7:'bit_out_of_service',
						bit_8:'bit_arriving',
						bit_9:'bit_overload',
						bit_10:'bit_up',
						bit_11:'bit_down',
						bit_12:'bit_fire_alarm',
						bit_13:'bit_door_state_1',
						bit_14:'bit_door_state_2',
						bit_15:'bit_intercom'};
			this.setState({ioMap:defaultMap});
		}

		setDefaultDoor() {
			var defaultDoor = {closed:'10',opening:'11',open:'01',closing:'11'}
			this.setState({door_map:defaultDoor});
		}

		setDoorConfig( key, value) {
			var props = this.state.door_map;
			//console.log("setDoorConfig key="+key+" value="+value);
			props[key]=value;
			this.setState({door_map:props});
		}

		createDropDown( inputKey, value) {
			var curVal = this.state.ioMap[inputKey];
			//console.log("swVersion="+this.props.sw_version);
			return (
			<FormControl>
				 <InputLabel>Input {inputKey}</InputLabel>
				 <Select
					 labelId={"input_"+inputKey}
					 id={"input_"+inputKey}
					 value={curVal}
					 style={{ width: 200}}
					 onChange={(e)=>{this.setIOMap(inputKey,e.target.value)}}>
					 	<MenuItem value='-1'>--- undefined ---</MenuItem>
						<MenuItem value='bit_0_floor'>FLOOR BIT 0</MenuItem>
						<MenuItem value='bit_1_floor'>FLOOR BIT 1</MenuItem>
						<MenuItem value='bit_2_floor'>FLOOR BIT 2</MenuItem>
						<MenuItem value='bit_3_floor'>FLOOR BIT 3</MenuItem>
						<MenuItem value='bit_4_floor'>FLOOR BIT 4</MenuItem>
						<MenuItem value='bit_5_floor'>FLOOR BIT 5</MenuItem>
						<MenuItem value='bit_6_floor'>FLOOR BIT 6</MenuItem>
						<MenuItem value='bit_7_floor'>FLOOR BIT 7</MenuItem>
						<MenuItem value='bit_8_floor'>FLOOR BIT 8</MenuItem>
						<MenuItem value='bit_9_floor'>FLOOR BIT 9</MenuItem>
						<MenuItem value='bit_up'>arrow UP</MenuItem>
						<MenuItem value='bit_down'>arrow Down</MenuItem>
						<MenuItem value='bit_overload'>Overload</MenuItem>
						<MenuItem value='bit_fire_alarm'>Fire Alarm</MenuItem>
						<MenuItem value='bit_out_of_service'>Out of Service</MenuItem>
						<MenuItem value='bit_arriving'>Arriving Signal</MenuItem>
						<MenuItem value='bit_door_state_1'>Door Input 1</MenuItem>
						<MenuItem value='bit_door_state_2'>Door Input 2</MenuItem>
						<MenuItem value='bit_intercom'>Intercom  Signal</MenuItem>
						<MenuItem value='bit_maintenance'>Maintenance (AKP85+)</MenuItem>
						<MenuItem value='bit_emergency_power'>Emergency Power (APK85+)</MenuItem>
						<MenuItem value='bit_service_upper_deck'>Service Upper Deck (APK85+)</MenuItem>
						<MenuItem value='bit_service_lower_deck'>Service Lower Deck (APK85+)</MenuItem>
						<MenuItem value='bit_special_io_1'>Special IO 1 (APK85+)</MenuItem>
						<MenuItem value='bit_special_io_2'>Special IO 2 (APK85+)</MenuItem>
						<MenuItem value='bit_special_io_3'>Special IO 3 (APK85+)</MenuItem>
						<MenuItem value='bit_special_io_4'>Special IO 4 (APK85+)</MenuItem>
				 </Select>
			 </FormControl>
		 )
		}

		createDoorDropDown(inputKey) {
			//console.log("inputKey="+inputKey);
			var curVal = this.state.door_map[inputKey];
			//console.log("inputKey="+inputKey+" curVal="+curVal);
			return (
			<FormControl>
				 <InputLabel>{inputKey}</InputLabel>
				 <Select
					 labelId={"input_"+inputKey}
					 id={"input_"+inputKey}
					 value={curVal}
					 style={{ width: 200}}
					 onChange={(e)=>{this.setDoorConfig(inputKey,e.target.value)}}>
						<MenuItem value='00'>00</MenuItem>
						<MenuItem value='01'>01</MenuItem>
						<MenuItem value='11'>11</MenuItem>
						<MenuItem value='10'>10</MenuItem>
				 </Select>
			 </FormControl>
		 )
		}

		getValue( bitNr) {
			return this.state.ioMap["bit_"+bitNr];
		}

		saveIO() {
			this.showInfoMessage("IO Mapping","Saving IO Mapping...");
			var url = ETVConstants.getServerBase()+"/elevator_config?action=save_io_mapping&device_id="+this.props.device_id;
			//console.log("saving settings");
			for(var i=0; i<16; i++) {
				var val = this.state.ioMap["bit_"+i];
				if (val!=='-1') {
					url += "&"+val+"="+i;
				}
			}
			// add doorstate
			var closed = this.state.door_map["closed"];
			var closing = this.state.door_map["closing"];
			var open = this.state.door_map["open"];
			var opening = this.state.door_map["opening"];
			url += "&bits_door_open="+open+opening;
			url += "&bits_door_opening="+opening+closed;
			url += "&bits_door_closed="+closed+closing;
			url += "&bits_door_closing=" +closing+open;
			url += "&xor_mask="+this.state.xor_mask;
			//console.log("----------> aaaa URL="+url);
			fetch(url)
				.then(res => res.json())
				.then(
					(result) => {
						//console.log("Result="+result);
						this.showInfoMessage("IO Mapping","success");
						//console.log("xxxxxxxxxxxxxx="+result["000000"]);
					},
					// Note: it's important to handle errors here
					// instead of a catch() block so that we don't swallow
					// exceptions from actual bugs in components.
					(error) => {
						//console.log("EERRROR="+error);
						this.setState({
							error
						});
					}
				)
		}

    render() {

      return(
				<div>
						<Typography variant="h6">
							ADAM Input Mapping: {this.props.device_id}
						</Typography>
						<Button size='small' color="primary" variant='contained' onClick={() => this.setDefault()}>Set Default</Button>
						<table style={{marginTop:10}}>
							<tbody>
								<tr>
									<td><div>{this.createDropDown('bit_0')}</div><br/></td>
									<td><div>{this.createDropDown('bit_8')}</div><br/></td>
								</tr>
								<tr>
									<td><div>{this.createDropDown('bit_1')}</div><br/></td>
									<td><div>{this.createDropDown('bit_9')}</div><br/></td>
								</tr>
								<tr>
									<td><div>{this.createDropDown('bit_2')}</div><br/></td>
									<td><div>{this.createDropDown('bit_10')}</div><br/></td>
								</tr>
								<tr>
									<td><div>{this.createDropDown('bit_3')}</div><br/></td>
									<td><div>{this.createDropDown('bit_11')}</div><br/></td>
								</tr>
								<tr>
									<td><div>{this.createDropDown('bit_4')}</div><br/></td>
									<td><div>{this.createDropDown('bit_12')}</div><br/></td>
								</tr>
								<tr>
									<td><div>{this.createDropDown('bit_5')}</div><br/></td>
									<td><div>{this.createDropDown('bit_13')}</div><br/></td>
								</tr>
								<tr>
									<td><div>{this.createDropDown('bit_6')}</div><br/></td>
									<td><div>{this.createDropDown('bit_14')}</div><br/></td>
								</tr>
								<tr>
									<td><div>{this.createDropDown('bit_7')}</div><br/></td>
									<td><div>{this.createDropDown('bit_15')}</div><br/></td>
								</tr>
							</tbody>
						</table>
						<br/>
						<Divider/>
						<br/>
						<Typography variant="h6">
							Digital IO Inversion (XOR Mask | Bit 0 at end)
						</Typography>
						<br/>
							<TextField size='small' key='xor_mask' label="XOR MASK" variant='outlined' value={this.state.xor_mask} onChange={(e)=>{this.setState({xor_mask:e.target.value})}} />
						<br/><br/>
							<Divider/>
						<br/>
						<Typography variant="h6">
							Setting Door Behaviour
						</Typography>
						<Button size='small' color="primary" variant='contained' onClick={() => this.setDefaultDoor()}>Set Default</Button>
						<table style={{marginTop:10}}>
							<tbody>
								<tr>
									<td><div>{this.createDoorDropDown('closed')}</div><br/></td>
								</tr>
								<tr>
									<td><div>{this.createDoorDropDown('opening')}</div><br/></td>
								</tr>
								<tr>
									<td><div>{this.createDoorDropDown('open')}</div><br/></td>
								</tr>
								<tr>
									<td><div>{this.createDoorDropDown('closing')}</div><br/></td>
								</tr>
							</tbody>
						</table>
						<br/>
						<Divider/>
						<br/>
						<Typography variant="h6">
							Out of Service Notification
						</Typography>
						<table style={{marginTop:10}}>
							<tbody>
								<tr>
										<td><div><TextField size='small' key='email' label="email" variant='outlined' value='' /></div></td>
								</tr>
								<tr>
										<td><div><TextField size='small' key='sms' label="SMS" variant='outlined' value='' /></div></td>
								</tr>
								<tr>
										<td><div><TextField size='small' key='trigger' label="trigger [min]" variant='outlined' value='' /></div></td>
								</tr>
							</tbody>
						</table>
						<br/>
						<Divider/>
						<br/>
						 <Button variant="contained" color="secondary" onClick={() => this.saveIO()}>Save Settings</Button>
						<br/>
						<div id='overlay_gray' style={{display: this.state.show_overlay === true ? 'block' : 'none'}}>
	               <div id='message_div' style={{display:this.state.window==='message_div'?'block':'none'}}>
	                   <Card>
	                       <CardContent>
	                         <InfoMessage2 title={this.state.info_title} message={this.state.info_message} />
	                       </CardContent>
	                       <CardActions>
	                         <Button color="primary" variant='outlined' onClick={() => this.closeInfoMessage()}>Close</Button>
	                       </CardActions>
	                   </Card>
	               </div>
	          </div>
		   	</div>
			);
	}
}

export default EditIO
