import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {People, Work, AddLocation, Visibility, ThumbUp} from '@material-ui/icons';
import Create3rdPartyUserTable from './usermgmt/Create3rdPartyUserTable';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import {AppBar,Toolbar,Button} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SearchIcon from '@material-ui/icons/Search';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ImageIcon from '@material-ui/icons/Image';
import ListIcon from '@material-ui/icons/List';
import DeviceMgmt from './devicemgmt/DeviceMgmt';
import GroupMgmt from './devicemgmt/GroupMgmt';
import RemoteDeviceMgmt from './devicemgmt/RemoteDeviceMgmt';
import ElevatorMgmt from './elevator/ElevatorMgmt';
import ElevatorStatistics from './elevator/ElevatorStatistics';
import ContentLibrary from './contentmgmt/ContentLibrary';
import Playlist from './contentmgmt/Playlist'
import TvIcon from '@material-ui/icons/Tv';
import { Divider } from '@material-ui/core';
import EditText from './contentmgmt/EditText';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import LayoutDesigner from './devicemgmt/LayoutDesigner';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import WeatherInfo from './contentmgmt/WeatherInfo';
import SurveyMgmt from './contentmgmt/SurveyMgmt';
import VoucherMgmt from './contentmgmt/VoucherMgmt';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SettingsIcon from '@material-ui/icons/Settings';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PollIcon from '@material-ui/icons/Poll';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import InfoIcon from '@material-ui/icons/Info';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';
import Support from './about/Support';
import AuditLog from './about/AuditLog';
import AccountUpgrade from './about/AccountUpgrade';
import EditUserAccount from './about/EditUserAccount';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PaymentIcon from '@material-ui/icons/Payment';
import SecurityMgmtIPCamera from './security/SecurityMgmtIPCamera';
import SecurityMgmt from './security/SecurityMgmt';
import VideocamIcon from '@material-ui/icons/Videocam';
import SecurityIcon from '@material-ui/icons/Security';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import Countdown from './contentmgmt/Countdown';
import FloorDirectory from './contentmgmt/FloorDirectory';
import RSSCreator from './contentmgmt/RSSCreator';
import Newsticker from './contentmgmt/Newsticker';
import Calendar from './contentmgmt/Calendar';
import PublicIcon from '@material-ui/icons/Public';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import EventIcon from '@material-ui/icons/Event';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FormattedMessage} from 'react-intl';
import ReactCountryFlag from "react-country-flag";
import ServiceTechnician from './elevator/ServiceTechnician';
import JobDefinition from './elevator/JobDefinition';
import Jobs from './elevator/Jobs';
import ServiceTechnicianIcon from '@material-ui/icons/Accessibility';
import CallIcon from '@material-ui/icons/Call';
import BuildIcon from '@material-ui/icons/Build';
import WorkIcon from '@material-ui/icons/Work';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Whatsapp from './contentmgmt/Whatsapp';
import OnPremisesServers from './devicemgmt/OnPremisesServers';
import ServersIcon from '@material-ui/icons/Dns';
import * as ETVConstants from './ETVConstants';

const list_width = '270px';
const header_height = '60px';
const marginL = '10px';

const useStyles = makeStyles((theme) => ({
  root: {
    background:'white',
    display:'flex',
    marginLeft:'0px',
    margin: '5px',
    color:'#1C1C1C',
  },

  appBar: {
    zIndex:1,
    position:'fixed',
    background:'#FFFFFF',
    height:header_height,
    marginLeft: 'auto',
  },

  list: {
    zIndex:0,
    background:'white',
    position:'flex',
    marginTop: header_height,
    width: list_width,
    maxWidth:list_width,
    minWidth:list_width,
    backgroundColor:'white',
    color:'#1C1C1C',
  },

  nested: {
    paddingLeft: '50px',
    color:'black',
  },

  content: {
    zIndex:0,
    background: 'white',
    marginLeft: marginL,
    marginTop: header_height,
  }
}));


export default function Portal(props) {

  const classes = useStyles();

  // content
  const [open1, setOpen1] = React.useState(false);
  const handleClick1 = () => {
    setOpen1(!open1);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
  };

  // device
  const [open2, setOpen2] = React.useState(false);
  const handleClick2 = () => {
    setOpen1(false);
    setOpen2(!open2);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
  };

  const [open3, setOpen3] = React.useState(false);
  const handleClick3 = () => {
    setOpen1(false);
    setOpen2(false);
    setOpen3(!open3);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
  };

  const [open4, setOpen4] = React.useState(false);
  const handleClick4 = () => {
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(!open4);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
  };

  const [open5, setOpen5] = React.useState(false);
  const handleClick5 = () => {
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(!open5);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
  };

  const [open6, setOpen6] = React.useState(false);
  const handleClick6 = () => {
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(!open6);
    setOpen7(false);
    setOpen8(false);
  };

  const [open7, setOpen7] = React.useState(false);
  const handleClick7 = () => {
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(!open7);
    setOpen8(false);
  };

  const [open8, setOpen8] = React.useState(false);
  const handleClick8 = () => {
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(!open8);
  };

  const [curPage, setPage] = React.useState(-1);
  const showPage = (whichPage) => {
    //console.log("showPage="+whichPage);
    setPage(whichPage);
  }

  const setLang = (lang) => {
    var accessToken = localStorage.getItem("etv.access_token");
    var reqUserID = localStorage.getItem("etv.user_id");
    var url = ETVConstants.getServerBase()+'/useradmin?action=set_language&user_id='+props.user_id+"&lang="+lang;
    fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson["result"]) {
        props.langCallback(lang);
      }
    })
    .catch((error) => {
    });
  }

  function trans( msgID) {
    return <FormattedMessage id={msgID} defaultMessage={"["+msgID+"]"} />
  }

///////////////////////////////////////////////////////////////////////////////
// roles
// [library,marketplace,playlist,create_text,weather_info,surveys,coupons,reservations,device_configuration,remote_management,layout_designer,user_management,elevator_config,elevator_statistics]
///////////////////////////////////////////////////////////////////////////////

  function hasCategory( cat) {
    for(var i=0; i<props.roles.length; i++) {
      //console.log("hasCategory="+cat+" [ "+props.roles[i]+"]");
      if(props.roles[i].indexOf("."+cat+".")>0) {
        //console.log("...yes");
        return true;
      }
    }
    //console.log("...no");
    return false;
  }

  function hasRole(role) {
    for(var i=0; i<props.roles.length; i++) {
      var r = props.roles[i].substring(props.roles[i].lastIndexOf(".")+1,props.roles[i].length);
      if(r===role) return true;
    }
    return false;
    //return props.roles.includes(role);
  }

  function getUserStatus() {
    return props.status;
  }

  // library,marketplace,playlist,create_text,weather_info,surveys,coupons,reservations
  function hasContentCategory() {
    return hasCategory("content");
  }

  function hasDeviceCategory() {
    //console.log("======>"+props.status);
    if(props.status==='marketplace_provider') return false;
    return true; //everyone shall have the device grouping
    //return hasCategory("device");
  }

  function hasUserCategory() {
    return hasCategory("usermgmt");
  }

  function hasElevatorCategory() {
    return hasCategory("elevator");
  }

  function hasSecurityCategory() {
    return hasCategory("security");
  }

  function isJobAdmin() {
    // maybe used in future
    return false;
  }

  function canRelease() {
    return false;
  }

  function canReview() {
    return false;
  }

////////////////////////////////////////////////////////
// menu AppBar
////////////////////////////////////////////////////////

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function isOnPremise( domain) {
    var temp = domain.trim().toLowerCase();
    if(temp === 'localhost') return true;
    if(temp.startsWith('10.')) return true;
    if(temp.startsWith('172.')) return true;
    if(temp.startsWith('127.')) return true;
    if(temp.startsWith('192.168.')) return true;
    return false;
  };

  function getLogo() {
      var domain = window.location.hostname;
      if(ETVConstants.ON_PREMISE_VERSION) return "/images/elevator_onpremise.jpg";
      if(!domain || domain.trim().toLowerCase().endsWith('elevator.tv')) return "/images/logo.jpg";
      if(domain.trim().toLowerCase().endsWith('emch.com')) return "/images/emch_logo.png";
      if(domain.trim().toLowerCase().endsWith('lift.tv')) return "/images/lifttvlogo.png";
      if(domain.trim().toLowerCase().endsWith('nle.jp')) return "/images/nlelogo.png";
      //default
      return "/images/logo.jpg";
  }

  function isSuperAdmin() {
    return (props.status === "superadmin");
  }

  function hasWhatsApp() {
    return true;
    //return isSuperAdmin();
  }

  //const messages = {en,de};

  //<MenuItem value='fr'><ReactCountryFlag countryCode="fr" svg />&nbsp;Francés</MenuItem>
  //<MenuItem value='es'><ReactCountryFlag countryCode="es" svg />&nbsp;Español</MenuItem>
  //<MenuItem value='ja'><ReactCountryFlag countryCode="jp" svg />&nbsp;日本</MenuItem>

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
            <Box display='flex' flexGrow={1}><img style={{height:50}} src={getLogo()} alt=''/></Box>
            <FormControl>
                <Select
                    labelId="language"
                    id="language"
                    autoWidth
                    value={props.lang}
                    onChange={(e)=>{setLang(e.target.value)}}>
                     <MenuItem value='en'><ReactCountryFlag countryCode="us" svg />&nbsp;English</MenuItem>
                     <MenuItem value='de'><ReactCountryFlag countryCode="de" svg />&nbsp;Deutsch</MenuItem>
                     <MenuItem value='jp'><ReactCountryFlag countryCode="jp" svg />&nbsp;Japanese</MenuItem>
                </Select>
            </FormControl>
            <span style={{marginLeft:30}} />
            <AccountCircleIcon color='primary' fontSize='large'/>
            <span style={{marginLeft:10}} />
            <Typography variant='body1' style={{color:'black'}}>{props.username}</Typography>
            <span style={{marginLeft:30}} />
            <Button color='default' fontSize='large' variant='contained' onClick={()=>{props.loggedInCallback(false)}}><Typography variant='body1'>{trans("logout")}</Typography><ExitToAppIcon /></Button>
        </Toolbar>
      </AppBar>

      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
          </ListSubheader>
        }
        className={classes.list} >

        {!hasContentCategory()?<span/>:<div>

            <ListItem button onClick={handleClick1} style={open1?{background:'#e9eff2'}:{}}>
              <ListItemIcon>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText primary={trans("content")} />
              {open1 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {!hasRole("playlist")?<span/>:
                  <ListItem button onClick={()=>showPage(10)} className={classes.nested}>
                    <ListItemIcon><ListIcon/></ListItemIcon>
                    <ListItemText primary={trans("playlist")} />
                  </ListItem>
                }
                {!hasRole("library")?<span/>:
                  <ListItem button onClick={()=>showPage(11)} className={classes.nested}>
                    <ListItemIcon><PhotoLibraryIcon/></ListItemIcon>
                    <ListItemText primary={trans("library")} />
                  </ListItem>
                }
                {!hasRole("create_text")?<span/>:
                  <ListItem button onClick={()=>showPage(12)} className={classes.nested}>
                    <ListItemIcon><TextFieldsIcon/></ListItemIcon>
                    <ListItemText primary={trans("create_text")} />
                  </ListItem>
                }
                {!hasRole("create_text")?<span/>:
                  <ListItem button onClick={()=>showPage(13)} className={classes.nested}>
                    <ListItemIcon><TextFieldsIcon/></ListItemIcon>
                    <ListItemText primary={trans("ticker_text")} />
                  </ListItem>
                }
                {!hasRole("weather_info") || ETVConstants.ON_PREMISE_VERSION?<span/>:
                  <ListItem button onClick={()=>showPage(14)} className={classes.nested}>
                    <ListItemIcon><WbSunnyIcon/></ListItemIcon>
                    <ListItemText primary={trans("weather_info")} />
                  </ListItem>
                }
                {!hasRole("surveys") || ETVConstants.ON_PREMISE_VERSION?<span/>:
                  <ListItem button onClick={()=>showPage(15)} className={classes.nested}>
                    <ListItemIcon><PollIcon/></ListItemIcon>
                    <ListItemText primary={trans("surveys")} />
                  </ListItem>
                }
                {!hasRole("coupons") || ETVConstants.ON_PREMISE_VERSION?<span/>:
                  <ListItem button onClick={()=>showPage(16)} className={classes.nested}>
                    <ListItemIcon><CardGiftcardIcon/></ListItemIcon>
                    <ListItemText primary={trans("coupons")} />
                  </ListItem>
                }
                {!hasRole("create_text") || ETVConstants.ON_PREMISE_VERSION?<span/>:
                  <ListItem button onClick={()=>showPage(17)} className={classes.nested}>
                    <ListItemIcon><AvTimerIcon/></ListItemIcon>
                    <ListItemText primary={trans("countdown_ticker")} />
                  </ListItem>
                }
                {!hasRole("playlist")?<span/>:
                  <ListItem button onClick={()=>showPage(18)} className={classes.nested}>
                    <ListItemIcon><ListAltIcon/></ListItemIcon>
                    <ListItemText primary={trans("floor_directory")} />
                  </ListItem>
                }
                {!hasRole("create_text") || ETVConstants.ON_PREMISE_VERSION?<span/>:
                  <ListItem button onClick={()=>showPage(19)} className={classes.nested}>
                    <ListItemIcon><RssFeedIcon/></ListItemIcon>
                    <ListItemText primary={trans("rss_feeds")} />
                  </ListItem>
                }
                {!hasRole("create_text") || ETVConstants.ON_PREMISE_VERSION?<span/>:
                  <ListItem button onClick={()=>showPage(191)} className={classes.nested}>
                    <ListItemIcon><EventIcon/></ListItemIcon>
                    <ListItemText primary={trans("calendar")} />
                  </ListItem>
                }
                {!hasRole("create_text") || ETVConstants.ON_PREMISE_VERSION?<span/>:
                  <ListItem button onClick={()=>showPage(192)} className={classes.nested}>
                    <ListItemIcon><WhatsAppIcon/></ListItemIcon>
                    <ListItemText primary={trans("Whatsapp")} />
                  </ListItem>
                }
              </List>
            </Collapse>
            <Divider/>
        </div>}

        {!hasDeviceCategory()?<span/>:<div>
            <ListItem button onClick={handleClick2} style={open2?{background:'#e9eff2'}:{}}>
              <ListItemIcon>
                <TvIcon />
              </ListItemIcon>
              <ListItemText primary={trans("screens")} />
              {open2 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {!hasRole("device_configuration")?<span/>:
                  <ListItem button onClick={()=>showPage(20)} className={classes.nested}>
                    <ListItemIcon><FormatListBulletedIcon/></ListItemIcon>
                    <ListItemText primary={trans("screens")} />
                  </ListItem>
                }
                {!hasRole("device_configuration")?<span/>:
                  <ListItem button onClick={()=>showPage(23)} className={classes.nested}>
                    <ListItemIcon><FormatListBulletedIcon/></ListItemIcon>
                    <ListItemText primary={trans("grouping")} />
                  </ListItem>
                }
                {!hasRole("remote_management")?<span/>:
                  <ListItem button onClick={()=>showPage(21)} className={classes.nested}>
                    <ListItemIcon><VisibilityIcon/></ListItemIcon>
                    <ListItemText primary={trans("remote_management")} />
                  </ListItem>
                }
                {!hasRole("layout_designer")?<span/>:
                  <ListItem button onClick={()=>showPage(22)} className={classes.nested}>
                    <ListItemIcon><ViewCompactIcon/></ListItemIcon>
                    <ListItemText primary={trans("layout_designer")} />
                  </ListItem>
                }
                {!isSuperAdmin() || ETVConstants.ON_PREMISE_VERSION?<span/>:
                  <ListItem button onClick={()=>showPage(24)} className={classes.nested}>
                    <ListItemIcon><ServersIcon/></ListItemIcon>
                    <ListItemText primary={trans("OnPremisesServers")} />
                  </ListItem>
                }
              </List>
            </Collapse>
            <Divider/>
        </div>
        }

        {!hasUserCategory()?<span/>:<div>
          <ListItem button onClick={handleClick3} style={open3?{background:'#e9eff2'}:{}}>
            <ListItemIcon>
              <People />
            </ListItemIcon>
            <ListItemText primary={trans("user_mgmt")} />
            {open3 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open3} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {!hasRole("user_management")?<span/>:
                  <ListItem button onClick={()=>showPage(30)} className={classes.nested}>
                    <ListItemIcon><FormatListBulletedIcon/></ListItemIcon>
                    <ListItemText primary={trans("overview")} />
                  </ListItem>
              }
            </List>
          </Collapse>
          <Divider/>
        </div>}

        {isJobAdmin() ? <div>
            <ListItem button onClick={handleClick4} style={open4?{background:'#e9eff2'}:{}}>
              <ListItemIcon>
                <Work />
              </ListItemIcon>
              <ListItemText primary="Jobs" />
              {open4 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open4} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>

                <ListItem button onClick={()=>showPage(40)} className={classes.nested}>
                  <ListItemIcon><FormatListBulletedIcon/></ListItemIcon>
                  <ListItemText primary="Overview" />
                </ListItem>

                {canRelease()?
                  <ListItem button onClick={()=>showPage(41)} className={classes.nested}>
                    <ListItemIcon><ThumbUp/></ListItemIcon>
                    <ListItemText primary="Release" />
                  </ListItem>:<span/>}

                {canReview()?
                  <ListItem button onClick={()=>showPage(42)} className={classes.nested}>
                    <ListItemIcon><Visibility/></ListItemIcon>
                    <ListItemText primary="Review" />
                  </ListItem>:<span/>}

                <ListItem button onClick={()=>showPage(43)} className={classes.nested}>
                    <ListItemIcon><SearchIcon/></ListItemIcon>
                    <ListItemText primary="Database" />
                </ListItem>

              </List>
            </Collapse>
            <Divider/>
            </div>
        :<span/>}

        {!isJobAdmin()?<div/>:<div>
            <ListItem button onClick={handleClick5} style={open5?{background:'#e9eff2'}:{}}>
              <ListItemIcon>
                <HomeWorkIcon />
              </ListItemIcon>
              <ListItemText primary="Installations" />
              {open5 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open5} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button onClick={()=>showPage(50)} className={classes.nested}>
                  <ListItemIcon><FormatListBulletedIcon/></ListItemIcon>
                  <ListItemText primary="Overview" />
                </ListItem>
                <ListItem button onClick={()=>showPage(51)} className={classes.nested}>
                  <ListItemIcon><AddLocation/></ListItemIcon>
                  <ListItemText primary="Map" />
                </ListItem>
              </List>
            </Collapse>
            <Divider/>
          </div>}

          {!hasElevatorCategory()?<span/>:<div>
            <ListItem button onClick={handleClick6} style={open6?{background:'#e9eff2'}:{}}>
              <ListItemIcon>
                <ArrowUpwardIcon/>
              </ListItemIcon>
              <ListItemText primary={trans("elevator_mgmt")} />
              {open6 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open6} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {!hasRole("elevator_config")?<span/>:
                    <ListItem button onClick={()=>showPage(60)} className={classes.nested}>
                      <ListItemIcon><FormatListBulletedIcon/></ListItemIcon>
                      <ListItemText primary={trans("elevators")} />
                    </ListItem>
                }
                {!hasRole("elevator_config")?<span/>:
                    <ListItem button onClick={()=>showPage(62)} className={classes.nested}>
                      <ListItemIcon><ServiceTechnicianIcon/></ListItemIcon>
                      <ListItemText primary={trans("service_technician")} />
                    </ListItem>
                }
                {!hasRole("elevator_config")?<span/>:
                    <ListItem button onClick={()=>showPage(63)} className={classes.nested}>
                      <ListItemIcon><WorkIcon/></ListItemIcon>
                      <ListItemText primary={trans("job_definition")} />
                    </ListItem>
                }
                {!hasRole("elevator_config")?<span/>:
                    <ListItem button onClick={()=>showPage(64)} className={classes.nested}>
                      <ListItemIcon><BuildIcon/></ListItemIcon>
                      <ListItemText primary={trans("jobs")} />
                    </ListItem>
                }
                {!hasRole("elevator_statistics") || ETVConstants.ON_PREMISE_VERSION?<span/>:
                  <ListItem button onClick={()=>showPage(61)} className={classes.nested}>
                    <ListItemIcon><EqualizerIcon/></ListItemIcon>
                    <ListItemText primary={trans("statistics")} />
                  </ListItem>
                }
              </List>
            </Collapse>
            <Divider/>
          </div>}

          {!hasElevatorCategory()|| ETVConstants.ON_PREMISE_VERSION?<span/>:<div>
            <ListItem button onClick={handleClick7} style={open7?{background:'#e9eff2'}:{}}>
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText primary={trans("security_mgmt")} />
              {open7 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open7} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                    <ListItem button onClick={()=>showPage(70)} className={classes.nested}>
                      <ListItemIcon><VideocamIcon/></ListItemIcon>
                      <ListItemText primary={trans("ip_camera")} />
                    </ListItem>
                    <ListItem button onClick={()=>showPage(71)} className={classes.nested}>
                      <ListItemIcon><VideocamIcon/></ListItemIcon>
                      <ListItemText primary={trans("usb_camera")} />
                    </ListItem>
              </List>
            </Collapse>
            <Divider/>
          </div>}

          <div>
            <ListItem button onClick={handleClick8} style={open8?{background:'#e9eff2'}:{}}>
              <ListItemIcon>
                <InfoIcon/>
              </ListItemIcon>
              <ListItemText primary={trans("about")} />
              {open8 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open8} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                  <ListItem button onClick={()=>showPage(80)} className={classes.nested}>
                    <ListItemIcon><AccountCircleIcon/></ListItemIcon>
                    <ListItemText primary={trans("my_details")} />
                  </ListItem>
                  {!ETVConstants.ON_PREMISE_VERSION && (getUserStatus()==='offline' || getUserStatus()==='basic' || getUserStatus()==='premium' || getUserStatus()==='enterprise')?
                    <ListItem button onClick={()=>showPage(81)} className={classes.nested}>
                      <ListItemIcon><PaymentIcon/></ListItemIcon>
                      <ListItemText primary={trans("subscription")} />
                    </ListItem>
                    :
                    <span/>
                  }
                  { ETVConstants.ON_PREMISE_VERSION?<span/>:
                    <ListItem button onClick={()=>showPage(82)} className={classes.nested}>
                      <ListItemIcon><HelpIcon/></ListItemIcon>
                      <ListItemText primary={trans("support")} />
                    </ListItem>
                  }
                  <ListItem button onClick={()=>showPage(83)} className={classes.nested}>
                    <ListItemIcon><ListIcon/></ListItemIcon>
                    <ListItemText primary={trans("audit_logs")} />
                  </ListItem>
              </List>
            </Collapse>
          </div>
      </List>
      <main className={classes.content}>
          {
            curPage===10 ?<Playlist lang={props.lang} user_id={props.user_id} children={props.children} roles={props.roles} />:
            curPage===11 ?<ContentLibrary lang={props.lang} user_id={props.user_id} children={props.children} marketplace={false} roles={props.roles} />:
            curPage===12 ?<EditText lang={props.lang} user_id={props.user_id} roles={props.roles} />:
            curPage===13 ?<Newsticker lang={props.lang} user_id={props.user_id} roles={props.roles} />:
            curPage===14 ?<WeatherInfo lang={props.lang} user_id={props.user_id} roles={props.roles} />:
            curPage===15 ?<SurveyMgmt lang={props.lang} user_id={props.user_id} />:
            curPage===16 ?<VoucherMgmt lang={props.lang} user_id={props.user_id} />:
            curPage===17 ?<Countdown lang={props.lang} user_id={props.user_id} />:
            curPage===18 ?<FloorDirectory lang={props.lang} user_id={props.user_id} />:

            curPage===19 ?<RSSCreator lang={props.lang} user_id={props.user_id} />:
            curPage===191 ?<Calendar lang={props.lang} user_id={props.user_id} />:
            curPage===192 ?<Whatsapp lang={props.lang} user_id={props.user_id} />:

            curPage===20 ?<DeviceMgmt lang={props.lang} user_id={props.user_id} roles={props.roles} status={props.status} />:
            curPage===21 ?<RemoteDeviceMgmt lang={props.lang} user_id={props.user_id} status={props.status} />:
            curPage===22 ?<LayoutDesigner lang={props.lang} user_id={props.user_id} />:
            curPage===23 ?<GroupMgmt lang={props.lang} user_id={props.user_id} roles={props.roles} status={props.status} />:
            curPage===24 ?<OnPremisesServers lang={props.lang} user_id={props.user_id} />:

            curPage===30 ?<Create3rdPartyUserTable lang={props.lang} user_id={props.user_id} roles={props.roles} status={props.status} />:

            curPage===60 ?<ElevatorMgmt lang={props.lang} user_id={props.user_id} roles={props.roles} />:
            curPage===61 ?<ElevatorStatistics lang={props.lang} user_id={props.user_id} roles={props.roles} />:
            curPage===62 ?<ServiceTechnician lang={props.lang} user_id={props.user_id} roles={props.roles} />:
            curPage===63 ?<JobDefinition lang={props.lang} user_id={props.user_id} roles={props.roles} />:
            curPage===64 ?<Jobs lang={props.lang} user_id={props.user_id} roles={props.roles} />:

            curPage===70 ?<SecurityMgmtIPCamera lang={props.lang} user_id={props.user_id} roles={props.roles} />:
            curPage===71 ?<SecurityMgmt lang={props.lang} user_id={props.user_id} roles={props.roles} />:

            curPage===80 ?<EditUserAccount lang={props.lang} user_id={props.user_id} status={props.status} roles={props.roles} />:
            curPage===81 ?<AccountUpgrade lang={props.lang} user_id={props.user_id} />:
            curPage===82 ?<Support lang={props.lang} user_id={props.user_id} />:
            curPage===83 ?<AuditLog lang={props.lang} user_id={props.user_id} />:
            <span/>
          }
      </main>
    </div>
  );
}
