import React from 'react';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import SelectAvailableScreensNew from './SelectAvailableScreensNew';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import BasicTable2 from "../tools/BasicTable2";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import * as ETVConstants from '../ETVConstants';

class Newsticker extends React.Component {

    constructor(props) {
      super(props);
      this.selectedScreens = this.selectedScreens.bind(this);
      this.closeScreenSelection = this.closeScreenSelection.bind(this);
    }

    componentDidMount() {
      this.loadItems();
    }

    componentDidUpdate() {
      if(!this.state.isLoaded) {
        this.loadItems();
      }
    }

    state = {
      items:[],
      isLoaded:false,
      edit_id:'',
      edit_name:'',
      edit_value:'',
      edit_textColor:'#FFFFFF',
      edit_bgColor:'#000000',
      edit_size:'medium',
      selected_screens: [],
    }

    selectedScreens(screenIDs) {
      //console.log("selected screens="+screenIDs);
      this.showWindow('select_screens_div',false);
      this.setState({selected_screens:screenIDs});
    }

    closeScreenSelection() {
      this.showWindow('select_screens_div',false);
    }

    showWindow(divID, show) {
      show?this.setState({window:divID}):this.setState({window:''});
    }

    showInfoMessage(msg) {
      this.setState({window:'message_div',info_message:msg});
    }

    closeInfoMessage() {
      this.setState({info_message:undefined});
      this.showWindow('message_div',false);
    }

    loadItems() {
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      var url = ETVConstants.getServerBase()+"/marquee?action=list_marquees&user_id="+this.props.user_id;
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log(JSON.stringify(result));
            this.setState({
              items: result.data,
              isLoaded: true,
            });
          },
          (error) => {
            this.setState({
              items:[],
              isLoaded: true,
            });
          }
        )
    }

    addNewTickers() {
      if(this.state.selected_screens.length===0) {
        this.showInfoMessage(ETVConstants.trans("no_screen_selected"));
        return;
      }
      this.showInfoMessage(ETVConstants.trans("saving_ticker"));
      var url = ETVConstants.getServerBase()+"/marquee?action=save&bg_color="+encodeURIComponent(this.state.edit_bgColor)+"&text_size="+this.state.edit_size+"&text_color="+encodeURIComponent(this.state.edit_textColor);
      url += "&ticker_text="+encodeURIComponent(this.state.edit_value);
      if(this.state.selected_screens && this.state.selected_screens.length>0) {
        for(var i=0; i<this.state.selected_screens.length; i++) {
          url += "&screen_id="+this.state.selected_screens[i];
        }
      }
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      //console.log(url);
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({isLoaded:false,edit_value:'',selected_screens:[]});
            this.showWindow("edit_div",false);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.showInfoMessage("error happened: "+error);
          }
        )
    }

    updateTicker() {
      //this.showInfoMessage(ETVConstants.trans("update ticker"));
      var url = ETVConstants.getServerBase()+"/marquee?action=save&screen_id="+this.state.edit_id+"&bg_color="+encodeURIComponent(this.state.edit_bgColor)+"&text_size="+this.state.edit_size+"&text_color="+encodeURIComponent(this.state.edit_textColor);
      url += "&ticker_text="+encodeURIComponent(this.state.edit_value);
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      //console.log("xxxx="+url);
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({isLoaded:false,edit_id:'',edit_value:''});
            this.showWindow("edit_div",false);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.showInfoMessage("error happened: "+error);
          }
        )
    }

    deleteTicker(screenID) {
      var url = ETVConstants.getServerBase()+"/marquee?action=delete&screen_id="+screenID;
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      //console.log(url);
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({isLoaded:false});
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.showInfoMessage("error happened: "+error);
          }
        )
    }

    editTicker(screenID,screenName,tickerText,bgColor,txtColor,textSize) {
      this.setState({edit_id:screenID,edit_name:screenName,edit_value:tickerText,edit_bgColor:bgColor,edit_textColor:txtColor,edit_size:textSize});
      this.showWindow("edit_div",true);
    }

    closeScreenSelection() {
      this.showWindow('add_static_ticker_div',true);
    }

    selectedScreens(screenIDs) {
      this.showWindow('add_static_ticker_div',true);
      this.setState({selected_screens:screenIDs});
    }

    showAddTickerDiv() {
      this.setState({ticker_text:'',selected_screens:[],edit_bgColor:'#000000',edit_textColor:'#FFFFFF'});
      this.showWindow('add_static_ticker_div',true)
    }

    render() {

      const columnsData= [
          { title: ETVConstants.trans("device_name"), field: 'screen_name',render: rowData => (<div style={{minWidth: "100px", paddingLeft: "0px"}}> {rowData.screen_name} </div>) },
          { title: ETVConstants.trans("ticker_text"), field: 'ticker_text',render: rowData => (<div style={{minWidth: "300px", paddingLeft: "0px"}}> {rowData.ticker_text} </div>) },
          { title: ETVConstants.trans("edit"), field: '', render: rowData => <Button size='small' variant='contained' color='primary' onClick={()=>{this.editTicker(rowData.screen_id,rowData.screen_name,rowData.ticker_text,rowData.bg_color,rowData.text_color,rowData.text_size)}}>{ETVConstants.trans("edit")}</Button>},
          { title: ETVConstants.trans("delete"), field: 'select_layout', render: rowData => <Tooltip title={ETVConstants.trans("delete")}><IconButton size='small' color='secondary' onClick={()=>this.deleteTicker(rowData.screen_id)}><DeleteIcon/></IconButton></Tooltip>},
			];

      const tabletitle = "Ticker Text";

      return (
        <div style={{width:'700px'}}>
          <Card>
            <CardContent>
                <BasicTable2 data={this.state.items} columns={columnsData} title={tabletitle} pageSize={10} allow_search={true} />
                <Divider style={{marginTop:20}} />
                <Button style={{marginTop:20}} variant="contained" color="primary" onClick={() => {this.showAddTickerDiv()}}>{ETVConstants.trans("add_ticker")}</Button>
            </CardContent>
          </Card>

          <div id='select_screens_div' style={{display:this.state.window==='select_screens_div'?'block':'none'}}>
                <SelectAvailableScreensNew isOpen={this.state.window==='select_screens_div'} user_id={this.props.user_id} closeHook={this.closeScreenSelection} selectedScreens={this.selectedScreens} selected={this.state.selected_screens?this.state.selected_screens:[]}/>
          </div>

          <Dialog
            open={this.state.window==='add_static_ticker_div'}
            maxWidth='xs'
            fullWidth={true}
            onClose={()=>{}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{ETVConstants.trans("add_ticker")}</DialogTitle>
            <DialogContent>
                <TextField
                    id="add_outlined-multiline-static"
                    label={ETVConstants.trans("ticker_text")}
                    fullWidth={true}
                    multiline
                    minRows={4}
                    onChange={(e)=>{this.setState({edit_value:e.target.value})}}
                    value={this.state.edit_value}
                    variant="outlined" />
                    <div style={{marginTop:5}}>
                        <Typography variant="body2">{ETVConstants.trans("font_color")}</Typography>
                        <input type="color" style={{marginTop:5}} value={this.state.edit_textColor} onChange={e => this.setState({edit_textColor:e.target.value} )} />
                    </div>
                    <br/>
                    <div>
                        <Typography variant="body2">{ETVConstants.trans("background_color")}</Typography>
                        <input type="color" style={{marginTop:5}} value={this.state.edit_bgColor} onChange={e => this.setState({edit_bgColor:e.target.value} )} />
                    </div>
                    <br/>
                    <div>
                        <FormControl>
                           <InputLabel>{ETVConstants.trans("text_size")}</InputLabel>
                           <Select
                             labelId="text_size_ID"
                             id="text_size"
                             style={{ width: 200}}
                             value={this.state.edit_size}
                             onChange={(e)=>{this.setState({edit_size:e.target.value})}}>
                             <MenuItem value='small'>{ETVConstants.trans("small")}</MenuItem>
                             <MenuItem value='medium'>{ETVConstants.trans("medium")}</MenuItem>
                             <MenuItem value='large'>{ETVConstants.trans("large")}</MenuItem>
                             <MenuItem value='extra_large'>{ETVConstants.trans("extra_large")}</MenuItem>
                           </Select>
                         </FormControl>
                    </div>
                <Button style={{marginTop:30}} color="primary" variant='contained' onClick={() => this.showWindow('select_screens_div',true)}>{ETVConstants.trans("select_screens")} ({this.state.selected_screens.length})</Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.showWindow("add_static_ticker_div",false)}} color="primary">{ETVConstants.trans("close")}</Button>
              <Button onClick={()=>{this.addNewTickers()}} variant='contained' disabled={this.state.selected_screens.length===0} color="secondary">{ETVConstants.trans("save")}</Button>
            </DialogActions>
          </Dialog>

          <div id='select_screens_div' style={{display:this.state.window==='select_screens_div'?'block':'none'}}>
                <SelectAvailableScreensNew isOpen={this.state.window==='select_screens_div'} user_id={this.props.user_id} closeHook={this.closeScreenSelection} selectedScreens={this.selectedScreens}/>
          </div>

          <Dialog
            open={this.state.window==='edit_div'}
            maxWidth='xs'
            fullWidth={true}
            onClose={()=>{}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{this.state.edit_name}</DialogTitle>
            <DialogContent>
                <Typography variant="body1"></Typography>
                <TextField
                    id="outlined-multiline-static"
                    label={ETVConstants.trans("ticker_text")}
                    fullWidth={true}
                    multiline
                    minRows={4}
                    onChange={(e)=>{this.setState({edit_value:e.target.value})}}
                    value={this.state.edit_value}
                    variant="outlined" />
                    <div>
                        <Typography variant="body2">{ETVConstants.trans("font_color")}</Typography>
                        <input type="color" style={{marginTop:5}} value={this.state.edit_textColor} onChange={e => this.setState({edit_textColor:e.target.value} )} />
                    </div>
                    <br/>
                    <div>
                        <Typography variant="body2">{ETVConstants.trans("background_color")}</Typography>
                        <input type="color" style={{marginTop:5}} value={this.state.edit_bgColor} onChange={e => this.setState({edit_bgColor:e.target.value} )} />
                    </div>
                    <br/>
                    <div>
                        <FormControl>
                           <InputLabel>{ETVConstants.trans("text_size")}</InputLabel>
                           <Select
                             labelId="text_size_ID"
                             id="text_size_id_edit"
                             style={{ width: 200}}
                             value={this.state.edit_size}
                             onChange={(e)=>{this.setState({edit_size:e.target.value})}}>
                             <MenuItem value='small'>{ETVConstants.trans("small")}</MenuItem>
                             <MenuItem value='medium'>{ETVConstants.trans("medium")}</MenuItem>
                             <MenuItem value='large'>{ETVConstants.trans("large")}</MenuItem>
                             <MenuItem value='extra_large'>{ETVConstants.trans("extra_large")}</MenuItem>
                           </Select>
                         </FormControl>
                    </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
              <Button onClick={()=>{this.updateTicker()}} variant='contained' color="secondary">{ETVConstants.trans("save")}</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.window==='message_div'}
            maxWidth='xs'
            fullWidth={true}
            onClose={()=>{this.setState({window:''})}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
            <DialogContent>
                <Typography variant="body1">{this.state.info_message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    }
}

export default Newsticker;
